import React from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useInfiniteQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import theme from 'theme';
import parse from 'parse-link-header';

// API
import * as remoteApi from 'api/remote';

// Components

import KoalaButton from 'koala/components/Button';
import { AxiosResponse } from 'axios';
import OutcomeBlockCompact from './OutcomeBlockCompact';
import { useTranslation } from 'react-i18next';

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${theme.spacing.x1} 0;
`;

export const BlockContent = styled.div`
  font-weight: 700;
  grid-area: content;
  background: transparent;
  margin: 0;
  height: 100%;

  display: flex;
  align-items: center;
`;

export const BlockMeta = styled.div`
  font-weight: 700;
  grid-area: meta;
  display: flex;
  align-items: center;
  height: 100%;
`;

const OutcomesContainer = styled.div``;

interface Props {
  membership: TabilityTypes.Membership;
}

const getNextPage = (response: any) => {
  if (response && response.headers && response.headers.link) {
    const links = response.headers.link;
    const parsed = parse(links);
    if (parsed && parsed.next) {
      return parsed.next.page;
    }
  }
  return null;
};

function Inbox(props: Props) {
  // Query that fetches all the outcomes with pending checkins
  const { membership } = props;
  const activeOutcomeQueryKey = [queryKeys.outcomes, membership.id, false, { limit: 30 }];
  const {
    isLoading,
    isFetchingMore,
    data: outcomesResponse,
    fetchMore,
    canFetchMore,
  }: any = useInfiniteQuery(activeOutcomeQueryKey, remoteApi.fetchMembershipAllActiveOutcomes, {
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
  });
  const { t } = useTranslation();

  if (isLoading) {
    return null;
  }

  let activeOutcome: AxiosResponse[] = [];

  if (outcomesResponse) {
    activeOutcome = outcomesResponse;
  }

  // Display empty state if you're at inbox zero
  if (activeOutcome.length === 0 || activeOutcome[0].data.length === 0) {
    return (
      <OutcomesContainer>
        <p className="subtle">{t('shared.noData')}</p>
      </OutcomesContainer>
    );
  }

  return (
    <>
      {activeOutcome.map((group, i: number) => {
        return (
          <OutcomesContainer key={i}>
            {group.data.map((outcome: TabilityTypes.Outcome, index: number) => {
              return <OutcomeBlockCompact outcome={outcome} key={index} />;
            })}
          </OutcomesContainer>
        );
      })}

      {canFetchMore && (
        <LoadMore>
          <KoalaButton
            onClick={() => fetchMore()}
            loading={isFetchingMore}
            disabled={isFetchingMore}
            appearance="secondary"
          >
            {t('shared.loadMore')}
          </KoalaButton>
        </LoadMore>
      )}
    </>
  );
}

export default Inbox;
