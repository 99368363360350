import React, { useState } from 'react';
import { Fragment } from 'react';
import * as TabilityTypes from 'types';
import { Helmet } from 'react-helmet';
import * as workspaceUtils from 'utils/workspaceUtils';
import styled from 'styled-components';
import theme from 'theme';
import { Base64 } from 'js-base64';

// Components
import { ChromeContent } from 'components/Chrome';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { fetchPlans } from 'api/remote';
import { Edge, MarkerType, Node, Position, ReactFlowProvider } from 'reactflow';
import Map from './Map';
import UpgradeRequiredLegacy from './UpgradeRequiredLegacy';
import UpgradeRequiredV4 from './UpgradeRequiredV4';
// import { Base64 } from 'js-base64';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import ShareButton from 'components/ShareButton';

const UpgradeModal = styled.div`
  position: fixed;
  left: 50%;
  max-height: 25rem;
  z-index: 80;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  overflow: hidden;
  height: 25rem;
  top: 50%;
  width: 60rem;
  max-width: 60rem;
  margin-left: -30rem;
  margin-top: -20rem;
  background: #fff;

  @media ${theme.devices.mobile} {
    width: 100%;
    margin: 20rem 0 0 0;
    margin-top: -20rem;
    left: unset;
    overflow: auto;
    height: 30rem;
    max-height: 30rem;
  }
`;

const HeaderContainer = styled.div`
  padding: ${theme.spacing.x1};
  border-bottom: 1px solid ${theme.colors.N10};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Actions = styled.div`
  display: flex;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const edgeDefaults = {
  targetHandle: 'in',
  sourceHandle: 'out',
  type: 'step',
  markerEnd: {
    type: MarkerType.ArrowClosed,
  },
};
const nodeDefaults = {
  position: { x: 0, y: 0 },
  sourcePosition: Position.Bottom,
  targetPosition: Position.Top,
};

interface Props {
  workspace: TabilityTypes.Workspace;
}

function WorkspaceMapV2(props: Props) {
  const { workspace } = props;
  const { slug } = workspace;
  const { t } = useTranslation();
  const [hasFetchedPlans, setHasFetchedPlans] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [initialEdges, setInitialEdges] = useState<Edge[]>([]);
  const [initialNodes, setInitialNodes] = useState<Node[]>([]);

  // Ignore upgrade check for workspace created before December 15th;
  const hasEssentialsSubscription =
    workspaceUtils.hasEssentialsSubscription(workspace) || workspace.pricing_version >= 2;

  // Check the upgrade required flag
  let upgradeRequired = false;

  // Only show upgrade if on the old v4 free plan
  if (workspace.pricing_version === 4 && workspace.billing_subscription_plan === 'tability-v4-free') {
    upgradeRequired = !workspace.billing_has_done_marketing;
  }

  if (workspace.pricing_version < 4) {
    upgradeRequired = !hasEssentialsSubscription;
  }

  const filterParams: { [key: string]: any } = {
    children_of: null,
    status: 'planning',
  };

  const searchParams = new URLSearchParams(location.search);
  const mapStateHash = searchParams.get('mapState');
  let mapState: { [key: string]: any } = {};
  if (mapStateHash) {
    try {
      mapState = JSON.parse(Base64.decode(mapStateHash));
    } catch (e) {}
  }

  const params = {
    limit: 200,
    filter: filterParams,
  };

  useQuery([queryKeys.plans, slug, params], fetchPlans, {
    onSuccess: (response) => {
      // Create the plans mapping
      const plans: TabilityTypes.Plan[] = response.data;

      const nodes: Node[] = [
        {
          id: 'workspace',
          data: { workspace, expanded: true },
          type: 'workspace',
          ...nodeDefaults,
          style: {
            height: 80,
            width: 350,
          },
        },
      ];

      const edges: Edge[] = [];

      plans.forEach((plan, index) => {
        const hasParent = plan.parent_id;
        if (hasParent) {
          //  if plan has parent, add edge
          edges.push({
            id: `${plan.parent_id}:${plan.id}`,
            target: plan.id,
            source: plan.parent_id,
            ...edgeDefaults,
          });
        } else {
          // top level plan, add edge from workspace to plan
          edges.push({
            id: `workspace:${plan.id}`,
            target: plan.id,
            source: 'workspace',
            ...edgeDefaults,
          });
        }

        const expanded = mapState['expandedPlans'] ? mapState['expandedPlans'][plan.id] : true;

        nodes.push({
          id: `${plan.id}`,
          type: 'plan',
          data: {
            expanded,
            plan,
            outcomesExpanded: true,
            path: `${plan.nano_slug}/`,
          },
          style: {
            height: 600,
            maxHeight: 600,
            width: 600,
            maxWidth: 600,
          },
          ...nodeDefaults,
        });
      });

      setHasFetchedPlans(true);
      setInitialEdges(edges);
      setInitialNodes(nodes);
    },
  });

  const handleShare = () => {
    dispatch(setGlobalModalContent('workspace:map:share'));
  };

  const link = `https://${process.env.REACT_APP_DOMAIN}${location.pathname}${location.search}`;

  return (
    <Fragment>
      <Helmet>
        <title>
          {workspace.name} | {t('workspaceMap.title')} | Tability
        </title>
      </Helmet>
      <ChromeContent>
        {upgradeRequired && (
          <UpgradeModal>
            {workspace.pricing_version === 4 && <UpgradeRequiredV4 />}
            {workspace.pricing_version < 4 && <UpgradeRequiredLegacy />}
          </UpgradeModal>
        )}
        <Content>
          <HeaderContainer>
            <Actions>
              <ShareButton id="strategy-map" link={link} shareCallback={handleShare} />
            </Actions>
          </HeaderContainer>
          {hasFetchedPlans && (
            <ReactFlowProvider>
              <Map initialEdges={initialEdges} initialNodes={initialNodes} />
            </ReactFlowProvider>
          )}
        </Content>
      </ChromeContent>
    </Fragment>
  );
}

export default WorkspaceMapV2;
