import React, { useState } from 'react';
//import * as TabilityTypes from 'types';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import styled from 'styled-components';
import theme from 'theme';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import Loader from 'components/Loader';
import KoalaAvatar from 'koala/components/Avatar';
import { shallowEqual, useSelector } from 'react-redux';
import MentionBox from 'components/MentionBox';
import { CONTEXT_STORAGE_KEY } from 'config/constants';
import KoalaButton from 'koala/components/Button';
import { useTranslation } from 'react-i18next';
import KoalaTextButton from 'koala/components/TextButton';
import CommentBlock from './CommentBlock';
import { Checkin, CheckinComment } from 'types';

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'gutter meta'
    'gutter content'
    'gutter footer';
  column-gap: ${theme.spacing.x1};

  margin-top: ${theme.spacing.x2};

  &.primary {
    background: #f3f3f3;
    border-radius: 8px;
    padding: ${theme.spacing.x3};

    border-bottom: 0;
  }
`;

const Gutter = styled.div`
  grid-area: gutter;
`;

const Content = styled.div`
  grid-area: content;
  textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    font-size: 1.6rem;
    line-height: 1.4;
    padding: ${theme.spacing.x1};
    min-height: 10rem;
    border-radius: 4px;
    background: #f3f3f3;
    border: 0px solid #949494;
    &:placeholder {
      color: #949494;
      font-size: 1.4rem;
    }

    width: 100%;

    &.minimized {
      min-height: 4rem;
      height: 4rem;
    }
  }
`;

const Footer = styled.div`
  grid-area: footer;
  margin: 0 0 ${theme.spacing.x2};
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${theme.colors.subtleText};

  > div {
    display: flex;
    align-items: center;
    margin-top: ${theme.spacing.x1};
    button {
      margin-right: ${theme.spacing.x1};
    }
  }
`;

const Separator = styled.div`
  border-bottom: 1px solid ${theme.colors.N10};
`;

const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  margin-top: ${theme.spacing.x2};
`;

interface Props {
  checkin: Checkin;
  showNewComment: boolean;
  handleShowNewComment: (value: boolean) => void;
}

function NewsblockCheckinComments(props: Props) {
  const { checkin, showNewComment, handleShowNewComment } = props;
  const { t } = useTranslation();
  const user = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const [body, setBody] = useState('');
  const queryCache = useQueryCache();
  // Construct the query key using the plan Id
  const queryKeyCheckinComments = [queryKeys.checkinComments, checkin.id];
  const staleTime = queryKeys.staleTime;
  const storageKey = `${CONTEXT_STORAGE_KEY}_checkin_comment_${checkin.nano_slug}`;

  // Get the comments details
  const { data: checkinCommentsResponse, isLoading } = useQuery(
    queryKeyCheckinComments,
    remoteApi.fetchCheckinComments,
    {
      staleTime,
    },
  );
  // Checkin comment mutations
  const [createCheckinCommentMutation, { isLoading: isSaving }] = useMutation(remoteApi.createCheckinComment, {
    onSuccess: () => {
      sessionStorage.removeItem(storageKey);
      queryCache.invalidateQueries(queryKeys.checkinComments);
      setBody('');
      handleShowNewComment(false);
    },
  });

  let checkinComments = [];
  if (checkinCommentsResponse) {
    checkinComments = checkinCommentsResponse.data;
  }

  const handleSubmit = () => {
    const checkinComment = {
      body,
    };
    createCheckinCommentMutation({
      checkinId: checkin.id,
      checkin_comment: checkinComment,
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <CommentsContainer>
      {checkinComments.length > 0 && <Separator />}
      {checkinComments.map((checkinComment: CheckinComment) => {
        return (
          <CommentBlock
            checkinComment={checkinComment}
            checkin={checkin}
            key={checkinComment.id}
            handleShowNewComment={handleShowNewComment}
          />
        );
      })}
      {showNewComment && (
        <Grid>
          <Gutter>
            <KoalaAvatar membership={{ ...currentMembership, user }} tooltipType="card" />
          </Gutter>
          <Content>
            <MentionBox
              setValue={setBody}
              value={body}
              placeholder={t('shared.reply') ?? 'Reply'}
              comment={true}
              cmdEnterCallback={handleSubmit}
            />
          </Content>

          <Footer>
            <div>
              <KoalaButton onClick={handleSubmit} disabled={isSaving} loading={isSaving}>
                {t('shared.save')}
              </KoalaButton>
              <KoalaTextButton onClick={() => handleShowNewComment(false)} appearance="secondary">
                {t('shared.cancel')}
              </KoalaTextButton>
            </div>
          </Footer>
        </Grid>
      )}
    </CommentsContainer>
  );
}

export default React.memo(NewsblockCheckinComments);
