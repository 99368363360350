import React, { memo } from 'react';
import PlanIconLabel from 'components/PlanIconLabel';
import PlanStatus from 'components/PlanStatus';
import KoalaIconButton from 'koala/components/IconButton';
import { Handle, NodeProps, Position } from 'reactflow';
import styled from 'styled-components';
import theme from 'theme';
import { Link } from 'react-router-dom';
import * as routes from 'routes';

import { useTranslation } from 'react-i18next';
import PlanContent from './PlanContent';
import ProgressBarV2 from 'components/ProgressBarV2';
import ConfidenceBar from 'components/ConfidenceBar';
import { CustomTermKey, translate } from 'utils/customTermUtils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  width: 100%;

  .expand-plan-button {
    transition: all ease 0.2s;
    transform: rotate(90deg);
    &.plan--expanded {
      transform: rotate(-90deg);
    }
  }
`;
const PlanContainer = styled.div`
  width: 100%;
  height: 100%;
  border: 2px solid ${theme.colors.B20};
  border-radius: 4px;
  background-color: ${theme.colors.N0};
  display: flex;
  flex-direction: column;
  &.parent {
    border: 1px solid ${theme.colors.blockBorder};
  }
`;
const Title = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: ${theme.spacing.x1};
  justify-content: space-between;
  padding: ${theme.spacing.x2};
  a {
    width: 100%;
  }
  .plan-title {
    font-weight: 600;
  }
`;
const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .expand-button {
    transition: all ease 0.2s;
    transform: rotate(-90deg);
    &.show-outcomes {
      transform: rotate(0deg);
    }
  }
`;

const PlanMetrics = styled.div`
  display: flex;
  gap: ${theme.spacing.x3};
  padding: 0 ${theme.spacing.x1} ${theme.spacing.x1} ${theme.spacing.x1};
  justify-content: space-between;
  width: 100%;

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x1};
  }
`;

const CustomHandle = styled(Handle)`
  background: transparent;
  border: none;
`;

const HeaderLabel = styled.span`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1rem;
  color: ${theme.colors.subtleText};
`;

const ExpandableHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.x1};
  cursor: pointer;
  > div {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.half};
  }
  width: 100%;
  justify-content: space-between;
  &:hover {
    background: ${theme.colors.N3};
  }
`;

function PlanNode(node: NodeProps) {
  const { data } = node;
  const { t } = useTranslation();
  const { plan, isParent, outcomesExpanded, handleExpandOutcomes } = data;
  const { workspace } = plan;
  // const [showOutcomes, setShowOutcomes] = useState(true);

  const routeTarget =
    plan.state === 'published' ? routes.WORKSPACE_PLAN_TRACK_ROUTE : routes.WORKSPACE_PLAN_WRITE_ROUTE;
  const planRoute = routeTarget.replace(':workspaceSlug', workspace.slug).replace(':planId', plan.nano_slug);

  const handleExpandOutcomesClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    handleExpandOutcomes(event, node);
  };

  const isParentClassName = isParent ? 'parent' : '';

  const expandedClassName = outcomesExpanded ? 'show-outcomes' : '';

  const outcomeProgress = plan.outcome_progress_prct * 100;
  const initiativeProgress = plan.initiative_progress_prct * 100;

  return (
    <Container onClick={(e) => e.stopPropagation()}>
      <CustomHandle position={Position.Top} type="target" id="in" key="in" isConnectableStart={false} />
      <PlanContainer className={`${expandedClassName} ${isParentClassName}`}>
        <Title>
          <Link to={planRoute}>
            <PlanIconLabel plan={plan} />
          </Link>
        </Title>
        <PlanMetrics>
          <div>
            <HeaderLabel>{translate(workspace, CustomTermKey.OUTCOME, 2)}</HeaderLabel>
            <ProgressBarV2
              percentage={outcomeProgress}
              totalCount={plan.total_outcomes_count}
              label={`${Math.round(outcomeProgress)}%`}
            />
          </div>
          <div>
            <HeaderLabel>{translate(workspace, CustomTermKey.INITIATIVE, 2)}</HeaderLabel>
            <ProgressBarV2
              percentage={initiativeProgress}
              totalCount={plan.total_initiatives_count}
              label={`${plan.closed_initiatives_count}/${plan.total_initiatives_count}`}
            />
          </div>
          <div>
            <HeaderLabel>{t('workspacePlans.table.confidence')}</HeaderLabel>
            <ConfidenceBar
              greenCount={plan.green_outcomes_count}
              greyCount={plan.grey_outcomes_count}
              redCount={plan.red_outcomes_count}
              totalCount={plan.total_outcomes_count}
              yellowCount={plan.yellow_outcomes_count}
              ncs={Math.round(plan.ncs)}
            />
          </div>
        </PlanMetrics>
        <Footer>
          <ExpandableHeader onClick={handleExpandOutcomesClick}>
            <div>
              <KoalaIconButton
                className={`${expandedClassName} expand-button`}
                iconName="downChevron"
                size="xsmall"
                edge="square"
                onClick={handleExpandOutcomesClick}
              />
              {outcomesExpanded && <small>{t('workspaceMap.hidePlanContent')}</small>}
              {!outcomesExpanded && <small>{t('workspaceMap.showPlanContent')}</small>}
            </div>

            <PlanStatus plan={plan} />
          </ExpandableHeader>
        </Footer>
        {outcomesExpanded && (
          <>
            <PlanContent plan={plan} />
          </>
        )}
      </PlanContainer>
      <CustomHandle type="source" position={Position.Bottom} key="out" id="out" isConnectableStart={false} />
    </Container>
  );
}

export default memo(PlanNode);
