import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import { Initiative } from 'types';
import { shallowEqual, useSelector } from 'react-redux';
import KoalaAvatar from 'koala/components/Avatar';
import UserSelectButton from 'components/UserSelectButton';
import KoalaStackedAvatar from 'koala/components/StackedAvatars';
import { useMutation, useQueryCache } from 'react-query';

import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import InitiativeDueDate from 'components/InitiativeDueDate';
import * as workspaceUtils from 'utils/workspaceUtils';
import * as initiativeUtils from 'utils/initiativeUtils';
import * as outcomeUtils from 'utils/outcomeUtils';
import BlockActions from 'components/BlockActions';
import KoalaIconButton from 'koala/components/IconButton';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import * as integrationUtils from 'utils/integrationUtils';
import { truncateString } from 'utils/textUtils';

const IntegrationIcon = styled.img`
  height: 1.3rem;
  margin-right: ${theme.spacing.half};
`;

const BlockHeader = styled.div`
  grid-area: header;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: ${theme.spacing.half};
  span {
    overflow: hidden;
    word-break: break-word;
  }
`;

const BlockMeta = styled.div`
  grid-area: meta;
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

const OutcomeLink = styled(Link)<{ bgColor?: string }>`
  background: ${(props) => props.bgColor || theme.colors.N0};
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.2;
  display: inline-block;
  align-items: center;
  padding: ${theme.spacing.half};
  border-radius: 2px;

  &:hover {
    text-decoration: underline;
  }
`;

const BlockGrid = styled.div`
  display: grid;
  width: 100%;
  min-width: 100%;
  position: relative;
  border: 1px solid ${theme.colors.blockBorder};
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'header'
    'middle'
    'meta';
  align-items: start;
  gap: ${theme.spacing.half};
  padding: 1rem;
  background: #fff;
  border-radius: 4px;
  &.closed {
    opacity: 1;

    .title span {
      text-decoration: line-through;
    }
  }

  &:hover {
    background: ${theme.colors.N3};
    cursor: grab;
    .block-actions {
      opacity: 1;
    }
  }
  &.selected,
  &:focus,
  &:active {
    background: ${theme.colors.B5};
  }

  .block-actions {
    opacity: 0;
    top: ${theme.spacing.x1};
    right: ${theme.spacing.x1};
  }
`;

const BlockOutcome = styled.div`
  grid-area: middle;
`;

const ContributorsWrapper = styled.div`
  display: flex;
  .contributor {
    margin-left: -8px;
  }
`;
const BlockOwners = styled.div`
  display: flex;
  align-items: center;
  .contributors {
    margin-left: -10px;
  }
`;

function Avatars(props: { initiative: Initiative; handleChangeOwner: (id: string | null) => void }) {
  const { initiative, handleChangeOwner } = props;
  const { t } = useTranslation();
  const editWarning = t('panels.initiative.avatarEditWarning', {
    integration: integrationUtils.getIntegrationType(initiative),
  });

  if (initiative.integration_type && initiative.integration_remote_id) {
    return (
      <KoalaAvatar
        integrationType={initiative.integration_type}
        integrationAssignee={integrationUtils.getIntegrationUser(initiative)}
        size={2.2}
        tooltipText={editWarning}
        tooltipType="card"
      />
    );
  } else {
    return (
      <BlockOwners>
        <UserSelectButton
          size="small"
          position="left"
          canEdit={true}
          handleAssignUser={handleChangeOwner}
          handleUnassignUser={() => handleChangeOwner(null)}
          selectedUser={initiative.membership ?? null}
        />
        <KoalaStackedAvatar
          owner={null}
          contributors={initiative.contributors}
          showNumber={true}
          size="small"
          hideOwner={true}
          className="contributors"
        />
      </BlockOwners>
    );
  }
}

interface Props {
  initiative: Initiative;
}

function InitiativeCard(props: Props) {
  const { initiative } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const queryCache = useQueryCache();
  const { outcome_id } = initiative;
  const outcome = useSelector((state: any) => state.editorEntities.outcomes[outcome_id]);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const hasStarterSubscription =
    workspaceUtils.hasStarterSubscription(currentWorkspace) || currentWorkspace.pricing_version >= 2;

  const [updateInitiativeMutation, { isLoading }] = useMutation(remoteApi.updateInitiative, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.initiatives);
      queryCache.invalidateQueries(queryKeys.currentInitiative);
    },
  });

  if (!initiative || !outcome) {
    return null;
  }

  const blockId = `initiative:${initiative.nano_slug}`;
  const hashPath = `#${blockId}:show`;

  const handleNav = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(hashPath);
  };

  const handleEdit = (e: React.MouseEvent) => {
    e.preventDefault();
    const blockId = `initiative:${initiative.nano_slug}`;
    const hashPath = `#${blockId}:edit`;
    e.stopPropagation();
    history.push(hashPath);
  };

  const handleStopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleChangeOwner = (membershipId: string | null) => {
    const params = {
      membership_id: membershipId,
    };
    updateInitiativeMutation({ initiativeId: initiative.id, initiative: params });
  };
  const handleChangeDueAt = (value: Date | null) => {
    const params = { due_at: value };
    updateInitiativeMutation({
      initiativeId: initiative.id,
      initiative: params,
    });
  };
  const toggleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    const params =
      initiative.state === 'closed'
        ? { ...initiativeUtils.getWorkStateParams(initiativeUtils.InitiativeStates.IN_PROGRESS) }
        : { ...initiativeUtils.getWorkStateParams(initiativeUtils.InitiativeStates.DONE) };
    updateInitiativeMutation({
      initiativeId: initiative.id,
      initiative: params,
    });
  };

  const outcomePath = `#outcome:${outcome.nano_slug}:show`;
  let title = initiative.title;
  let dueDate = initiative.due_at;
  let imageSource = null;
  let isIntegration = false;
  let isClosed = initiative.state === 'closed';
  let integrationType = initiative.integration_type;

  if (initiative.integration_type && initiative.integration_remote_id) {
    isIntegration = true;
    title = initiative.integration_title;
    imageSource = integrationUtils.getIntegrationImageSource(initiative.integration_type);
    dueDate = integrationUtils.getDueDate(initiative);
    isClosed = integrationUtils.isIntegrationClosed(initiative);
    integrationType = integrationUtils.getIntegrationType(initiative);
  }
  const closedClass = isClosed ? 'closed' : '';
  const bgColor = outcomeUtils.outcomeIdToBackgroundColor(initiative.outcome_id);

  return (
    <BlockGrid className={closedClass} onClick={handleNav}>
      <BlockHeader className="title">
        {imageSource && <IntegrationIcon src={imageSource} />}
        <span>{title}</span>
      </BlockHeader>
      <BlockOutcome className="text-ellipsis">
        {outcome && (
          <div>
            <OutcomeLink title={outcome.title} to={outcomePath} onClick={handleStopPropagation} bgColor={bgColor}>
              {truncateString(outcome.title, 30)}
            </OutcomeLink>
          </div>
        )}
      </BlockOutcome>
      <BlockMeta>
        <ContributorsWrapper>
          <Avatars initiative={initiative} handleChangeOwner={handleChangeOwner} />
        </ContributorsWrapper>
        <InitiativeDueDate
          dueAt={dueDate}
          state={initiative.state}
          dataFor={`${blockId}-dueDate`}
          handleDateChange={hasStarterSubscription && !isIntegration ? handleChangeDueAt : undefined}
          handleDateClear={() => handleChangeDueAt(null)}
        />
        {isIntegration && (
          <ReactTooltip id={`${blockId}-dueDate`} place="top" effect="solid" type="dark" delayShow={300}>
            {t(`panels.initiative.integrationDueDate`, { integrationType })}
          </ReactTooltip>
        )}
      </BlockMeta>
      {!isIntegration && (
        <BlockActions className="block-actions">
          <KoalaIconButton
            onClick={toggleClose}
            iconName="checkAction"
            size="small"
            edge="square"
            dataFor={`${blockId}-close`}
            loading={isLoading}
          />
          <ReactTooltip id={`${blockId}-close`} place="top" effect="solid" type="dark" delayShow={300}>
            {isClosed ? t('shared.reopen') : t('shared.close')}
          </ReactTooltip>
          <KoalaIconButton
            onClick={handleEdit}
            iconName="settings"
            size="small"
            edge="square"
            dataFor={`${blockId}-edit`}
          />
          <ReactTooltip id={`${blockId}-edit`} place="top" effect="solid" type="dark" delayShow={300}>
            {t('shared.edit')}
          </ReactTooltip>
        </BlockActions>
      )}
    </BlockGrid>
  );
}

export default InitiativeCard;
