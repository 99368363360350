import React, { Fragment, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useMutation, useQuery, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

// Components
import KoalaButton from 'koala/components/Button';
import { useTranslation } from 'react-i18next';
import { Follower } from 'types';

function FollowButton() {
  const { membershipId } = useParams<{ membershipId: string }>();
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const { t } = useTranslation();
  const params = {
    filter: {
      memberhip_id: currentMembership.id,
    },
  };
  const queryKey = [queryKeys.followers, membershipId, params];

  const [currentFollower, setCurrentFollower] = useState<Follower | null>(null);

  const queryCache = useQueryCache();

  useQuery(queryKey, remoteApi.fetchFollowers, {
    staleTime: 0,
    onSuccess: (response) => {
      const followers: Follower[] = response.data;
      // if membership_source_id is current membership id, set current follower
      const currentFollower = followers.find((follower) => follower.membership_source_id === currentMembership.id);
      setCurrentFollower(currentFollower || null);
    },
  });

  const [deleteFollowerMutation, { isLoading: isDeletingFollower }]: [any, any] = useMutation(
    remoteApi.deleteFollower,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKey);
      },
    },
  );

  const [createFollowerMutation, { isLoading: isCreatingFollower }]: [any, any] = useMutation(
    remoteApi.createFollower,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKey);
      },
    },
  );

  const isFollowing = currentFollower !== null;

  const handleButtonClick = () => {
    if (isFollowing) {
      deleteFollowerMutation(currentFollower.id);
    } else {
      createFollowerMutation(membershipId);
    }
  };

  const followerLoading = isDeletingFollower || isCreatingFollower;

  const appearance = isFollowing ? 'secondary' : 'subtle';
  const label = isFollowing ? t('shared.unfollow') : t('shared.follow');
  return (
    <Fragment>
      <KoalaButton size="small" onClick={handleButtonClick} loading={followerLoading} appearance={appearance}>
        {label}
      </KoalaButton>
    </Fragment>
  );
}

export default FollowButton;
