import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useQuery, useQueryCache, useMutation } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import * as workspaceUtils from 'utils/workspaceUtils';
import * as planUtils from 'utils/planUtils';

// API
import * as remoteApi from 'api/remote';

import { setGlobalModalContent } from 'state/actions/globalUIActions';

// Routes
import ReactTooltip from 'react-tooltip';

// Components
import Loader from 'components/Loader';
import { ModalGrid, ModalHeader, ModalContent, ModalFooter } from 'components/GlobalModal';
import AddPersonIcon from 'components/_assets/AddPersonIcon';
import UserSearchableDropdown from 'components/UserSearchableDropdown';
import UpgradeIcon from 'components/_assets/UpgradeIcon';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaIconButton from 'koala/components/IconButton';
import KoalaAvatar from 'koala/components/Avatar';
import { Trans, useTranslation } from 'react-i18next';
import { Membership } from 'types';

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Help = styled.p`
  background: ${theme.colors.warningBg};
  padding: ${theme.spacing.x1};
  border-radius: 8px;
  a {
    color: ${theme.colors.blue};
  }
  margin-bottom: ${theme.spacing.x3};
`;

const OwnerLine = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  &.header {
    margin-bottom: ${theme.spacing.x1};
  }
`;

const NameContainer = styled.div`
  min-width: 30rem;
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

const OwnerOption = styled.div`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};

  border-radius: 8px;

  &:hover {
    cursor: pointer;
    background: #f3f3f3;
  }
  .owner-option-name {
    margin-left: ${theme.spacing.x2};
  }

  &.active {
    background: #f3f3f3;
    &:hover {
      cursor: initial;
      background: #f3f3f3;
    }
  }

  &.not-assigned {
    opacity: 0.5;
  }

  &.option-button {
    background: #f3f3f3;
    svg {
      height: 1.8rem;
      g {
        stroke: ${theme.colors.subtleText};
      }
    }
    &:hover {
      cursor: pointer;
      background: #e7e7e7;
    }
  }
`;

const HeaderLine = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};

  label {
    color: ${theme.colors.subtleText};
    text-transform: uppercase;
    font-weight: 800;
    font-size: 1.1rem;
  }
`;

interface Props {
  planId: string;
}

function EditPlanPermissions(props: Props) {
  const dispatch = useDispatch();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const { planId } = props;
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const translationKey = 'modals.editPlanPermissions';

  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const hasEssentialsSubscription =
    workspaceUtils.hasEssentialsSubscription(currentWorkspace) || currentWorkspace.pricing_version >= 2;

  // Get the plan details
  const { data: planResponse }: any = useQuery([queryKeys.currentPlan, planId], remoteApi.fetchPlanDetails, {
    staleTime: 0,
  });

  const [planPermissions, setPlanPermissions]: any = useState([]);
  const [currentUserHasEditPlanPermission, setCurrentUserHasEditPlanPermission]: any = useState(false);

  const planPermissionsQueryKey = [queryKeys.planPermissions, planId];
  const { isFetching: isFetchingPlanPermissions } = useQuery(planPermissionsQueryKey, remoteApi.fetchPlanPermissions, {
    staleTime: 0,
    onSuccess: (response) => {
      const permissions = response.data;
      setPlanPermissions(permissions);
      const currentUserHasEditPlanPermission = permissions.find(
        (p: any) => p.membership.id === currentMembership.id && p.permission_type === 'edit',
      );
      setCurrentUserHasEditPlanPermission(currentUserHasEditPlanPermission);
    },
  });

  /* Plan permissions actions */

  const [addPlanPermissionMutation, { isLoading: isAddingPlanPermission }]: [any, any] = useMutation(
    remoteApi.createPlanPermission,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKeys.planPermissions);
        queryCache.invalidateQueries(queryKeys.currentPlan);
      },
    },
  );

  const [updatePlanPermissionMutation, { isLoading: isUpdatingPlanPermission }]: [any, any] = useMutation(
    remoteApi.updatePlanPermission,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKeys.planPermissions);
        queryCache.invalidateQueries(queryKeys.currentPlan);
      },
    },
  );

  const [deletePlanPermissionMutation, { isLoading: isDeletingPlanPermission }]: [any, any] = useMutation(
    remoteApi.deletePlanPermission,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKeys.planPermissions);
        queryCache.invalidateQueries(queryKeys.currentPlan);
      },
    },
  );

  const handleAddPlanPermission = (membership_id: string) => {
    if (membership_id) {
      const params = {
        membership_id,
      };

      addPlanPermissionMutation({
        planId,
        plan_permission: params,
      });
    }
  };

  const handleUpdatePlanPermission = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const permission_type = e.target.value;
    const planPermissionId = e.target.dataset.planPermissionId;
    const plan_permission = {
      permission_type,
    };

    updatePlanPermissionMutation({
      planPermissionId,
      plan_permission,
    });
  };

  const handleDeletePlanPermission = (planPermissionId: string) => {
    // Find the permssion for the current user
    const permission = planPermissions.find((p: any) => p.id === planPermissionId);

    // Display a confirmation pop up if user is trying to delete their permission
    if (permission.membership.id === currentMembership.id) {
      const permissionInfo =
        t(`${translationKey}.removeOwn`) ?? 'Are you sure that you want to remove your own permission?';
      if (window.confirm(permissionInfo)) {
        deletePlanPermissionMutation(planPermissionId);
      } else {
        return false;
      }
    }
    deletePlanPermissionMutation(planPermissionId);
  };

  const plan = planResponse ? planResponse.data : null;
  const hasEditPermission = planUtils.hasEditPermission(plan, currentMembership);

  // Construct the query key using the plan Id
  const queryKey = [queryKeys.currentPlan, planId];
  const queryKeyTrack = [queryKeys.currentPlan, planId, 'track'];
  const queryKeyWork = [queryKeys.currentPlan, planId, 'work'];
  const queryKeyWrite = [queryKeys.currentPlan, planId, 'write'];

  // Mutation that will update the plan in the backend
  const [updatePlanMutation, { isLoading }]: [any, any] = useMutation(remoteApi.updatePlan, {
    onSuccess: (planResponse) => {
      queryCache.setQueryData(queryKey, planResponse);
      queryCache.setQueryData(queryKeyTrack, planResponse);
      queryCache.setQueryData(queryKeyWork, planResponse);
      queryCache.setQueryData(queryKeyWrite, planResponse);
    },
  });

  // Now we can display the app with the Chrome
  if (!plan) {
    return (
      <LoadingContainer>
        <Loader size="medium" />
      </LoadingContainer>
    );
  }

  const handleCancel = (e: any) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const isUpdatingPlanPermissions =
    isUpdatingPlanPermission || isAddingPlanPermission || isDeletingPlanPermission || isFetchingPlanPermissions;

  const editPlanPermissions = planPermissions.filter((p: any) => p.permission_type === 'edit');

  // Prevent locking if there are no editors
  const lockingDisabledBecauseNoEditors = editPlanPermissions.length === 0;

  const handleGlobalPermissionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const global_permission_type = e.target.value;
    const editPermission =
      t(`${translationKey}.missingEdit`) ??
      'You do not have edit permission. Are you sure that you want to lock editing of this plan?';

    if (global_permission_type !== 'edit' && !currentUserHasEditPlanPermission) {
      if (!window.confirm(editPermission)) {
        return;
      }
    }

    let planParams = {
      global_permission_type,
    };

    const mutationParams = {
      planId: plan.nano_slug,
      plan: planParams,
    };
    updatePlanMutation(mutationParams);
  };

  if (!hasEditPermission) {
    return (
      <ModalGrid>
        <ModalHeader>
          <HeaderLine>
            <h2>{t(`${translationKey}.title`)}</h2>
            {!hasEssentialsSubscription && <UpgradeIcon />}
          </HeaderLine>
          <KoalaIconButton iconName="close" onClick={handleCancel} />
        </ModalHeader>
        <ModalContent>{t(`${translationKey}.locked`)}</ModalContent>
        <ModalFooter>
          <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
        </ModalFooter>
      </ModalGrid>
    );
  }

  return (
    <ModalGrid>
      <ModalHeader>
        <HeaderLine>
          <h2>{t(`${translationKey}.title`)}</h2>
          {!hasEssentialsSubscription && <UpgradeIcon />}
        </HeaderLine>
        <KoalaIconButton iconName="close" onClick={handleCancel} />
      </ModalHeader>
      <ModalContent className="hide-overflow">
        {!hasEssentialsSubscription && (
          <ReactTooltip
            place="bottom"
            type="dark"
            id="globalPermissionSelector"
            className="tooltip"
            effect="solid"
            delayShow={200}
          >
            {t(`${translationKey}.upgradeLock`)}
          </ReactTooltip>
        )}
        {hasEssentialsSubscription && lockingDisabledBecauseNoEditors && (
          <ReactTooltip
            place="bottom"
            type="dark"
            id="globalPermissionSelector"
            className="tooltip"
            effect="solid"
            delayShow={200}
          >
            {t(`${translationKey}.oneEditor`)}
          </ReactTooltip>
        )}
        <OwnerLine className="header">
          <span>{t(`${translationKey}.everyone`)}</span>
          <select
            value={plan.global_permission_type}
            onChange={handleGlobalPermissionChange}
            data-tip
            data-for="globalPermissionSelector"
            disabled={!hasEssentialsSubscription || lockingDisabledBecauseNoEditors}
          >
            <option value="edit">{t(`${translationKey}.edit`)}</option>
            <option value="view">{t(`${translationKey}.view`)}</option>
            <option value="none">{t(`${translationKey}.noAccess`)}</option>
          </select>
          {isLoading && <Loader />}
        </OwnerLine>
        <Block>
          <label className="subtle">
            {t(`${translationKey}.permissions`)} {isUpdatingPlanPermissions && <Loader size="small" />}
          </label>
          {!hasEssentialsSubscription && (
            <Help>
              <Fragment>
                {currentMembership && ['owner'].includes(currentMembership.role) && (
                  <p>
                    <Trans
                      i18nKey={`${translationKey}.upgrade`}
                      components={{ link: <Link to={`/${workspaceSlug}/settings/billing`} /> }}
                    />
                  </p>
                )}
                {currentMembership && !['owner'].includes(currentMembership.role) && (
                  <p>
                    <Trans i18nKey={`${translationKey}.upgrade`} components={{ link: <b /> }} />
                  </p>
                )}
              </Fragment>
            </Help>
          )}

          {hasEssentialsSubscription && (
            <div>
              <UserSearchableDropdown
                trigger={
                  <OwnerOption className="active option-button">
                    <AddPersonIcon /> <span className="owner-option-name">{t(`${translationKey}.addEditor`)}</span>
                  </OwnerOption>
                }
                // @ts-ignore
                callback={handleAddPlanPermission}
                position="left"
              />
            </div>
          )}
          {planPermissions.map((planPermission: any) => {
            const membership: Membership = planPermission.membership;
            // Prevent removing editor if the plan is locked
            const removeDisabledBecausePlanLocked =
              editPlanPermissions.length === 1 &&
              plan.global_permission_type !== 'edit' &&
              planPermission.permission_type === 'edit';

            return (
              <OwnerLine key={planPermission.id}>
                <NameContainer>
                  <OwnerOption className="active" data-plan-permission-id={planPermission.id} key={planPermission.id}>
                    <KoalaAvatar membership={membership} size={2.2} tooltipType="none" />
                    <span className="owner-option-name">{membership.cached_fullname ?? membership.cached_email}</span>
                  </OwnerOption>
                </NameContainer>
                <select
                  value={planPermission.permission_type}
                  onChange={handleUpdatePlanPermission}
                  data-plan-permission-id={planPermission.id}
                >
                  <option value="edit">{t(`${translationKey}.edit`)}</option>
                  <option value="view">{t(`${translationKey}.view`)}</option>
                </select>
                <div data-tip data-for="remove-disabled">
                  <KoalaTextButton
                    disabled={removeDisabledBecausePlanLocked}
                    onClick={() => handleDeletePlanPermission(planPermission.id)}
                  >
                    {t('shared.remove')}
                  </KoalaTextButton>
                </div>
                {hasEssentialsSubscription && removeDisabledBecausePlanLocked && (
                  <ReactTooltip
                    place="bottom"
                    type="dark"
                    id="remove-disabled"
                    className="tooltip"
                    effect="solid"
                    delayShow={200}
                  >
                    {t(`${translationKey}.oneEditor`)}
                  </ReactTooltip>
                )}
              </OwnerLine>
            );
          })}
        </Block>
      </ModalContent>
      <ModalFooter>
        <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
      </ModalFooter>
    </ModalGrid>
  );
}

export default EditPlanPermissions;
