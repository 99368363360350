import React, { useCallback, useEffect, useState } from 'react';
import ReactFlow, {
  Edge,
  Node,
  Background,
  Controls,
  useReactFlow,
  NodeMouseHandler,
  OnNodesChange,
  OnEdgesChange,
  applyNodeChanges,
  applyEdgeChanges,
} from 'reactflow';
import 'reactflow/dist/style.css';
import styled from 'styled-components';
import PlanNode from './PlanNode';
import theme from 'theme';
import WorkspaceNode from './WorkspaceNode';
import useExpandCollapse from './usExpandCollapse';
import { useHistory, useLocation } from 'react-router-dom';
import { Base64 } from 'js-base64';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const nodeTypes = {
  plan: PlanNode,
  workspace: WorkspaceNode,
};

interface Props {
  initialEdges: Edge[];
  initialNodes: Node[];
}

function Map(props: Props) {
  const { initialEdges, initialNodes } = props;
  const history = useHistory();
  const location = useLocation();
  const reactFlowInstance = useReactFlow();
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);

  const onNodesChange: OnNodesChange = useCallback((changes) => {
    setNodes((nds) => applyNodeChanges(changes, nds));
  }, []);
  const onEdgesChange: OnEdgesChange = useCallback((changes) => setEdges((eds) => applyEdgeChanges(changes, eds)), []);

  // set expanded when arrow is clicked in PlanNode
  const handleNodeExpand: NodeMouseHandler = useCallback(
    (_, node) => {
      const expandedPlans: any = {};
      const newExpandState = !node.data.expanded;
      const levelNumber = node.data.path.split('/').length - 1;
      const updateURL = (mapStateHash: { [key: string]: any }) => {
        const encodedHash = Base64.encode(JSON.stringify(mapStateHash));
        history.push(location.pathname + '?mapState=' + encodedHash);
      };
      setNodes((nds) =>
        nds.map((n) => {
          if (n.type === 'plan') {
            if (n.id === node.id) {
              if (newExpandState) {
                expandedPlans[node.id] = true;
              }
              return {
                ...n,
                data: { ...n.data, expanded: newExpandState },
              };
            } else {
              const nlevelNumber = n.data.path.split('/').length - 1;
              if (newExpandState === true) {
                // collapse all plans with level number greater than the current plan and not in the same branch
                if (nlevelNumber >= levelNumber && !n.data.path.includes(node.data.path)) {
                  return {
                    ...n,
                    data: { ...n.data, expanded: false },
                  };
                } else {
                  if (n.data.expanded) {
                    expandedPlans[n.id] = true;
                  }
                }
              } else {
                if (n.data.expanded) {
                  expandedPlans[n.id] = true;
                }
              }
            }
          }
          return n;
        }),
      );
      updateURL({ expandedPlans });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setNodes],
  );

  // set expanded when arrow is clicked in PlanNode
  const handleNodeExpandOutcomes: NodeMouseHandler = useCallback(
    (_, node) => {
      setNodes((nds) =>
        nds.map((n) => {
          if (n.id === node.id) {
            let height = 200;
            let width = 350;
            if (!n.data.outcomesExpanded) {
              // we're about to expand the outcomes.
              height = 600;
              width = 600;
            }
            return {
              ...n,
              data: { ...n.data, outcomesExpanded: !n.data.outcomesExpanded },
              style: { height, width, maxHeight: height, maxWidth: width },
            };
          }

          return n;
        }),
      );
    },
    [setNodes],
  );

  // when initial values change, set edges and nodes
  useEffect(() => {
    setEdges([...initialEdges]);
    // pass handleNodeExpand callback to nodes
    setNodes(() =>
      initialNodes.map((node) => {
        return {
          ...node,
          data: {
            ...node.data,
            handleExpand: handleNodeExpand,
            handleExpandOutcomes: handleNodeExpandOutcomes,
          },
        };
      }),
    );
  }, [handleNodeExpand, initialEdges, initialNodes, handleNodeExpandOutcomes]);

  // This function filters the nodes to avoid duplicate
  // This works because findIndex always returns the first element found in an array
  // See https://stackoverflow.com/questions/2218999/how-to-remove-all-duplicates-from-an-array-of-objects
  const filteredNodes = nodes.filter(
    (node, index, self) => index === self.findIndex((reference) => reference.id === node.id),
  );
  // This function filters the edges to avoid duplicate (see above)
  const filteredEdges = edges.filter(
    (edge, index, self) => index === self.findIndex((reference) => reference.id === edge.id),
  );

  // handle expand, collapse and layout
  const { nodes: visibleNodes, edges: visibleEdges } = useExpandCollapse(filteredNodes, filteredEdges, {
    layoutNodes: true,
  });

  // fit graph in screen
  useEffect(() => {
    const timer = setTimeout(() => {
      reactFlowInstance.fitView({ duration: 300, maxZoom: 0.8, minZoom: 0.8 });
    }, 200); // Adjust the delay (in milliseconds) as needed

    return () => clearTimeout(timer);
  }, [reactFlowInstance]);

  return (
    <Container>
      <ReactFlow
        nodes={visibleNodes}
        edges={visibleEdges}
        nodeTypes={nodeTypes}
        snapToGrid={true}
        minZoom={0.1}
        maxZoom={2}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        style={{ background: theme.colors.N5 }}
        proOptions={{ account: 'paid-pro', hideAttribution: true }}
        defaultViewport={{
          x: 0,
          y: 0,
          zoom: 0.8,
        }}
      >
        <Background color={theme.colors.N20} size={2} />
        <Controls />
      </ReactFlow>
    </Container>
  );
}

export default Map;
