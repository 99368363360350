import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation, useQueryCache } from 'react-query';
import styled from 'styled-components';
import { OutcomeSectionMeta, RetrospectiveSection } from 'types';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import theme from 'theme';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import RadioField from 'components/RadioField';
import FormField from 'components/FormField';
import MentionBox from 'components/MentionBox';
import { useTranslation } from 'react-i18next';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';

const NewSection = styled.div`
  padding: 1.2rem ${theme.spacing.x2};
  min-height: 15rem;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  justify-content: start;
  border: 1px solid ${theme.colors.N10};
  border-radius: 8px;

  .radio-group {
    margin-top: ${theme.spacing.x1};
  }
`;
const Actions = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
`;

interface Props {
  section: RetrospectiveSection;
  setShowEdit: (value: string) => void;
}

function OutcomeSectionEdit(props: Props) {
  const { setShowEdit, section } = props;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const queryCache = useQueryCache();
  const sectionMeta: OutcomeSectionMeta = JSON.parse(section.section_meta);
  const { id } = section;
  const { t } = useTranslation();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const translationKey = 'workspaceRetrospective';
  const [confidence, setConfidence] = useState(sectionMeta.outcome_grade_color ?? '');
  const [gradeLabel, setGradeLabel] = useState(sectionMeta.outcome_grade_label ?? '');
  const [currentText, setCurrentText] = useState(section ? section.body : '');
  const [outcomeProgress, setOutcomeProgress] = useState(sectionMeta.score);

  const [updateSectionMutation, { isLoading: isUpdating }] = useMutation(remoteApi.updateRetrospectiveSection, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.retrospectiveSections, section.retrospective_id]);
      setShowEdit('');
    },
  });

  // Function that updates the section
  const handleSave = useCallback(() => {
    const meta: OutcomeSectionMeta = {
      ...sectionMeta,
      outcome_grade_color: confidence,
      outcome_grade_label: gradeLabel,
      score: outcomeProgress,
    };
    const mutationParams = {
      retrospectiveSectionId: id,
      retrospectiveSection: { section_type: 'outcome', body: currentText, section_meta: JSON.stringify(meta) },
    };
    updateSectionMutation(mutationParams);
  }, [confidence, currentText, id, sectionMeta, updateSectionMutation, gradeLabel, outcomeProgress]);

  useEffect(() => {
    // if in editor and click outside, will save progress and switch back to view mode
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        handleSave();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, setShowEdit, handleSave]);

  const changeConfidence = (el: React.ChangeEvent<HTMLInputElement>) => {
    const value = el.target.value;
    setConfidence(value);
  };

  const gradePlaceholder = t(`${translationKey}.gradePlaceholder`) ?? 'Enter a label for the grade';

  const outcomeLabel = translate(currentWorkspace, CustomTermKey.OUTCOME, 1).toLowerCase();
  const outcomePlaceholder = t(`${translationKey}.outcomeProgressPlaceholder`) ?? 'Enter a percentage for the outcome';
  const textPlaceholder =
    t(`${translationKey}.outcomeDescriptionPlaceholder`, { outcome: outcomeLabel }) ??
    'Add additional text for the outcome';
  return (
    <NewSection ref={wrapperRef}>
      <h3>{sectionMeta.outcome_title}</h3>
      <RadioField>
        <label>{t(`${translationKey}.grade`)}</label>
        <input
          type="text"
          name="gradeLabel"
          placeholder={gradePlaceholder}
          value={gradeLabel}
          required={false}
          onChange={(e) => setGradeLabel(e.target.value)}
        />
        <div className="radio-group">
          <input
            type="radio"
            id="green"
            checked={confidence === 'green'}
            name="confidence"
            value={'green'}
            onChange={changeConfidence}
          />
          <label htmlFor="green" className="green">
            {t(`${translationKey}.gradeGreen`)}
          </label>
          <input
            type="radio"
            id="yellow"
            checked={confidence === 'yellow'}
            name="confidence"
            value={'yellow'}
            onChange={changeConfidence}
          />
          <label htmlFor="yellow" className="yellow">
            {t(`${translationKey}.gradeYellow`)}
          </label>
          <input
            type="radio"
            id="red"
            checked={confidence === 'red'}
            name="confidence"
            value={'red'}
            onChange={changeConfidence}
          />
          <label htmlFor="red" className="red">
            {t(`${translationKey}.gradeRed`)}
          </label>
        </div>
      </RadioField>

      <FormField>
        <label>{t(`${translationKey}.outcomeScore`, { outcome: outcomeLabel })}</label>
        <input
          name="outcomeProgress"
          placeholder={outcomePlaceholder}
          value={outcomeProgress}
          required={true}
          onChange={(e) => setOutcomeProgress(e.target.value)}
        />
      </FormField>
      <FormField>
        <label>{t(`${translationKey}.description`)}</label>

        <MentionBox setValue={setCurrentText} value={currentText} placeholder={textPlaceholder} allowHeaders={true} />
      </FormField>
      <Actions>
        <KoalaButton onClick={handleSave} loading={isUpdating}>
          {t(`shared.save`)}
        </KoalaButton>
        <KoalaTextButton onClick={() => setShowEdit('')}>{t(`shared.cancel`)}</KoalaTextButton>
      </Actions>
    </NewSection>
  );
}

export default React.memo(OutcomeSectionEdit);
