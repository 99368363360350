import React, { useState } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useSelector, shallowEqual } from 'react-redux';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { Trans, useTranslation } from 'react-i18next';
import ProgressBarV2 from 'components/ProgressBarV2';
import ConfidenceBar from 'components/ConfidenceBar';

// API
import * as remoteApi from 'api/remote';

// Components
import theme from 'theme';
import KoalaIcon from 'koala/components/Icons';
import ReactTooltip from 'react-tooltip';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: ${theme.spacing.x2};
  border-bottom: 1px solid ${theme.colors.blockBorder};
  padding: ${theme.spacing.x2};
`;

const DataContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${theme.spacing.x1};
  position: relative;

  h4 {
    font-weight: 600;
  }
  small {
    display: flex;
    gap: ${theme.spacing.half};
    align-items: center;
  }
`;

interface Props {
  currentMembership: TabilityTypes.Membership;
}

function MembershipInsights(props: Props) {
  const { currentMembership } = props;
  const { t } = useTranslation();

  const [membership, setMembership] = useState<TabilityTypes.Membership>(currentMembership);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const queryKeyMembership = [queryKeys.currentMembership, props.currentMembership.id];

  useQuery(queryKeyMembership, remoteApi.fetchMembershipDetails, {
    staleTime: 0,
    onSuccess: (response) => {
      const membership = response.data;
      setMembership(membership);
    },
  });

  return (
    <Container>
      <DataContainer>
        <small>{translate(currentWorkspace, CustomTermKey.OUTCOME, 2)}</small>
        <ProgressBarV2
          percentage={membership.outcome_progress_prct * 100}
          totalCount={membership.total_outcomes_count}
          label={`${Math.round(membership.outcome_progress_prct * 100)}%`}
        />
      </DataContainer>
      <DataContainer>
        <small>{translate(currentWorkspace, CustomTermKey.INITIATIVE, 2)}</small>
        <ProgressBarV2
          percentage={membership.initiative_progress_prct * 100}
          totalCount={membership.total_initiatives_count}
          label={`${membership.closed_initiatives_count}/${membership.total_initiatives_count}`}
        />
      </DataContainer>
      <DataContainer>
        <small>
          {t('components.profileCard.confidence')}
          <KoalaIcon iconName="tooltip" iconSize="small" data-tip data-for="explain-ncs" />
        </small>
        <ConfidenceBar
          totalCount={membership.total_outcomes_count}
          redCount={membership.red_outcomes_count}
          yellowCount={membership.yellow_outcomes_count}
          greenCount={membership.green_outcomes_count}
          greyCount={membership.grey_outcomes_count}
          ncs={Math.round(membership.ncs)}
        />
        <ReactTooltip
          place="bottom"
          type="dark"
          className="tooltip"
          effect="solid"
          id="explain-ncs"
          delayHide={500}
          clickable={true}
        >
          <Trans i18nKey="shared.ncs.tooltip" components={{ br: <br /> }} />{' '}
          <a
            href="https://www.tability.io/odt-articles/nps-but-for-your-okrs-introducing-the-net-confidence-score-ncs"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('shared.learnMore')}
          </a>
        </ReactTooltip>
      </DataContainer>
    </Container>
  );
}

export default MembershipInsights;
