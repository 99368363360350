import React from 'react';

const CelebrationIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4_13498)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9643 3.43121C10.8572 3.10308 10.5043 2.92391 10.1762 3.03103C9.84806 3.13814 9.66889 3.49099 9.77601 3.81912C10.0801 4.75063 10.0401 5.76029 9.66322 6.6648C9.53047 6.98343 9.68115 7.34935 9.99978 7.4821C10.3184 7.61485 10.6843 7.46417 10.8171 7.14554C11.309 5.96492 11.3612 4.64706 10.9643 3.43121ZM13.1208 5.56112C13.4627 5.51358 13.7783 5.75221 13.8259 6.0941C13.9174 6.75252 13.7651 7.42169 13.3977 7.97565C13.2069 8.26329 12.819 8.3418 12.5314 8.15099C12.2437 7.96019 12.1652 7.57233 12.356 7.28468C12.5549 6.98483 12.6374 6.62262 12.5878 6.26623C12.5403 5.92434 12.7789 5.60865 13.1208 5.56112ZM13.3517 9.31048C13.9981 9.16371 14.6759 9.2612 15.2547 9.58419C15.5561 9.7524 15.6641 10.1331 15.4959 10.4345C15.3277 10.7359 14.947 10.8439 14.6455 10.6757C14.3362 10.5031 13.974 10.451 13.6285 10.5294C13.2919 10.6059 12.9571 10.395 12.8807 10.0584C12.8042 9.72175 13.0151 9.38692 13.3517 9.31048ZM15.0001 6.24999C15.0001 5.6977 15.4478 5.24999 16.0001 5.24999C16.5524 5.24999 17.0001 5.6977 17.0001 6.24999C17.0001 6.80227 16.5524 7.24999 16.0001 7.24999C15.4478 7.24999 15.0001 6.80227 15.0001 6.24999ZM6.79728 6.91476C7.08426 6.84093 7.38522 6.84075 7.67228 6.91423C7.95934 6.98771 8.2232 7.13249 8.4394 7.33512L8.45103 7.34638L12.641 11.5364L12.646 11.5414C12.8507 11.7519 12.999 12.0105 13.0775 12.2934C13.156 12.5763 13.162 12.8744 13.0951 13.1602C13.0282 13.4461 12.8904 13.7105 12.6945 13.9292C12.4996 14.1466 12.2535 14.3119 11.9784 14.4098L5.30211 16.8984L5.2945 16.9013C4.9927 17.0083 4.66701 17.0287 4.35415 16.9606C4.04129 16.8925 3.75365 16.7384 3.52361 16.5157C3.29357 16.293 3.13027 16.0105 3.05207 15.7C2.97388 15.3895 2.98391 15.0634 3.08104 14.7583C3.08343 14.7508 3.086 14.7433 3.08874 14.7359L5.56544 8.0648C5.65757 7.78722 5.81745 7.53693 6.03068 7.33659C6.24663 7.13369 6.51031 6.98859 6.79728 6.91476Z"
        fill="#408C76"
      />
    </g>
    <defs>
      <clipPath id="clip0_4_13498">
        <rect width="14" height="14" fill="white" transform="translate(3 3)" />
      </clipPath>
    </defs>
  </svg>
);

export default CelebrationIcon;
