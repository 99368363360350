/**
  This is the playground editor. It loads the state from local storage and saves there too
 */

import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useSelector, shallowEqual } from 'react-redux';

// Routes
import * as routes from 'routes';

// Components
import { NavLink } from 'react-router-dom';
import KoalaAvatar from 'koala/components/Avatar';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.x4} ${theme.spacing.x4};
  align-items: flex-start;
  width: 30rem;
  justify-content: flex-start;
  background: #f3f3f3;
  height: 100%;

  a {
    width: 100%;
    color: ${theme.colors.subtleText};
    margin: 0;
    margin-bottom: ${theme.spacing.x1};
    border-bottom: 2px solid transparent;
    padding-bottom: 0.4rem;
    font-weight: 400;

    &:hover {
      background: #f3f3f3;
      border-bottom: 2px solid ${theme.colors.green};
      text-decoration: none;
    }
    &.active {
      color: ${theme.colors.black};
      border-bottom: 2px solid ${theme.colors.green};
    }
  }

  @media ${theme.devices.tablet} {
    width: 17rem;
    padding: ${theme.spacing.x2};
  }
`;

const Header = styled.div`
  margin-bottom: ${theme.spacing.x2};
  text-transform: uppercase;
  color: ${theme.colors.green};
  letter-spacing: 1px;
  font-size: 1rem;
  font-weight: 800;
`;

const Profile = styled.div`
  > div {
    margin-bottom: ${theme.spacing.x1};
  }
`;

function InboxNav() {
  const { t } = useTranslation();
  const currentUser = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const name = currentUser.fullname || currentUser.email;
  const displayEmail = currentUser.fullname ? true : false;

  return (
    <Container>
      <Profile>
        <KoalaAvatar membership={{ ...currentMembership, user: currentUser }} size="xlarge" tooltipType="none" />
        <div className="name">{name}</div>
        {displayEmail && (
          <div>
            <small>{currentUser.email}</small>
          </div>
        )}
      </Profile>
      <Header>{t('account.account')}</Header>
      <NavLink to={routes.ACCOUNT_ROOT_ROUTE} exact={true}>
        {t('account.details')}
      </NavLink>
      <NavLink to={routes.ACCOUNT_EMAIL_ROUTE} exact={true}>
        {t('account.email')}
      </NavLink>
      <Header>{t('account.security')}</Header>
      <NavLink to={routes.ACCOUNT_PASSWORD_ROUTE} exact={true}>
        {t('account.password')}
      </NavLink>
      <NavLink to={routes.ACCOUNT_2FA_ROUTE} exact={true}>
        {t('account.2FA')}
      </NavLink>
      <Header>{t('account.danger')}</Header>
      <NavLink to={routes.ACCOUNT_DELETE_ROUTE} exact={true}>
        {t('account.delete')}
      </NavLink>
      <Header>{t('account.rewards')}</Header>
      <NavLink to={routes.ACCOUNT_REFERRAL_ROUTE} exact={true}>
        {t('account.refer')}
      </NavLink>
    </Container>
  );
}

export default InboxNav;
