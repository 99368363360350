import React, { ReactElement } from 'react';

import { PanelGrid, PanelHeader } from 'components/GlobalPanel';
import styled from 'styled-components';
import theme from 'theme';
import { Link, useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PlanIconLabel from 'components/PlanIconLabel';
import { Initiative } from 'types';
import DropdownMenu from 'components/DropdownMenu';
import KoalaIconButton from 'koala/components/IconButton';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import { useMutation, useQueryCache } from 'react-query';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { useTranslation } from 'react-i18next';
import * as integrationUtils from 'utils/integrationUtils';
import KoalaTextBadge from 'koala/components/TextBadge';
import KoalaButton from 'koala/components/Button';
import KoalaAvatar from 'koala/components/Avatar';
import TagPickerInitiative from 'components/TagPickerInitiative';
import InitiativeDueDate from 'components/InitiativeDueDate';
import KoalaCheckbox from 'koala/components/Checkbox';
import OutcomeExpandable from 'components/OutcomeExpandable';
import InitiativeLinksTab from './InitiativeLinksTab';

const IntegrationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  align-items: center;
  justify-content: center;
`;

const TagPicker = styled.div`
  display: flex;
  width: 100%;

  label {
    display: none;
  }
  .tag-container,
  .tag-select {
    width: 100%;
  }
  .tag-picker {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 100%;
  }
`;
const AvatarLine = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
`;

const InfoLine = styled.div`
  display: flex;
  font-size: 1.2rem;
  margin-bottom: ${theme.spacing.x2};
  align-items: center;

  &.align-top {
    align-items: start;
  }
  label {
    color: ${theme.colors.subtleText};
    width: 8rem;
    min-width: 8rem;
  }
`;
const CustomPanelContent = styled.div`
  padding: 0;
  display: grid;
  grid-template-columns: auto 40rem;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'description info'
    'comments info';
  overflow-y: auto;

  .panel-tasks {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x2};
  }
`;

const CommentColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  border-right: 1px solid ${theme.colors.inputBorder};
  padding: 1.2rem ${theme.spacing.x2};
  width: 100%;
  grid-area: comments;
  h4 {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 800;
  }
`;
const DescriptionColumn = styled(CommentColumn)`
  grid-area: description;
  border-bottom: 1px solid ${theme.colors.N20};
`;
const InfoColumn = styled.div`
  background: ${theme.colors.N5};
  padding: 1.2rem ${theme.spacing.x2};
  grid-area: info;
  h4 {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 800;
    margin-bottom: ${theme.spacing.x2};
  }
`;

const InitiativeTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};

  .inline-text {
    font-size: 1.6rem;
  }
`;

const IntegrationIcon = styled.img`
  width: 2.4rem;
`;

const CustomPanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  .plan-link {
    font-weight: 600;
    color: ${theme.colors.subtleText};
  }
`;

const HeaderActions = styled.div`
  display: flex;
  gap: ${theme.spacing.x2};
`;

interface Props {
  initiative: Initiative;
}

function IntegrationInitiative(props: Props) {
  const { initiative } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const translationKey = 'modals.initiative';
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { plan } = initiative;

  const [archiveInitiativeMutation] = useMutation(remoteApi.archiveInitiative, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.initiatives);
      queryCache.invalidateQueries(queryKeys.currentInitiative);
    },
  });

  const [deleteInitiativeMutation] = useMutation(remoteApi.deleteInitiative, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.initiatives);
      history.goBack();
    },
  });

  const archiveInitiative = () => {
    const mutationParams = {
      initiativeId: initiative.id,
      initiative: {
        archived: true,
      },
    };
    archiveInitiativeMutation(mutationParams);
  };

  const unArchiveInitiative = () => {
    const mutationParams = {
      initiativeId: initiative.id,
      initiative: {
        archived: false,
      },
    };
    archiveInitiativeMutation(mutationParams);
  };

  const deleteInitiative = () => {
    if (window.confirm(`Delete this ${translate(currentWorkspace, CustomTermKey.INITIATIVE, 1)}?`)) {
      deleteInitiativeMutation(initiative.id);
    }
  };

  const handleMenuSelection = (value: ReactElement) => {
    const action = value.props['data-action'];
    const blockId = `initiative:${initiative.nano_slug}`;
    switch (action) {
      case 'move':
        dispatch(setGlobalModalContent(`${blockId}:move`));
        break;
      case 'archive':
        archiveInitiative();
        break;
      case 'unarchive':
        unArchiveInitiative();
        break;
      case 'delete':
        deleteInitiative();
        break;
    }
  };

  const menuItems = [
    <span key="move" data-action="move">
      {t('shared.move')}
    </span>,
  ];

  if (!initiative.archived) {
    menuItems.push(
      <span key="archive" data-action="archive">
        {t('shared.archive')}
      </span>,
    );
  } else {
    menuItems.push(
      <span key="unarchive" data-action="unarchive">
        {t('shared.unarchive')}
      </span>,
    );
  }
  menuItems.push(
    <span key="delete" data-action="delete">
      {t('shared.delete')}
    </span>,
  );

  const handleCancel = (e: Event) => {
    e.preventDefault();
    history.goBack();
  };

  const imageSource = integrationUtils.getIntegrationImageSource(initiative.integration_type);

  const assignee = integrationUtils.getIntegrationUser(initiative);

  const displayName = assignee ? assignee.name : 'Not assigned';
  const isComplete = integrationUtils.isIntegrationClosed(initiative);
  const integrationStatus = integrationUtils.getIntegrationStatus(initiative);
  const dueDate = integrationUtils.getDueDate(initiative);
  const integrationType = integrationUtils.getIntegrationType(initiative);

  return (
    <PanelGrid>
      <PanelHeader>
        <CustomPanelHeader>
          <HeaderTitle>
            <Link className="plan-link hide-mobile" to={`/${currentWorkspace.slug}/plans/${plan.nano_slug}/outcomes`}>
              <PlanIconLabel plan={plan} size="small" />
            </Link>
          </HeaderTitle>
          <HeaderActions>
            <DropdownMenu
              trigger={<KoalaIconButton iconName="ellipsis" />}
              onSelection={handleMenuSelection}
              items={menuItems}
            />
            <KoalaIconButton iconName="close" onClick={handleCancel} />
          </HeaderActions>
        </CustomPanelHeader>
        <InitiativeTitle>
          <KoalaCheckbox checked={isComplete} disabled />

          {imageSource && <IntegrationIcon src={imageSource} />}
          <h2>{initiative.integration_title}</h2>
          {initiative.archived && (
            <KoalaTextBadge className="archive-label" isLowercase={true} variant="violet-light">
              {t('shared.status.archived')}
            </KoalaTextBadge>
          )}
        </InitiativeTitle>
      </PanelHeader>
      <CustomPanelContent>
        <DescriptionColumn>
          <h4>
            {t('shared.related')} {translate(currentWorkspace, CustomTermKey.OUTCOME, 1)}
          </h4>
          <OutcomeExpandable
            outcome={initiative.outcome}
            hideExpand={true}
            hideActions={true}
            hideOwners={true}
            hideTags={true}
          />
        </DescriptionColumn>
        <CommentColumn>
          <IntegrationContainer>
            <p className="subtle">
              {t(`${translationKey}.integration`, {
                initiative: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1).toLowerCase(),
                integration: integrationType,
              })}
            </p>
            <KoalaButton appearance="subtle" href={initiative.title} target="_blank">
              {t(`${translationKey}.viewLink`, { label: integrationType })}
            </KoalaButton>
          </IntegrationContainer>
        </CommentColumn>
        <InfoColumn>
          <InfoLine>
            <label>{t(`${translationKey}.link`)}</label>
            <KoalaButton appearance="subtle" size="small" href={initiative.title} target="_blank">
              {t(`${translationKey}.viewLink`, { label: integrationType })}
            </KoalaButton>
          </InfoLine>
          <InfoLine>
            <label>{t(`${translationKey}.status`)}</label>
            <KoalaTextBadge variant={isComplete ? 'neutral-light' : 'blue-light'} isLowercase>
              {integrationStatus}
            </KoalaTextBadge>
          </InfoLine>
          <InfoLine>
            <label>{t(`panels.dueDate`)}</label>
            <InitiativeDueDate dueAt={dueDate} state={initiative.state} />
          </InfoLine>
          <InfoLine>
            <label>{t(`${translationKey}.owner`)}</label>
            <AvatarLine>
              <KoalaAvatar
                integrationType={initiative.integration_type}
                integrationAssignee={assignee}
                size="small"
                tooltipType="none"
              />
              <span>{displayName}</span>
            </AvatarLine>
          </InfoLine>
          <InfoLine>
            <label>{t(`${translationKey}.tags`)}</label>
            <TagPicker>
              <TagPickerInitiative initiative={initiative} />
            </TagPicker>
          </InfoLine>
          <h4>{t(`panels.shared.links`)}</h4>
          <InitiativeLinksTab initiative={initiative} />
        </InfoColumn>
      </CustomPanelContent>
    </PanelGrid>
  );
}

export default IntegrationInitiative;
