import React, { memo } from 'react';
import queryKeys from 'config/queryKeys';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import theme from 'theme';
import { Objective, Outcome, Plan } from 'types';
import * as remoteApi from 'api/remote';
import KoalaLoader from 'koala/components/Loader';
import OutcomeBlockCompact from './OutcomeBlockCompact';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  width: 100%;
  flex: 1;

  .expand-plan-button {
    transition: all ease 0.2s;
    transform: rotate(90deg);
    &.plan--expanded {
      transform: rotate(-90deg);
    }
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  border-top: 1px solid ${theme.colors.N10};
  padding: ${theme.spacing.x1};
  max-height: 40rem;
  overflow: auto;
`;

const ObjectiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.half};
  align-items: center;
  width: 100%;
`;
const ObjectiveTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: ${theme.spacing.half};
  gap: ${theme.spacing.half};

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 10rem;
    width: inherit;
    font-weight: 600;
  }
`;

function PlanContent(props: { plan: Plan }) {
  const { plan } = props;

  // Set the stale time to 5 minutes
  const staleTime = 1000 * 60 * 5;

  // Get the objectives
  const { isLoading: objectivesAreLoading, data: objectivesData } = useQuery(
    [queryKeys.objectives, plan.id],
    remoteApi.fetchPlanObjectives,
    {
      staleTime,
    },
  );

  // Get the outcomes
  const { isLoading: outcomesAreLoading, data: outcomesData } = useQuery(
    [queryKeys.outcomes, plan.id],
    remoteApi.fetchPlanOutcomes,
    {
      staleTime,
    },
  );

  if (objectivesAreLoading || outcomesAreLoading) {
    return <KoalaLoader />;
  }

  const objectives: Objective[] = objectivesData?.data || [];
  const outcomes: Outcome[] = outcomesData?.data || [];

  const outcomesByObjective: { [key: string]: Outcome[] } = {};
  outcomes.forEach((outcome: Outcome) => {
    if (outcomesByObjective[outcome.objective_id]) {
      outcomesByObjective[outcome.objective_id].push(outcome);
    } else {
      outcomesByObjective[outcome.objective_id] = [outcome];
    }
  });

  return (
    <Container>
      {objectives.length > 0 && (
        <Content className={`nowheel`}>
          {objectives.map((objective: Objective) => {
            const outcomesToDisplay = outcomesByObjective[objective.id] || [];

            return (
              <ObjectiveContainer key={objective.id}>
                <ObjectiveTitle>
                  <p>{objective.title}</p>
                </ObjectiveTitle>
                {outcomesToDisplay.map((outcome) => (
                  <OutcomeBlockCompact isSelected={false} outcome={outcome} key={outcome.id} />
                ))}
              </ObjectiveContainer>
            );
          })}
        </Content>
      )}
    </Container>
  );
}

export default memo(PlanContent);
