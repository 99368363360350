/**
 * Reviewers component
 */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

// UI Components
import AddPersonIcon from 'components/_assets/AddPersonIcon';
import Loader from 'components/Loader';
import ReactTooltip from 'react-tooltip';
import KoalaAvatar from 'koala/components/Avatar';
import { useTranslation } from 'react-i18next';
import { Plan } from 'types';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  > div {
    margin-right: ${theme.spacing.x1};
    margin-bottom: ${theme.spacing.x1};
  }
`;

const AddOwnerButton = styled.div`
  height: 2rem;
  width: 2rem;
  background-color: #e7e7e7;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin-right: 3.2rem;
  border-radius: 50%;
  svg {
    width: 1.6rem;
    height: 1.6rem;
    g {
      stroke: ${theme.colors.subtleText};
    }
  }

  &:hover {
    background: #d1d1d1;
  }
`;

interface Props {
  plan: Plan;
  isReadOnly?: boolean;
}

function Reviewers(props: Props) {
  const history = useHistory();
  const { plan, isReadOnly } = props;
  const { t } = useTranslation();
  const [reviewers, setReviewers]: any = useState([]);

  const reviewersQueryKey = [queryKeys.reviewers, plan.id];
  const staleTime = 0;
  const { isLoading } = useQuery(reviewersQueryKey, remoteApi.fetchPlanReviewers, {
    staleTime,
    onSuccess: (response) => {
      setReviewers(response.data);
    },
  });

  const blockId = `plan:${plan.id}`;

  const handleEditReviewers = () => {
    const hashPath = `#editor:${blockId}:edit.reviewers`;
    history.push(hashPath);
  };

  return (
    <Container>
      {reviewers.map((reviewer: any) => {
        const { membership } = reviewer;
        return <KoalaAvatar membership={membership} size={2} key={reviewer.id} tooltipType="card" />;
      })}
      {!isReadOnly && (
        <AddOwnerButton onClick={handleEditReviewers} data-tip data-for="plan-manage-reviewer">
          <AddPersonIcon />
        </AddOwnerButton>
      )}
      {isLoading && <Loader />}
      <ReactTooltip place="bottom" type="dark" id={`plan-manage-reviewer`} className="tooltip" effect="solid">
        {t('workspacePlan.write.manageReviewers.title')}
      </ReactTooltip>
    </Container>
  );
}

export default React.memo(Reviewers);
