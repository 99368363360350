import queryKeys from 'config/queryKeys';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import theme from 'theme';
import { Retrospective, RetrospectiveSection } from 'types';
import * as remoteApi from 'api/remote';
import { AxiosResponse } from 'axios';

import { useParams } from 'react-router-dom';

import { formatLocale } from 'utils/dateUtils';

import ObjectiveSectionView from 'components/Retrospectives/ObjectiveSectionView';
import TextSectionView from 'components/Retrospectives/TextSectionView';
import KoalaLoader from 'koala/components/Loader';
import { useTranslation } from 'react-i18next';
import PlanStatus from 'components/PlanStatus';
import PlanIconLabel from 'components/PlanIconLabel';
import KoalaTextBadge from 'koala/components/TextBadge';
import KoalaButton from 'koala/components/Button';
import OutcomeSectionView from 'components/Retrospectives/OutcomeSectionView';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  margin: ${theme.spacing.x2} auto;
  width: 100rem;
  background-color: ${theme.colors.N0};
  padding: ${theme.spacing.x4} ${theme.spacing.x5};
`;

const PlanContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${theme.spacing.x2};
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
    font-size: 2.8rem;
    font-weight: 700;
  }
`;

const SectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
`;

interface Props {
  retrospective: Retrospective;
}

function RetrospectivePage(props: Props) {
  const { retrospective } = props;
  const { plan } = retrospective;
  const { t, i18n } = useTranslation();
  const translationKey = 'publicRetrospective';

  const { workspaceSlug, planId } = useParams<{
    workspaceSlug: string;
    planId: string;
  }>();

  const [sections, setSections] = useState<RetrospectiveSection[]>([]);

  // Get the retrospective sections
  const sectionQueryKey = [queryKeys.retrospectiveSections, retrospective.id];
  const staleTime = 0;
  const { isLoading: isSectionsLoading } = useQuery(sectionQueryKey, remoteApi.fetchPublicRetrospectiveSections, {
    staleTime,
    onSuccess: (response: AxiosResponse<RetrospectiveSection[]>) => {
      const sections = response.data;
      setSections(sections);
    },
  });

  const planLink = `/${workspaceSlug}/plans/${planId}`;
  const retrospectiveLink = `/${workspaceSlug}/plans/${planId}/retrospectives/${retrospective.nano_slug}`;

  return (
    <Content>
      <Header>
        {plan && (
          <PlanContainer>
            <a href={planLink} target="_blank" rel="noopener noreferrer">
              <PlanIconLabel plan={plan} />
            </a>
            <PlanStatus plan={plan} />
          </PlanContainer>
        )}
        <KoalaButton isLink to={retrospectiveLink} size="small" appearance="secondary">
          {t(`publicPlan.viewButton`)}
        </KoalaButton>
      </Header>
      <Title>
        <h1>{retrospective.title}</h1>
        {retrospective.published_date && (
          <KoalaTextBadge variant="neutral-light" edge="circle" isLowercase size="small">
            {formatLocale(retrospective.published_date, 'dd MMM yyyy', i18n)}
          </KoalaTextBadge>
        )}
      </Title>
      <SectionsContainer>
        {isSectionsLoading && <KoalaLoader />}
        {!isSectionsLoading && sections.length === 0 && <div>{t(`${translationKey}.noSections`)}</div>}
        {!isSectionsLoading &&
          sections.map((section, i) => {
            if (section.section_type === 'text') {
              return <TextSectionView section={section} hideEmpty={true} key={i} />;
            } else if (section.section_type === 'objective') {
              return <ObjectiveSectionView section={section} key={i} />;
            } else if (section.section_type === 'outcome') {
              return <OutcomeSectionView section={section} key={i} />;
            } else {
              return <div key={i}>{t(`errors.notFound`)}</div>;
            }
          })}
      </SectionsContainer>
    </Content>
  );
}

export default RetrospectivePage;
