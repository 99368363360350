import React, { useRef } from 'react';
import { RetrospectiveSection } from 'types';
import styled from 'styled-components';
import theme from 'theme';
import KoalaIconButton from 'koala/components/IconButton';
import { useMutation, useQueryCache } from 'react-query';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import { useDrag, useDrop } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';
import KoalaIcon from 'koala/components/Icons';
import ObjectiveSectionEdit from './ObjectiveSectionEdit';
import TextSectionEdit from './TextSectionEdit';
import ObjectiveSectionView from 'components/Retrospectives/ObjectiveSectionView';
import TextSectionView from 'components/Retrospectives/TextSectionView';
import { useTranslation } from 'react-i18next';
import OutcomeSectionView from 'components/Retrospectives/OutcomeSectionView';
import OutcomeSectionEdit from './OutcomeSectionEdit';

const ViewWrapper = styled.div`
  grid-area: section;
  width: 100%;
  padding: ${theme.spacing.x1};
  .ql-display-only {
    padding: 0 !important;
  }

  :hover {
    background-color: ${theme.colors.N5};
    cursor: pointer;
  }
`;

const ViewContainer = styled.div`
  width: 100%;
`;

const Container = styled.div<{ isDragging: boolean }>`
  display: flex;
  opacity: ${({ isDragging }) => (isDragging ? 0 : 1)};
  min-height: 6rem;
  .hover-to-show {
    display: none;
  }
  :hover {
    .hover-to-show {
      display: unset;
    }
  }
`;
const DragContainer = styled.div`
  margin-left: -3rem;
  width: 3rem;

  :hover {
    cursor: grab;
  }
`;
const Actions = styled.div`
  grid-area: actions;
  width: 3rem;
  margin-right: -3rem;
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: ${theme.spacing.half};
`;

interface Props {
  section: RetrospectiveSection;
  isEditing: boolean;
  setEditSectionId: (value: string) => void;
  index: number;
  id: string;
  moveSection: (dragIndex: number, hoverIndex: number, itemId: string) => void;
  saveDrag: (id: string, index: number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

function Section(props: Props) {
  const { isEditing, section, setEditSectionId, id, index, moveSection, saveDrag } = props;
  const queryCache = useQueryCache();
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const translationKey = 'workspaceRetrospective';

  const [deleteSectionMutation, { isLoading: isDeleting }] = useMutation(remoteApi.deleteRetrospectiveSection, {
    onSuccess: (response) => {
      queryCache.invalidateQueries([queryKeys.retrospectiveSections, section.retrospective_id]);
    },
  });

  //handle drag and drop
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ['section', 'new-section'],
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveSection(dragIndex, hoverIndex, item.id);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'section',
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      saveDrag(item.id, item.index);
    },
  });

  if (isEditing) {
    switch (section.section_type) {
      case 'text':
        return <TextSectionEdit section={section} setShowEdit={setEditSectionId} />;
      case 'objective':
        return <ObjectiveSectionEdit section={section} setShowEdit={setEditSectionId} />;
      case 'outcome':
        return <OutcomeSectionEdit section={section} setShowEdit={setEditSectionId} />;
      default:
        return <div>{t(`${translationKey}.sectionNotFound`)}</div>;
    }
  }

  const handleClick = () => {
    setEditSectionId(section.id);
  };

  const handleDelete = () => {
    deleteSectionMutation(section.id);
  };

  const renderContent = () => {
    switch (section.section_type) {
      case 'text':
        return <TextSectionView section={section} />;
      case 'objective':
        return <ObjectiveSectionView section={section} />;
      case 'outcome':
        return <OutcomeSectionView section={section} />;
      default:
        return <div>{t(`${translationKey}.sectionNotFound`)}</div>;
    }
  };

  drag(drop(ref));

  return (
    <Container ref={ref} data-handler-id={handlerId} isDragging={isDragging}>
      <DragContainer>
        <KoalaIcon iconName="grab" className="hover-to-show" />
      </DragContainer>
      <ViewWrapper>
        <ViewContainer onClick={handleClick}>{renderContent()}</ViewContainer>
      </ViewWrapper>
      <Actions>
        <KoalaIconButton
          size="small"
          iconName="edit"
          className="hover-to-show"
          onClick={handleClick}
          loading={isDeleting}
        />
        <KoalaIconButton
          size="small"
          iconName="trash"
          className="hover-to-show"
          onClick={handleDelete}
          loading={isDeleting}
        />
      </Actions>
    </Container>
  );
}
export default React.memo(Section);
