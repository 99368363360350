import { format } from 'date-fns';
import { Emoji } from 'emoji-mart';
import KoalaAvatar from 'koala/components/Avatar';
import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import TabbySparkle from 'components/_assets/tabby_sparkle.png';
import { Membership } from 'types';
import { Trans } from 'react-i18next';
import { TFunction } from 'i18next';

const CardContent = styled.div`
  background-color: ${theme.colors.N0};
  width: 350px;
  height: 350px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  align-items: center;
  justify-content: center;
  letter-spacing: 0.01px;
  padding: ${theme.spacing.x4} ${theme.spacing.x2};
  cursor: default;
  user-select: none;
`;

const BigNumber = styled.div<{ textColor: string; size?: number }>`
  font-size: ${(props) => props.size || 120}px;
  font-weight: bold;
  line-height: 70%;
  color: ${(props) => props.textColor};
  font-family: 'Poppins', sans-serif;
`;

const BigText = styled.div<{ size?: number; isCenter?: boolean }>`
  font-size: ${(props) => props.size ?? 35}px;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  text-align: ${(props) => (props.isCenter ? 'center' : 'start')};
`;

const BigTextHighlight = styled.span<{ color?: string }>`
  color: ${(props) => props.color || theme.colors.B50};
`;

const EmojiRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.x1};
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const EmojiContainer = styled.div<{ size?: number }>`
  width: ${(props) => props.size ?? 85}px;
  height: ${(props) => props.size ?? 85}px;
  background-color: ${theme.colors.B5};
  border-radius: 4px;
  padding: ${theme.spacing.x1};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.x1};
  font-weight: bold;
`;

const LeaderboardRow = styled.div`
  display: grid;
  grid-template-columns: 3.2rem 1fr 4rem;
  align-items: center;
  flex-direction: row;
  gap: ${theme.spacing.x2};
`;

const TabbyImage = styled.img`
  width: 60px;
  height: 60px;
  object-fit: cover;
`;

const LeaderboardContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: ${theme.spacing.x2};
  width: 100%;
`;

const DetailSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.half};
  align-items: center;
  justify-content: center;
`;

const TextContainer = styled.div<{ isCenter?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  align-items: center;
  justify-content: ${(props) => (props.isCenter ? 'center' : 'start')};

  p {
    text-align: center;
  }
`;

const WorkspaceStreakLeaderboard = (props: any) => {
  const { data, t } = props;
  return (
    <CardContent>
      <DetailSection>
        <Emoji emoji=":fire:" size={30} set="twitter" />

        <BigText size={20} isCenter>
          {t('modals.yearReview.workspaceStreakLeaderboard.title')}
        </BigText>
        <p>{t('modals.yearReview.workspaceStreakLeaderboard.description')}</p>
      </DetailSection>
      <LeaderboardContainer>
        {data.map((item: any, index: number) => {
          const membership: Membership = item.membership;
          return (
            <LeaderboardRow key={index}>
              <KoalaAvatar membership={membership} tooltipType="text" />
              <h3>{membership.cached_fullname ?? membership.cached_email}</h3>
              <h3>{item.count}</h3>
            </LeaderboardRow>
          );
        })}
      </LeaderboardContainer>
    </CardContent>
  );
};

const WorkspaceCheckinLeaderboard = (props: any) => {
  const { data, t } = props;
  return (
    <CardContent>
      <DetailSection>
        <Emoji emoji=":dart:" size={30} set="twitter" />
        <BigText size={20}>{t('modals.yearReview.workspaceCheckinLeaderboard.title')}</BigText>
        <p>{t('modals.yearReview.workspaceCheckinLeaderboard.description')}</p>
      </DetailSection>
      <LeaderboardContainer>
        {data.map((item: any, index: number) => {
          const membership: Membership = item.membership;
          return (
            <LeaderboardRow key={index}>
              <KoalaAvatar membership={membership} tooltipType="text" />
              <h3>{membership.cached_fullname ?? membership.cached_email}</h3>
              <h3>{item.count}</h3>
            </LeaderboardRow>
          );
        })}
      </LeaderboardContainer>
    </CardContent>
  );
};

const WorkspaceSavings = (props: {
  numCheckins: number;
  numStandups: number;
  numRetrospectives: number;
  t: TFunction;
}) => {
  const { numCheckins, numStandups, numRetrospectives, t } = props;
  const traditionalCheckinTime = 20; // Synchronous check-in time (per person)
  const asyncCheckinTime = 5; // Asynchronous check-in time (per person)
  const traditionalStandupTime = 20; // Synchronous standup time (team-based)
  const asyncStandupTime = 5; // Asynchronous standup time (team-based)
  const traditionalRetroTime = 90; // Synchronous retrospective time (team-based)
  const asyncRetroTime = 30; // Asynchronous retrospective time (team-based)

  const checkinTimeSaved = traditionalCheckinTime - asyncCheckinTime;
  const standupTimeSaved = traditionalStandupTime - asyncStandupTime;
  const retrospectiveTimeSaved = traditionalRetroTime - asyncRetroTime;

  // Total time saved
  const totalTimeSaved =
    checkinTimeSaved * numCheckins + standupTimeSaved * numStandups + retrospectiveTimeSaved * numRetrospectives;

  // Convert total time saved to hours
  const totalHoursSaved = totalTimeSaved / 60;
  return (
    <CardContent>
      <Emoji emoji=":stopwatch:" size={30} set="twitter" />
      <TextContainer isCenter={true}>
        <BigText size={36} isCenter>
          <Trans
            i18nKey="modals.yearReview.workspaceSavings.mainText"
            components={{ highlight: <BigTextHighlight /> }}
            values={{ count: totalHoursSaved }}
          />
        </BigText>
        <p>{t('modals.yearReview.workspaceSavings.estimated')}</p>
      </TextContainer>
    </CardContent>
  );
};

const WorkspaceCheckinsFromDataSources = (props: any) => {
  const { data } = props;
  return (
    <CardContent>
      <Emoji emoji=":electric_plug:" size={30} set="twitter" />
      <TextContainer isCenter>
        <BigText isCenter>
          <Trans
            i18nKey="modals.yearReview.workspaceDataSources.mainText"
            components={{ highlight: <BigTextHighlight /> }}
            values={{ count: data }}
          />
        </BigText>
      </TextContainer>
    </CardContent>
  );
};

const WorkspaceFavoriteReactions = (props: any) => {
  const { reactions, count, t } = props;
  return (
    <CardContent>
      <Emoji emoji=":+1:" size={30} set="twitter" />
      <TextContainer>
        <BigText size={25} isCenter={true}>
          {t('modals.yearReview.workspaceFavoriteReactions.title')}
        </BigText>
        <EmojiRow>
          {reactions.map((reaction: any, index: number) => (
            <EmojiContainer key={index}>
              <Emoji emoji={reaction.emotion} size={40} set="twitter" />
              <p>{reaction.reaction_count}</p>
            </EmojiContainer>
          ))}
        </EmojiRow>
        <p>{t('modals.yearReview.workspaceFavoriteReactions.total', { count })}</p>
      </TextContainer>
    </CardContent>
  );
};

const WorkspaceMostActiveCheckinDay = (props: any) => {
  const { data } = props;
  const day = new Date(data.checkin_date);
  const count = data.checkin_count;

  return (
    <CardContent>
      <Emoji emoji=":spiral_calendar_pad:" size={30} set="twitter" />
      <TextContainer isCenter={true}>
        <BigText isCenter={true}>
          <Trans
            i18nKey="modals.yearReview.workspaceActiveDay"
            values={{ date: format(day, 'dd MMM'), count }}
            components={{ highlight: <BigTextHighlight color={theme.colors.V50} /> }}
          />
        </BigText>
      </TextContainer>
    </CardContent>
  );
};

const WorkspaceStandupCount = ({ count, t }: { count: number; t: TFunction }) => {
  return (
    <CardContent>
      <Emoji emoji=":writing_hand:" size={30} set="twitter" />
      <TextContainer isCenter={true}>
        <BigText size={25} isCenter>
          {t('modals.yearReview.workspaceStandup.firstLine')}
        </BigText>
        <BigNumber textColor={theme.colors.V50}>{count}</BigNumber>
        <BigText size={25} isCenter>
          {t('modals.yearReview.workspaceStandup.secondLine')}
        </BigText>
      </TextContainer>
    </CardContent>
  );
};

const WorkspaceCheckinCount = ({ count, t }: { count: number; t: TFunction }) => {
  return (
    <CardContent>
      <Emoji emoji=":memo:" size={30} set="twitter" />
      <TextContainer isCenter={true}>
        <BigText size={25} isCenter>
          {t('modals.yearReview.workspaceCheckinCount.firstLine')}
        </BigText>
        <BigNumber textColor={theme.colors.V50}>{count}</BigNumber>
        <BigText size={25} isCenter>
          {t('modals.yearReview.workspaceCheckinCount.secondLine')}
        </BigText>
      </TextContainer>
    </CardContent>
  );
};

const WorkspaceRetrospectiveCount = ({ count, t }: { count: number; t: TFunction }) => {
  return (
    <CardContent>
      <Emoji emoji=":thought_balloon:" size={30} set="twitter" />
      <TextContainer isCenter={true}>
        <BigText size={25} isCenter>
          {t('modals.yearReview.workspaceRetro.firstLine')}
        </BigText>
        <BigNumber textColor={theme.colors.T50}>{count}</BigNumber>
        <BigText size={25} isCenter>
          {t('modals.yearReview.workspaceRetro.secondLine')}
        </BigText>
      </TextContainer>
    </CardContent>
  );
};

const WorkspaceInitiativeCount = ({
  count,
  initiativeLabel,
  t,
}: {
  count: number;
  initiativeLabel: string;
  t: TFunction;
}) => {
  return (
    <CardContent>
      <Emoji emoji=":white_check_mark:" size={30} set="twitter" />
      <TextContainer isCenter={true}>
        <BigText size={25} isCenter>
          {t('modals.yearReview.workspaceInitiatives.firstLine')}
        </BigText>
        <BigNumber textColor={theme.colors.G50}>{count}</BigNumber>
        <BigText size={25} isCenter>
          {t('modals.yearReview.workspaceInitiatives.secondLine', { label: initiativeLabel })}
        </BigText>
      </TextContainer>
    </CardContent>
  );
};

const ThankYou = ({ t }: { t: TFunction }) => {
  return (
    <CardContent>
      <Emoji emoji=":tada:" size={30} set="twitter" />
      <TextContainer>
        <BigText isCenter={true}>{t('modals.yearReview.thankyou.title')}</BigText>
        <BigText size={15} isCenter={true}>
          {t('modals.yearReview.thankyou.description')}
        </BigText>
        <TabbyImage src={TabbySparkle} alt="Tabby Sparkle" />
      </TextContainer>
    </CardContent>
  );
};

const WorkspaceIntro = ({ t }: { t: TFunction }) => {
  return (
    <CardContent>
      <Emoji emoji=":tada:" size={30} set="twitter" />
      <BigText size={45} isCenter={true}>
        {t('modals.yearReview.workspaceIntro')}
      </BigText>
    </CardContent>
  );
};

const PersonalIntro = ({ t }: { t: TFunction }) => {
  return (
    <CardContent>
      <Emoji emoji=":tada:" size={30} set="twitter" />
      <BigText size={45} isCenter={true}>
        {t('modals.yearReview.personalIntro')}
      </BigText>
    </CardContent>
  );
};

const PersonalCheckinCount = ({ count, t }: { count: number; t: TFunction }) => {
  return (
    <CardContent>
      <Emoji emoji=":memo:" size={30} set="twitter" />
      <TextContainer isCenter={true}>
        <BigText size={25} isCenter>
          {t('modals.yearReview.personalCheckinCount.firstLine')}
        </BigText>
        <BigNumber textColor={theme.colors.V50}>{count}</BigNumber>
        <BigText size={25} isCenter>
          {t('modals.yearReview.personalCheckinCount.secondLine')}
        </BigText>
      </TextContainer>
    </CardContent>
  );
};

const PersonalInitiativeCount = ({ count, t, label }: { count: number; t: TFunction; label: string }) => {
  return (
    <CardContent>
      <Emoji emoji=":white_check_mark:" size={30} set="twitter" />
      <TextContainer isCenter={true}>
        <BigText size={25} isCenter>
          {t('modals.yearReview.personalInitiativeCount.firstLine')}
        </BigText>
        <BigNumber textColor={theme.colors.G50}>{count}</BigNumber>
        <BigText size={25} isCenter>
          {t('modals.yearReview.personalInitiativeCount.secondLine', { label })}
        </BigText>
      </TextContainer>
    </CardContent>
  );
};

const PersonalFavoriteReactions = (props: any) => {
  const { reactions, t } = props;
  return (
    <CardContent>
      <Emoji emoji=":+1:" size={30} set="twitter" />
      <TextContainer>
        <BigText size={25} isCenter={true}>
          {t('modals.yearReview.personalFavoriteReactions.description')}
        </BigText>
        <EmojiRow>
          {reactions.map((reaction: any, index: number) => (
            <EmojiContainer size={55} key={index}>
              <Emoji emoji={reaction.emotion} size={30} set="twitter" />
            </EmojiContainer>
          ))}
        </EmojiRow>
        <p>{t('modals.yearReview.personalFavoriteReactions.info')}</p>
      </TextContainer>
    </CardContent>
  );
};

const PersonalStandupCount = ({ count, t }: { count: number; t: TFunction }) => {
  return (
    <CardContent>
      <Emoji emoji=":writing_hand:" size={30} set="twitter" />
      <TextContainer isCenter={true}>
        <BigText size={25} isCenter>
          {t('modals.yearReview.personalStandupCount.firstLine')}
        </BigText>
        <BigNumber textColor={theme.colors.T50}>{count}</BigNumber>
        <BigText size={25} isCenter>
          {t('modals.yearReview.personalStandupCount.secondLine')}
        </BigText>
      </TextContainer>
    </CardContent>
  );
};

const PersonalSavings = (props: { numCheckins: number; numStandups: number; t: TFunction }) => {
  const { numCheckins, numStandups, t } = props;
  const traditionalCheckinTime = 20; // Synchronous check-in time (per person)
  const asyncCheckinTime = 5; // Asynchronous check-in time (per person)
  const traditionalStandupTime = 20; // Synchronous standup time (team-based)
  const asyncStandupTime = 5; // Asynchronous standup time (team-based)

  const checkinTimeSaved = traditionalCheckinTime - asyncCheckinTime;
  const standupTimeSaved = traditionalStandupTime - asyncStandupTime;

  // Total time saved
  const totalTimeSaved = checkinTimeSaved * numCheckins + standupTimeSaved * numStandups;

  // Convert total time saved to hours
  const totalHoursSaved = totalTimeSaved / 60;
  return (
    <CardContent>
      <Emoji emoji=":stopwatch:" size={30} set="twitter" />
      <TextContainer isCenter={true}>
        <BigText size={36} isCenter>
          <Trans
            i18nKey="modals.yearReview.personalSavings.mainText"
            components={{ highlight: <BigTextHighlight /> }}
            values={{ count: totalHoursSaved }}
          />
        </BigText>
        <p>{t('modals.yearReview.personalSavings.estimated')}</p>
      </TextContainer>
    </CardContent>
  );
};

const PersonalLongestStreak = ({ streak, t }: { streak: number; t: TFunction }) => {
  return (
    <CardContent>
      <Emoji emoji=":fire:" size={30} set="twitter" />
      <TextContainer isCenter={true}>
        <BigText size={25} isCenter>
          {t('modals.yearReview.personalLongestStreak.firstLine')}
        </BigText>
        <BigNumber textColor={theme.colors.R40}>{streak}</BigNumber>
        <BigText size={25} isCenter>
          {t('modals.yearReview.personalLongestStreak.secondLine')}
        </BigText>
      </TextContainer>
    </CardContent>
  );
};

export default {
  WorkspaceStreakLeaderboard,
  WorkspaceCheckinLeaderboard,
  WorkspaceSavings,
  WorkspaceCheckinsFromDataSources,
  WorkspaceFavoriteReactions,
  WorkspaceMostActiveCheckinDay,
  WorkspaceStandupCount,
  WorkspaceCheckinCount,
  WorkspaceRetrospectiveCount,
  WorkspaceInitiativeCount,
  ThankYou,
  WorkspaceIntro,
  PersonalIntro,
  PersonalCheckinCount,
  PersonalInitiativeCount,
  PersonalFavoriteReactions,
  PersonalStandupCount,
  PersonalSavings,
  PersonalLongestStreak,
};
