import MentionBox from 'components/MentionBox';
import KoalaAvatar from 'koala/components/Avatar';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import { InitiativeComment } from 'types';

import * as remoteApi from 'api/remote';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { CONTEXT_STORAGE_KEY } from 'config/constants';

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  grid-template-areas:
    'avatar content'
    '. actions';
  gap: ${theme.spacing.x1};
  margin-top: ${theme.spacing.x1};
  padding: 0px ${theme.spacing.x1} ${theme.spacing.x1} ${theme.spacing.x6};
`;

const ActionContainer = styled.div`
  grid-area: actions;

  display: flex;
  gap: ${theme.spacing.x1};
`;

const AvatarSection = styled.div`
  grid-area: avatar;
`;

const ContentSection = styled.div`
  grid-area: content;
`;

interface Props {
  comment: InitiativeComment;
  setShowNewReply: (value: boolean) => void;
}

function NewInitiativeReply(props: Props) {
  const { comment, setShowNewReply } = props;
  const [body, setBody] = useState('');
  const currentUser = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const storageKey = `${CONTEXT_STORAGE_KEY}_initiative_reply_${comment.nano_slug}`;

  // Review comment mutations
  const [createInitiativeReplyMutation, { isLoading }] = useMutation(remoteApi.createInitiativeReply, {
    onSuccess: () => {
      sessionStorage.removeItem(storageKey);
      queryCache.invalidateQueries([queryKeys.initiativeReplies, comment.id]);
      setShowNewReply(false);
    },
  });

  useEffect(() => {
    debounceStoreText.current(body);
  }, [body]);

  useEffect(() => {
    const savedContext = sessionStorage.getItem(storageKey) || '';
    setBody(savedContext);
  }, [storageKey]);

  const storeText = (newText: string) => {
    if (newText) {
      sessionStorage.setItem(storageKey, newText);
    }
  };

  const debounceStoreText = useRef(
    _.debounce((newText: string) => storeText(newText), 1000, {
      maxWait: 5000,
    }),
  );

  const handleSubmit = () => {
    const params = {
      body,
    };
    createInitiativeReplyMutation({
      initiativeCommentId: comment.id,
      initiative_reply: params,
    });
  };

  const handleCancel = () => {
    setShowNewReply(false);
  };

  return (
    <Container>
      <AvatarSection>
        <KoalaAvatar membership={{ ...currentMembership, user: currentUser }} tooltipType="none" />
      </AvatarSection>
      <ContentSection>
        <MentionBox
          setValue={setBody}
          value={body}
          placeholder={t('modals.initiative.replyPlaceholder') ?? 'Enter reply'}
          comment={true}
          cmdEnterCallback={handleSubmit}
        />
      </ContentSection>

      <ActionContainer>
        <KoalaButton onClick={handleSubmit} loading={isLoading} disabled={isLoading}>
          {t('shared.reply')}
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
      </ActionContainer>
    </Container>
  );
}

export default React.memo(NewInitiativeReply);
