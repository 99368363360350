import React from 'react';

const DashboardIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7929 2.79289C10.9804 2.60536 11.2348 2.5 11.5 2.5H17.5C18.0523 2.5 18.5 2.94772 18.5 3.5V7C18.5 7.55228 18.0523 8 17.5 8H11.5C10.9477 8 10.5 7.55228 10.5 7V3.5C10.5 3.23478 10.6054 2.98043 10.7929 2.79289ZM12.5 4.5V6H16.5V4.5H12.5Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 10C10.5 9.44772 10.9477 9 11.5 9H17.5C18.0523 9 18.5 9.44772 18.5 10V16.5C18.5 17.0523 18.0523 17.5 17.5 17.5H11.5C10.9477 17.5 10.5 17.0523 10.5 16.5V10ZM12.5 11V15.5H16.5V11H12.5Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.20711 17.2071C9.01957 17.3946 8.76522 17.5 8.5 17.5L2.5 17.5C1.94772 17.5 1.5 17.0523 1.5 16.5L1.5 13C1.5 12.4477 1.94772 12 2.5 12L8.5 12C9.05229 12 9.5 12.4477 9.5 13L9.5 16.5C9.5 16.7652 9.39464 17.0196 9.20711 17.2071ZM7.5 15.5L7.5 14L3.5 14L3.5 15.5L7.5 15.5Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 10C9.5 10.5523 9.05228 11 8.5 11L2.5 11C1.94772 11 1.5 10.5523 1.5 10L1.5 3.5C1.5 2.94772 1.94772 2.5 2.5 2.5L8.5 2.5C9.05229 2.5 9.5 2.94772 9.5 3.5L9.5 10ZM7.5 9L7.5 4.5L3.5 4.5L3.5 9L7.5 9Z"
      fill="#737373"
    />
  </svg>
);

export default DashboardIcon;
