import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import KoalaAvatar from 'koala/components/Avatar';
import KoalaIcon from 'koala/components/Icons';

import CheckinCommentCreated from 'components/Newsblocks/CheckinCommentCreated';
import ReviewReplyCreated from 'components/Newsblocks/ReviewReplyCreated';
import ReviewCommentCreated from 'components/Newsblocks/ReviewCommentCreated';
import CheckinCreated from 'components/Newsblocks/CheckinCreated';
import InitiativeCreated from 'components/Newsblocks/InitiativeCreated';
import InitiativeClosed from 'components/Newsblocks/InitiativeClosed';
import InitiativeMovedNow from 'components/Newsblocks/InitiativeMovedNow';
import InitiativeMovedNext from 'components/Newsblocks/InitiativeMovedNext';
import OutcomeCreated from 'components/Newsblocks/OutcomeCreated';
import PlanCreated from 'components/Newsblocks/PlanCreated';
import PlanPublished from 'components/Newsblocks/PlanPublished';
import WorkspaceUpdated from 'components/Newsblocks/WorkspaceUpdated';
import { TabilityEvent } from 'types';
import * as dateUtils from 'utils/dateUtils';
import CheckinBlock from './CheckinBlock';

const NewsblockLayout = styled.div`
  display: grid;
  grid-template-columns: auto auto auto 1fr;
  grid-template-rows: auto;
  gap: ${theme.spacing.x1};
  cursor: pointer;
  padding: ${theme.spacing.x1};
  border-radius: 4px;
  color: ${theme.colors.N70};

  &:hover {
    background: ${theme.colors.N10};
    cursor: pointer;
  }
  &:focus,
  &:active {
    background: ${theme.colors.B5};
  }
  position: relative;

  &.unlinked {
    :hover {
      cursor: initial;
    }
  }
`;

const Line = styled.div`
  position: absolute;
  width: 2px;
  background: ${theme.colors.N10};
  z-index: 1;
  top: ${theme.spacing.x1};
  bottom: -${theme.spacing.x1};
  left: 2.1rem;
`;

const IconContainer = styled.div`
  background: #fff;
  border-radius: 50%;
  width: 2.8rem;
  min-height: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: relative;
  border: 2px solid ${theme.colors.N20};
`;

export const Gutter = styled.div``;

export const Date = styled.div`
  min-height: 2.8rem;
  width: 10rem;
  position: relative;
  top: 4px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  position: relative;
  min-height: 2.8rem;
  top: 4px;
`;

const AvatarContainer = styled.div`
  min-height: 2.8rem;
  display: flex;
`;

export const ContentAction = styled.div`
  line-height: 1.6;
  span {
    display: inline-block;
    vertical-align: bottom;
  }
  .badge {
    margin: 0 ${theme.spacing.half};
    position: relative;
    top: 0px;
  }

  .closed {
    text-decoration: line-through;
  }
`;

export const ContentComment = styled.div`
  background: ${theme.colors.N0};
  border: 1px solid ${theme.colors.N10};
  .ql-container {
    color: ${theme.colors.N70};
  }
  padding: 0 ${theme.spacing.x1};
  margin-top: ${theme.spacing.x1};
  border-radius: 4px;
  flex: 1;
`;

interface Props {
  event: TabilityEvent;
}

function Block(props: Props) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { event } = props;
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();

  const { membership, source } = event;

  const getSourceLink = (event: any) => {
    // Test if the current event membership is the same as previous
    switch (event.event_type) {
      case 'checkin_comment.created':
        return `#checkin:${source.checkin?.nano_slug}:show`;
      case 'review_comment.created':
        return `/${workspaceSlug}/plans/${event.plan.nano_slug}/write/#editor:${source.block_id}:comment`;
      case 'review_reply.created':
        return `/${workspaceSlug}/plans/${event.plan.nano_slug}/write/#editor:${source.review_comment?.block_id}:comment`;
      case 'checkin.created':
        return `#checkin:${source.nano_slug}:show`;
      case 'initiative.created':
        return `#initiative:${source.nano_slug}:show`;
      case 'initiative.closed':
        return `#initiative:${source.nano_slug}:show`;
      case 'initiative.moved_now':
        return `#initiative:${source.nano_slug}:show`;
      case 'initiative.moved_next':
        return `#initiative:${source.nano_slug}:show`;
      case 'outcome.created':
        return `#outcome:${source.nano_slug}:show`;
      case 'plan.created':
        return `/${workspaceSlug}/plans/${source.nano_slug}/write`;
      case 'plan.published':
        return `/${workspaceSlug}/plans/${source.nano_slug}/outcomes`;
      default:
        return '';
    }
  };

  const handleNav = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const sourceLink = getSourceLink(event);

    history.push(sourceLink);
  };

  if (!membership) {
    return null;
  }

  const renderEventContent = (event: TabilityEvent) => {
    // Test if the current event membership is the same as previous
    switch (event.event_type) {
      case 'checkin_comment.created':
        return <CheckinCommentCreated key={event.id} event={event} />;
      case 'review_comment.created':
        return <ReviewCommentCreated key={event.id} event={event} />;
      case 'review_reply.created':
        return <ReviewReplyCreated key={event.id} event={event} />;
      case 'checkin.created':
        return <CheckinCreated key={event.id} event={event} />;
      case 'initiative.created':
        return <InitiativeCreated key={event.id} event={event} />;
      case 'initiative.closed':
        return <InitiativeClosed key={event.id} event={event} />;
      case 'initiative.moved_now':
        return <InitiativeMovedNow key={event.id} event={event} />;
      case 'initiative.moved_next':
        return <InitiativeMovedNext key={event.id} event={event} />;
      case 'outcome.created':
        return <OutcomeCreated key={event.id} event={event} />;
      case 'plan.created':
        return <PlanCreated key={event.id} event={event} />;
      case 'plan.published':
        return <PlanPublished key={event.id} event={event} />;
      case 'workspace.updated':
        return <WorkspaceUpdated key={event.id} event={event} />;
      default:
        return null;
    }
  };

  const iconName = (event: TabilityEvent) => {
    // Test if the current event membership is the same as previous
    switch (event.event_type) {
      case 'checkin_comment.created':
        return 'comment';
      case 'review_comment.created':
        return 'comment';
      case 'review_reply.created':
        return 'comment';
      case 'outcome.confidence_changed':
        return 'metric';
      case 'checkin.created':
        return 'metric';
      case 'initiative.created':
        return 'task';
      case 'initiative.closed':
        return 'labelTask';
      case 'initiative.moved_now':
        return 'task';
      case 'initiative.moved_next':
        return 'task';
      case 'outcome.created':
        return 'metric';
      case 'plan.created':
        return 'plan';
      case 'plan.published':
        return 'plan';
      case 'workspace.updated':
        return 'edit';
      default:
        return 'metric';
    }
  };

  if (event.event_type === 'checkin.created') {
    return <CheckinBlock checkin={event.source} showProgressPrct className="primary" />;
  }

  const handleAvatarClicked = () => {
    history.push(`/${workspaceSlug}/people/${membership.id}`);
  };

  if (event.event_type === 'workspace.updated') {
    return (
      <NewsblockLayout className="unlinked">
        <Line />
        <Gutter>
          <IconContainer>
            <KoalaIcon iconName={iconName(event)} iconSize="small" />
          </IconContainer>
        </Gutter>
        <Date>{dateUtils.formatDistanceToNowLocale(event.updated_at, i18n, true)}</Date>
        <AvatarContainer>
          <KoalaAvatar membership={membership} size={2.8} handleClick={handleAvatarClicked} tooltipType="card" />
        </AvatarContainer>
        <Content>{renderEventContent(event)}</Content>
      </NewsblockLayout>
    );
  }

  return (
    <NewsblockLayout onClick={handleNav}>
      <Line />
      <Gutter>
        <IconContainer>
          <KoalaIcon iconName={iconName(event)} iconSize="small" />
        </IconContainer>
      </Gutter>
      <Date>{t('shared.time.ago', { time: dateUtils.formatDistanceToNowLocale(event.updated_at, i18n) })}</Date>
      <AvatarContainer>
        <KoalaAvatar membership={membership} size={2.8} handleClick={handleAvatarClicked} tooltipType="card" />
      </AvatarContainer>
      <Content>{renderEventContent(event)}</Content>
    </NewsblockLayout>
  );
}

export default React.memo(Block);
