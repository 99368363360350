const staleTime = 5 * 60 * 1000; // default stale time is 5 mins;

export default {
  staleTime,
  stats: 'stats',
  currentUserMemberships: 'currentUserMemberships',
  workspaces: 'workspaces',
  plans: 'plans',
  objectives: 'objectives',
  outcomes: 'outcomes',
  outcomeLinks: 'outcomeLinks',
  checkins: 'checkins',
  initiatives: 'initiatives',
  initiativeLinks: 'initiativeLinks',
  initiativeContributors: 'initiativeContributors',
  integrations: 'integrations',
  memberships: 'memberships',
  currentWorkspace: 'currentWorkspace',
  currentWorkspaceSettings: 'currentWorkspaceSettings',
  currentPlan: 'currentPlan',
  currentObjective: 'currentObjective',
  currentOutcome: 'currentOutcome',
  currentCheckin: 'currentCheckin',
  currentInitiative: 'currentInitiative',
  currentMembership: 'currentMembership',
  currentTeam: 'currentTeam',
  events: 'events',
  reviewComments: 'reviewComments',
  reviewReplies: 'reviewReplies',
  checkinComments: 'checkinComments',
  initiativeComments: 'initiativeComments',
  initiativeReplies: 'initiativeReplies',
  planSubscriptions: 'planSubscriptions',
  outcomeContributors: 'outcomeContributors',
  notifications: 'notifications',
  reviewers: 'reviewers',
  recentVisits: 'recentVisits',
  publicTemplates: 'publicTemplates',
  planPermissions: 'planPermissions',
  tags: 'tags',
  segments: 'segments',
  teams: 'teams',
  teamMembers: 'teamMembers',
  streak: 'streak',
  review: 'review',
  outcomeRelationships: 'outcomeRelationships',
  relationships: 'relationships',
  currentPing: 'currentPing',
  teamRelationships: 'teamRelationships',
  standups: 'standups',
  standupComments: 'standupComments',
  followers: 'followers',
  following: 'following',
  checkinViewers: 'checkinViewers',
  reporting: 'reporting',
  retrospectives: 'retrospectives',
  currentRetrospective: 'currentRetrospective',
  retrospectiveSections: 'retrospectiveSections',
  retrospectiveComments: 'retrospectiveComments',
  retrospectiveReplies: 'retrospectiveReplies',
  dashboards: 'dashboards',
  currentDashboard: 'currentDashboard',
  dashboardWidgets: 'dashboardWidgets',
  planRecommendations: 'planRecommendations',
  workspaceYearReview: 'workspaceYearReview',
  personalYearReview: 'personalYearReview',
};
