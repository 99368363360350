import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import MentionBox from 'components/MentionBox';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaAvatar from 'koala/components/Avatar';
import { useTranslation } from 'react-i18next';

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'gutter meta'
    'gutter content'
    'gutter footer';
  column-gap: ${theme.spacing.x3};

  margin-bottom: ${theme.spacing.x2};

  &.primary {
    background: #f3f3f3;
    border-radius: 4px;
    padding: ${theme.spacing.x3};

    border-bottom: 0;
  }
`;

const Gutter = styled.div`
  grid-area: gutter;
`;

const Meta = styled.div`
  grid-area: meta;
  display: flex;
  align-items: center;

  font-weight: 600;
`;

const Content = styled.div`
  grid-area: content;
  margin: ${theme.spacing.x1} 0;

  textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    font-size: 1.6rem;
    line-height: 1.4;
    padding: ${theme.spacing.x1};
    min-height: 10rem;
    border-radius: 4px;
    border: 1px solid #949494;
    &:placeholder {
      color: #949494;
      font-size: 1.4rem;
    }

    width: 70%;
  }
`;

const Footer = styled.div`
  grid-area: footer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${theme.colors.subtleText};

  > div {
    display: flex;
    align-items: center;

    button {
      margin-right: ${theme.spacing.x1};
    }
  }
`;

interface Props {
  reviewComment: any;
  setIsEditing: any;
}

function CommentBlock(props: Props) {
  const queryCache = useQueryCache();
  const { t } = useTranslation();

  const { reviewComment, setIsEditing } = props;
  const { membership } = reviewComment;

  // Review comment mutations
  const [updateReviewCommentMutation]: [any, any] = useMutation(remoteApi.updateReviewComment, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.reviewComments);
      setIsEditing(false);
    },
  });

  // Form values
  const [body, setBody] = useState(reviewComment.body);

  const handleSubmit = () => {
    const params = {
      body,
    };
    updateReviewCommentMutation({
      reviewCommentId: reviewComment.id,
      review_comment: params,
    });
  };

  return (
    <Grid>
      <Gutter>
        <KoalaAvatar membership={membership} tooltipType="none" />
      </Gutter>
      <Meta>{membership.cached_fullname ?? membership.cached_email}</Meta>
      <Content>
        <MentionBox
          setValue={setBody}
          value={body}
          placeholder={t('comments.addComment') ?? 'Add your comment'}
          comment={true}
          cmdEnterCallback={handleSubmit}
        />
      </Content>
      <Footer>
        <div>
          <KoalaButton onClick={handleSubmit}>{t('shared.update')}</KoalaButton>
          <KoalaTextButton
            onClick={() => {
              setIsEditing(false);
            }}
          >
            {t('shared.cancel')}
          </KoalaTextButton>
        </div>
      </Footer>
    </Grid>
  );
}

export default React.memo(CommentBlock);
