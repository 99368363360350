import queryKeys from 'config/queryKeys';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import styled from 'styled-components';
import theme from 'theme';
import { Plan, Retrospective } from 'types';
import * as remoteApi from 'api/remote';
import * as routes from 'routes';
import KoalaLoader from 'koala/components/Loader';
import KoalaButton from 'koala/components/Button';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import { useHistory, useParams } from 'react-router-dom';
import KoalaTextBadge from 'koala/components/TextBadge';
import DropdownMenu from 'components/DropdownMenu';
import KoalaIconButton from 'koala/components/IconButton';
import { useHotkeys } from 'react-hotkeys-hook';
import { formatLocale } from 'utils/dateUtils';
import KoalaIcon from 'koala/components/Icons';
import KoalaAvatar from 'koala/components/Avatar';

const Container = styled.div`
  margin: ${theme.spacing.x2};
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;
const Header = styled.div`
  display: flex;
  justify-content: end;
`;
const List = styled.div`
  border: 1px solid ${theme.colors.N10};
  border-radius: 8px;

  .item:first-of-type {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .item:last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border: none;
  }
`;
const EmptyState = styled.div`
  width: 100%;
  align-items: center;
  padding: ${theme.spacing.x2};
`;

const ColumnTitle = styled.div`
  display: grid;
  grid-template-columns: 9rem 1fr 45rem;
  gap: ${theme.spacing.x1};
  padding: 0px ${theme.spacing.x2};
  grid-template-rows: auto;
  color: ${theme.colors.N60};

  @media ${theme.devices.laptop} {
    grid-template-columns: 9rem 1fr 40rem;
  }

  @media ${theme.devices.tablet} {
    grid-template-columns: 9rem 1fr;
    .createdLabel {
      display: none;
    }
  }
`;

const RetroRow = styled.div`
  padding: 1.2rem ${theme.spacing.x2};
  border-bottom: 1px solid ${theme.colors.N10};
  display: grid;
  position: relative;
  height: 4.9rem;
  grid-template-columns: 9rem 1fr auto;
  grid-template-rows: auto;
  gap: ${theme.spacing.x1};
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${theme.colors.N3};
  }
`;

const RowMeta = styled.div`
  display: grid;
  grid-template-columns: 1fr 6rem 8rem auto;
  justify-content: end;
  gap: ${theme.spacing.x3};
  width: 45rem;

  @media ${theme.devices.laptop} {
    width: 40rem;
    grid-template-columns: 1fr 6rem 8rem auto;
    gap: ${theme.spacing.x1};
  }

  @media ${theme.devices.tablet} {
    width: 12rem;
    grid-template-columns: 8rem auto;

    .userSection,
    .commentSection {
      display: none;
    }
  }
`;
const DateContainer = styled.div`
  display: flex;
`;
const UserSection = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.half};
`;
const CommentSection = styled.div`
  color: ${theme.colors.N60};
  font-weight: 600;
  display: flex;
  gap: ${theme.spacing.half};
  align-items: center;
  justify-content: center;
  padding: 0px ${theme.spacing.half};
`;
const StatusSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActionBlock = styled.div`
  height: 2rem;
  display: flex;
  align-items: center;
`;
interface Props {
  plan: Plan;
}

function PlanRetrospectives(props: Props) {
  const { plan } = props;
  const { t, i18n } = useTranslation();
  const translationKey = 'workspacePlan.retrospectives';
  const dispatch = useDispatch();
  const history = useHistory();
  const queryCache = useQueryCache();
  const { workspaceSlug } = useParams<{
    workspaceSlug: string;
  }>();

  useHotkeys('C', () => handleCreate(), { scopes: ['*'] });
  // Get the retrospectives
  const staleTime = 0;
  const planRetrospectivesQueryKey = [queryKeys.retrospectives, plan.id];
  const { data: retrospectivesResponse, isLoading: retrospectivesAreLoading } = useQuery(
    planRetrospectivesQueryKey,
    remoteApi.fetchRetrospectives,
    {
      staleTime,
    },
  );
  const retrospectives: Retrospective[] = retrospectivesResponse ? retrospectivesResponse.data : [];

  const [deleteRetrospectiveMutation, { isLoading: isDeleting }] = useMutation(remoteApi.deleteRetrospective, {
    onSuccess: () => {
      queryCache.invalidateQueries(planRetrospectivesQueryKey);
    },
  });

  const [updateRetrospectiveMutation] = useMutation(remoteApi.updateRetrospective, {
    onSuccess: () => {
      queryCache.invalidateQueries(planRetrospectivesQueryKey);
    },
  });

  const handleCreate = () => {
    dispatch(setGlobalModalContent(`plan:${plan.nano_slug}:create.retrospective`));
  };
  const handleShowRetro = (retroSlug: string) => {
    const path = routes.WORKSPACE_RETROSPECTIVE_ROUTE.replace(':workspaceSlug', workspaceSlug)
      .replace(':planId', plan.nano_slug)
      .replace(':retrospectiveId', retroSlug);
    history.push(path);
  };
  const handleDelete = (retrospectiveId: string) => {
    if (window.confirm('Delete this retrospective?')) {
      deleteRetrospectiveMutation(retrospectiveId);
    }
  };
  const handleEdit = (retrospectiveId: string) => {
    const path = routes.WORKSPACE_RETROSPECTIVE_EDIT_ROUTE.replace(':workspaceSlug', workspaceSlug)
      .replace(':planId', plan.nano_slug)
      .replace(':retrospectiveId', retrospectiveId);
    history.push(path);
  };
  const handleUnpublish = (retrospectiveId: string) => {
    updateRetrospectiveMutation({ retrospectiveId, retrospective: { published: false, published_at: null } });
  };
  const handlePublish = (retrospectiveId: string) => {
    updateRetrospectiveMutation({ retrospectiveId, retrospective: { published: true, published_at: new Date() } });
  };

  const handleMenuSelection = (value: any) => {
    const action = value.props['data-action'];
    const retrospectiveId = value.props['data-retrospective-id'];
    switch (action) {
      case 'delete':
        handleDelete(retrospectiveId);
        break;
      case 'edit':
        handleEdit(retrospectiveId);
        break;
      case 'unpublish':
        handleUnpublish(retrospectiveId);
        break;
      case 'publish':
        handlePublish(retrospectiveId);
        break;
    }
  };
  const getRetrospectiveOptions = (retrospective: Retrospective) => {
    const options = [
      <span data-action="edit" data-retrospective-id={retrospective.id}>
        {t(`shared.edit`)}
      </span>,
      <span data-action="delete" data-retrospective-id={retrospective.id}>
        {t(`shared.delete`)}
      </span>,
    ];
    if (retrospective.published) {
      options.push(
        <span data-action="unpublish" data-retrospective-id={retrospective.id}>
          {t(`shared.unpublish`)}
        </span>,
      );
    } else {
      options.push(
        <span data-action="publish" data-retrospective-id={retrospective.id}>
          {t(`shared.publish`)}
        </span>,
      );
    }
    return options;
  };
  const isLoading = isDeleting || retrospectivesAreLoading;
  return (
    <Container>
      <Header>
        <KoalaButton onClick={handleCreate}>{t(`${translationKey}.create`)}</KoalaButton>
      </Header>
      <ColumnTitle>
        <small>{t(`${translationKey}.date`)}</small>
        <small>{t(`${translationKey}.retroTitle`)}</small>
        <small className="createdLabel">{t(`${translationKey}.createdBy`)}</small>
      </ColumnTitle>
      <List>
        {isLoading && <KoalaLoader />}
        {!isLoading && retrospectives.length === 0 && <EmptyState>{t(`${translationKey}.empty`)}</EmptyState>}
        {!isLoading &&
          retrospectives.map((retro) => {
            const { membership } = retro;

            return (
              <RetroRow onClick={() => handleShowRetro(retro.nano_slug)} className="item">
                <DateContainer>
                  <KoalaTextBadge
                    isLowercase={true}
                    variant="neutral-light"
                    edge="circle"
                    size="small"
                    className="date-label"
                  >
                    {formatLocale(retro.created_at, 'dd MMM yyyy', i18n)}
                  </KoalaTextBadge>
                </DateContainer>

                <div>{retro.title}</div>
                <RowMeta>
                  <UserSection className="userSection">
                    {membership && (
                      <>
                        <KoalaAvatar membership={membership} size="small" tooltipType="card" />
                        {membership.cached_fullname ?? membership.cached_email}
                      </>
                    )}
                  </UserSection>
                  <CommentSection className="commentSection">
                    <KoalaIcon iconName="comment" />
                    {retro.retrospective_comments_count}
                  </CommentSection>
                  <StatusSection>
                    {retro.published && (
                      <KoalaTextBadge variant="blue-light" isLowercase>
                        {t(`shared.status.published`)}
                      </KoalaTextBadge>
                    )}
                    {!retro.published && (
                      <KoalaTextBadge variant="yellow-light" isLowercase>
                        {t(`shared.status.draft`)}
                      </KoalaTextBadge>
                    )}
                  </StatusSection>

                  <ActionBlock onClick={(e) => e.stopPropagation()} className="actions">
                    <DropdownMenu
                      trigger={<KoalaIconButton iconName="ellipsis" />}
                      onSelection={handleMenuSelection}
                      items={getRetrospectiveOptions(retro)}
                    />
                  </ActionBlock>
                </RowMeta>
              </RetroRow>
            );
          })}
      </List>
    </Container>
  );
}
export default PlanRetrospectives;
