import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useQuery, useMutation } from 'react-query';
import queryKeys from 'config/queryKeys';
import theme from 'theme';
import { Helmet } from 'react-helmet';
import * as workspaceUtils from 'utils/workspaceUtils';

// API
import * as remoteApi from 'api/remote';

// Components
import { ChromeContent, ChromeSideNav } from 'components/Chrome';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import ContentTitle from 'components/ContentTitle';
import Loader from 'components/Loader';
import WorkspaceSettingsNav from 'components/WorkspaceSettingsNav';
import KoalaButton from 'koala/components/Button';
import { Trans, useTranslation } from 'react-i18next';
import KoalaTextButton from 'koala/components/TextButton';

const Block = styled.div`
  margin-bottom: ${theme.spacing.x4};

  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
`;

const Usage = styled.div`
  white-space: nowrap;
  margin-right: ${theme.spacing.x2};
  min-width: 15rem;
`;

const Quota = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
`;

const ProgressBarContainer = styled.div`
  height: 1.2rem;
  flex: 1;
  background: ${theme.colors.blockBorder};
  border-radius: 4px;
  overflow: hidden;
`;

const ProgressBar = styled.div<{ prct: number }>`
  height: 100%;
  background-color: ${theme.colors.blue};
  border-radius: 4px;
  width: ${(props) => props.prct}%;
`;

declare global {
  interface Window {
    Stripe: any;
    Rewardful: any;
  }
}

interface Props {
  workspace: TabilityTypes.Workspace;
}

function PricingVersion(props: Props) {
  const { workspace } = props;
  const { t } = useTranslation();
  const [usersCount, setUsersCount] = useState(0);
  const [freeUsersCount, setFreeUsersCount] = useState(-1);
  const statsQueryKey = [queryKeys.currentWorkspace, workspace.slug, 'stats'];

  const { isFetching: isLoadingStats } = useQuery(statsQueryKey, remoteApi.fetchWorkspaceStats, {
    staleTime: 0,
    onSuccess: (response) => {
      const stats = response.data;
      const { users_count, free_users_count } = stats;
      setUsersCount(users_count);
      setFreeUsersCount(free_users_count);
    },
  });

  // Workspace mutations
  const [createWorkspacePortalSessionMutation, { isLoading: isLoadingPortal }]: [any, any] = useMutation(
    remoteApi.createWorkspacePortalSession,
    {
      onSuccess: (response) => {
        const { data } = response;
        const { session_url } = data;

        window.location.href = session_url;
      },
    },
  );

  const handleShowPortal = () => {
    const params = {
      workspaceSlug: workspace.slug,
    };
    createWorkspacePortalSessionMutation(params);
  };

  if (isLoadingStats) {
    return (
      <Fragment>
        <ChromeSideNav>
          <WorkspaceSettingsNav />
        </ChromeSideNav>
        <ChromeContent>
          <SettingsNarrowWrapper>
            <ContentTitle>
              <h1>{t('workspaceSettingsBilling.header')}&nbsp;</h1>
            </ContentTitle>
            <Loader />
          </SettingsNarrowWrapper>
        </ChromeContent>
      </Fragment>
    );
  }

  const hasPlan = workspaceUtils.hasSubscription(workspace);

  let usersQuotaProgress = 0;

  let freeUsersQuotaProgress = 0;
  let checkinsQuotaProgress = 0;
  let standupsQuotaProgress = 0;
  let aiQuotaProgress = 0;
  if (workspace.billing_user_limit) {
    usersQuotaProgress = Math.round((usersCount / workspace.billing_user_limit) * 100);
  }
  if (workspace.billing_free_user_limit > 0) {
    freeUsersQuotaProgress = Math.round((freeUsersCount / workspace.billing_free_user_limit) * 100);
  }
  if (workspace.billing_checkins_limit) {
    checkinsQuotaProgress = Math.round((workspace.checkins_count / workspace.billing_checkins_limit) * 100);
  }
  if (workspace.billing_standups_limit) {
    standupsQuotaProgress = Math.round((workspace.standups_count / workspace.billing_standups_limit) * 100);
  }
  if (workspace.billing_ai_credits_limit) {
    aiQuotaProgress = Math.round((workspace.ai_credits_used / workspace.billing_ai_credits_limit) * 100);
  }

  const isAnnualPlan = ['tability-v5-basic-annual', 'tability-v5-premium-annual'].includes(
    workspace.billing_subscription_plan,
  );
  return (
    <Fragment>
      <Helmet>
        <script src="https://js.stripe.com/v3/" />
        <title>
          {workspace.name} | {t('workspaceSettingsBilling.title')} | Tability
        </title>
      </Helmet>
      <ChromeSideNav>
        <WorkspaceSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <SettingsNarrowWrapper>
          <ContentTitle>
            <h1>{t('workspaceSettingsBilling.header')}</h1>
          </ContentTitle>
          {hasPlan && (
            <Block>
              <h3>{t('workspaceSettingsBilling.yourPlan')}</h3>
              <p>
                <Trans
                  i18nKey="workspaceSettingsBilling.currentPlan"
                  components={{ b: <b /> }}
                  values={{ plan: workspaceUtils.billingSubscriptionToString(workspace) }}
                />
              </p>
            </Block>
          )}

          {!hasPlan && (
            <Block>
              <h3>{t('workspaceSettingsBilling.yourPlan')}</h3>
              <p>{t('workspaceSettingsBilling.v4Trial')}</p>
            </Block>
          )}
          <Block>
            <h3>{t('workspaceSettingsBilling.usage')}</h3>

            {['tability-v5-basic-monthly', 'tability-v5-premium-monthly'].includes(
              workspace.billing_subscription_plan,
            ) && (
              <>
                {workspace.billing_user_limit !== undefined && workspace.billing_user_limit > 0 && (
                  <Quota>
                    <Usage>{t('workspaceSettingsBilling.users', { count: workspace.billing_user_limit })}</Usage>
                  </Quota>
                )}
              </>
            )}
            {['tability-v5-basic-annual', 'tability-v5-premium-annual'].includes(
              workspace.billing_subscription_plan,
            ) && (
              <>
                {workspace.billing_user_limit !== undefined && workspace.billing_user_limit > 0 && (
                  <Quota>
                    <Usage>
                      <b>{usersCount}</b> /{' '}
                      {t('workspaceSettingsBilling.users', { count: workspace.billing_user_limit })}
                    </Usage>
                    <ProgressBarContainer>
                      <ProgressBar prct={usersQuotaProgress} />
                    </ProgressBarContainer>
                  </Quota>
                )}
              </>
            )}
            {workspace.billing_user_limit === 0 && (
              <Quota>{t('workspaceSettingsBilling.users', { count: usersCount })}</Quota>
            )}
            {workspace.billing_free_user_limit > 0 && (
              <Quota>
                <Usage>
                  <b>{freeUsersCount}</b> /{' '}
                  {t('workspaceSettingsBilling.free_users', { count: workspace.billing_free_user_limit })}
                </Usage>
                <ProgressBarContainer>
                  <ProgressBar prct={freeUsersQuotaProgress} />
                </ProgressBarContainer>
              </Quota>
            )}
            {workspace.billing_checkins_limit !== undefined && workspace.billing_checkins_limit > 0 && (
              <Quota>
                <Usage>
                  <b>{workspace.checkins_count}</b> / {workspace.billing_checkins_limit}{' '}
                  {t('workspaceSettingsBilling.checkins')}
                </Usage>
                <ProgressBarContainer>
                  <ProgressBar prct={checkinsQuotaProgress} />
                </ProgressBarContainer>
              </Quota>
            )}
            {workspace.billing_standups_limit !== undefined && workspace.billing_standups_limit > 0 && (
              <Quota>
                <Usage>
                  <b>{workspace.standups_count}</b> / {workspace.billing_standups_limit}{' '}
                  {t('workspaceSettingsBilling.standupsCounter')}
                </Usage>
                <ProgressBarContainer>
                  <ProgressBar prct={standupsQuotaProgress} />
                </ProgressBarContainer>
              </Quota>
            )}
            {workspace.billing_ai_credits_limit !== undefined && workspace.billing_ai_credits_limit > 0 && (
              <Quota>
                <Usage>
                  <b>{workspace.ai_credits_used}</b> / {workspace.billing_ai_credits_limit}{' '}
                  {t('workspaceSettingsBilling.aiCredits')}
                </Usage>
                <ProgressBarContainer>
                  <ProgressBar prct={aiQuotaProgress} />
                </ProgressBarContainer>
              </Quota>
            )}
          </Block>
          <Block>
            <h3>{t('workspaceSettingsBilling.manageSubscription')}</h3>
            {!isAnnualPlan && (
              <div>
                <KoalaButton to={`/${workspace.slug}/settings/billing/plans`} isLink={true}>
                  {t('workspaceSettingsBilling.manageSubscription')}
                </KoalaButton>
              </div>
            )}
            {isAnnualPlan && (
              <div>
                <p>{t('workspaceSettingsBilling.v5.annualContactSupport')}</p>
                <p>
                  <KoalaButton onClick={() => window.Intercom('show')}>
                    {t('workspaceSettingsBilling.v5.contactSupport')}
                  </KoalaButton>
                </p>
              </div>
            )}
          </Block>
          {hasPlan && (
            <Block>
              <h3>{t('workspaceSettingsBilling.invoice')}</h3>
              <div>
                <KoalaButton
                  onClick={() => handleShowPortal()}
                  disabled={isLoadingPortal}
                  loading={isLoadingPortal}
                  appearance="subtle"
                >
                  {t('workspaceSettingsBilling.updateSubscription')}
                </KoalaButton>
              </div>
              <div>
                <KoalaTextButton onClick={() => handleShowPortal()} disabled={isLoadingPortal}>
                  {t('workspaceSettingsBilling.cancelSubscription')}
                </KoalaTextButton>
              </div>
            </Block>
          )}
        </SettingsNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default PricingVersion;
