import React, { memo } from 'react';
import { Workspace, Membership } from 'types';
import styled from 'styled-components';
import theme from 'theme';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { useTranslation } from 'react-i18next';

import KoalaAvatar from 'koala/components/Avatar';
import ProgressBarV2 from 'components/ProgressBarV2';
import ConfidenceBar from 'components/ConfidenceBar';

export const ProfileBlock = styled.div`
  display: flex;
  padding: ${theme.spacing.x2};
  border-bottom: 1px solid ${theme.colors.N20};
  gap: ${theme.spacing.x1};
`;

export const ProfileDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
`;

const ProfileName = styled(Link)`
  font-weight: 600;
  font-size: 1.6rem;

  :hover {
    text-decoration: underline;
  }
`;

const StatsBlock = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${theme.spacing.x2};
  gap: ${theme.spacing.x2};
  height: 9rem;
  max-height: 9rem;
`;

const StatsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${theme.spacing.x1};
  flex: 1;

  &.flat {
    width: initial;
    flex: 1;
  }

  p {
    font-size: 1.4rem;
    font-weight: 700;
  }

  small {
    flex: 1;
    color: ${theme.colors.N60};
    font-size: 1.1rem;
    font-weight: 500;
  }
`;

interface Props {
  membership: Membership;
}

function Profile(props: Props) {
  const history = useHistory();
  const { membership } = props;
  const { t } = useTranslation();
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const handleAvatarClicked = () => {
    history.push(`/${currentWorkspace.slug}/people/${membership.id}`);
  };

  return (
    <>
      <ProfileBlock>
        <ProfileDetails>
          <KoalaAvatar membership={membership} size={4} tooltipType="none" handleClick={handleAvatarClicked} />
          <ProfileName to={`/${currentWorkspace.slug}/people/${membership.id}`}>
            {membership.cached_fullname ?? membership.cached_email}
          </ProfileName>
        </ProfileDetails>
      </ProfileBlock>
      <StatsBlock>
        <StatsSection>
          <small>{translate(currentWorkspace, CustomTermKey.OUTCOME, 2)}</small>
          <ProgressBarV2
            percentage={membership.outcome_progress_prct * 100}
            totalCount={membership.total_outcomes_count}
            label={`${Math.round(membership.outcome_progress_prct * 100)}%`}
          />
        </StatsSection>
        <StatsSection>
          <small>{translate(currentWorkspace, CustomTermKey.INITIATIVE, 2)}</small>
          <ProgressBarV2
            percentage={membership.initiative_progress_prct * 100}
            totalCount={membership.total_initiatives_count}
            label={`${membership.closed_initiatives_count}/${membership.total_initiatives_count}`}
          />
        </StatsSection>
        <StatsSection>
          <small>{t('components.profileCard.confidence')}</small>
          <ConfidenceBar
            totalCount={membership.total_outcomes_count}
            redCount={membership.red_outcomes_count}
            yellowCount={membership.yellow_outcomes_count}
            greenCount={membership.green_outcomes_count}
            greyCount={membership.grey_outcomes_count}
            ncs={Math.round(membership.ncs)}
          />
        </StatsSection>
      </StatsBlock>
    </>
  );
}

export default memo(Profile);
