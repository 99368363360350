import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import StateLabel from 'components/StateLabel';
import DropdownMenu from 'components/DropdownMenu';
import MarkdownContent from 'components/MarkdownContent';
import KoalaIconButton from 'koala/components/IconButton';
import KoalaButton from 'koala/components/Button';
import Reactions from 'components/Reactions';
import { CachedReaction } from 'types';
import KoalaAvatar from 'koala/components/Avatar';
import { useTranslation } from 'react-i18next';
import { formatDistanceToNowLocale } from 'utils/dateUtils';

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'gutter meta resolve'
    'gutter content resolve'
    'gutter footer footer';
  column-gap: ${theme.spacing.x3};

  margin-bottom: ${theme.spacing.x2};

  &.primary {
    background: #f3f3f3;
    border-radius: 4px;
    padding: ${theme.spacing.x3};

    border-bottom: 0;
  }
`;

const Gutter = styled.div`
  grid-area: gutter;
`;

const Resolve = styled.div`
  grid-area: resolve;
  display: flex;
  align-items: center;
  > button {
    margin-left: ${theme.spacing.x1};
  }
`;

const Meta = styled.div`
  grid-area: meta;
  display: flex;
  align-items: center;

  font-weight: 600;
`;

const Content = styled.div`
  grid-area: content;
  margin-top: ${theme.spacing.x1};
`;

const Footer = styled.div`
  grid-area: footer;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  gap: ${theme.spacing.x1};
  color: ${theme.colors.subtleText};

  > div {
    display: flex;
    align-items: center;
  }
`;

interface Props {
  reviewComment: any;
  setIsEditing: any;
}

function CommentBlock(props: Props) {
  const queryCache = useQueryCache();
  const { t, i18n } = useTranslation();
  const { reviewComment, setIsEditing } = props;
  const { membership } = reviewComment;

  const currentUserRole = useSelector((state: any) =>
    state.session.currentMembership ? state.session.currentMembership.role : null,
  );
  const currentUserId = useSelector((state: any) => (state.session.currentUser ? state.session.currentUser.id : null));

  // Review comment mutations
  const [deleteReviewCommentMutation]: [any, any] = useMutation(remoteApi.deleteReviewComment, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.reviewComments);
    },
  });

  const [updateReviewCommentMutation]: [any, any] = useMutation(remoteApi.updateReviewComment, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.reviewComments);
    },
  });

  const handleResolve = () => {
    const params = {
      resolved: true,
    };
    updateReviewCommentMutation({
      reviewCommentId: reviewComment.id,
      review_comment: params,
    });
  };

  const handleReopen = () => {
    const params = {
      resolved: false,
    };
    updateReviewCommentMutation({
      reviewCommentId: reviewComment.id,
      review_comment: params,
    });
  };

  let menu_items: any[] = [];

  if (membership && membership.user && currentUserId === membership.user.id) {
    menu_items = [
      <span key="edit" data-action="edit" data-id={reviewComment.id}>
        {t('shared.edit')}
      </span>,
      <span key="delete" data-action="delete" data-id={reviewComment.id}>
        {t('shared.delete')}
      </span>,
    ];
  } else if (currentUserRole === 'owner' || currentUserRole === 'admin') {
    menu_items = [
      <span key="delete" data-action="delete" data-id={reviewComment.id}>
        {t('shared.delete')}
      </span>,
    ];
  }

  const handleMenuSelection = (value: any) => {
    const action = value.props['data-action'];
    const reviewCommentId = value.props['data-id'];
    switch (action) {
      case 'edit':
        setIsEditing(true);
        break;
      case 'delete':
        if (window.confirm(t('comments.deleteComment') ?? 'Delete this comment?')) {
          deleteReviewCommentMutation(reviewCommentId);
        }
        break;
    }
  };

  const [reactions, setReactions] = useState<CachedReaction[]>([]);
  useEffect(() => {
    if (reviewComment.cached_reactions && typeof reviewComment.cached_reactions !== 'string') {
      setReactions(reviewComment.cached_reactions);
    } else {
      setReactions([]);
    }
  }, [reviewComment]);
  const [addReactionMutation] = useMutation(remoteApi.createReviewCommentReaction, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.reviewComments);
    },
  });
  const [deleteReactionMutation] = useMutation(remoteApi.deleteReaction, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.reviewComments);
    },
  });

  return (
    <Grid>
      <Gutter>
        <KoalaAvatar membership={membership} tooltipType="card" />
      </Gutter>
      <Meta>{membership.cached_fullname ?? membership.cached_email}</Meta>
      <Content>
        <MarkdownContent source={reviewComment.body} />
      </Content>
      {reviewComment.resolved && (
        <Resolve>
          <StateLabel>{t('shared.resolved')}</StateLabel>
          <KoalaButton onClick={handleReopen} appearance="subtle">
            {t('shared.reopen')}
          </KoalaButton>
        </Resolve>
      )}
      {!reviewComment.resolved && (
        <Resolve>
          <KoalaButton onClick={handleResolve} appearance="subtle">
            {t('shared.resolve')}
          </KoalaButton>
        </Resolve>
      )}
      <Footer>
        <div>{formatDistanceToNowLocale(reviewComment.created_at, i18n, true)}</div>
        <div>
          <Reactions
            reactions={reactions}
            addCallback={(emotion) => addReactionMutation({ reviewCommentId: reviewComment.id, emotion: emotion })}
            removeCallback={(reactionId) => deleteReactionMutation(reactionId)}
          />
        </div>
        <div>
          {menu_items.length > 0 && (
            <DropdownMenu
              trigger={<KoalaIconButton iconName="ellipsis" />}
              onSelection={handleMenuSelection}
              items={menu_items}
            />
          )}
        </div>
      </Footer>
    </Grid>
  );
}

export default React.memo(CommentBlock);
