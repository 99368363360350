import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import AddPersonIcon from 'components/_assets/AddPersonIcon';
import Loader from 'components/Loader';
import FormField from 'components/FormField';
import UserSearchableDropdown from 'components/UserSearchableDropdown';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaAvatar from 'koala/components/Avatar';
import { useTranslation } from 'react-i18next';

const OwnerLine = styled.div`
  display: flex;
  align-items: center;
  > div:first-child {
    margin-right: ${theme.spacing.x2};
  }
`;

const OwnerOption = styled.div`
  display: flex;
  align-items: center;
  margin: ${theme.spacing.x1} 0;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};

  border-radius: 8px;
  background: #f3f3f3;

  &:hover {
    cursor: pointer;
    background: #e7e7e7;
  }
  .owner-option-name {
    margin-left: ${theme.spacing.x2};
  }
`;

interface Props {
  outcomeId: string;
}

function OutcomeContributors(props: Props) {
  const { outcomeId } = props;
  const queryCache = useQueryCache();
  const { t } = useTranslation();

  const [outcomeContributors, setOutcomeContributors]: any = useState([]);

  const outcomeContributorsQueryKey = [queryKeys.outcomeContributors, outcomeId];
  const { isFetching: isFetchingOutcomeContributors } = useQuery(
    outcomeContributorsQueryKey,
    remoteApi.fetchOutcomeContributors,
    {
      staleTime: 0,
      onSuccess: (response) => {
        setOutcomeContributors(response.data);
      },
    },
  );

  const [addOutcomeContributorMutation, { isLoading: isAddingOutcomeContributor }]: [any, any] = useMutation(
    remoteApi.createOutcomeContributor,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKeys.outcomeContributors);
      },
    },
  );

  const [deleteOutcomeContributorMutation, { isLoading: isDeletingOutcomeContributor }]: [any, any] = useMutation(
    remoteApi.deleteOutcomeContributor,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKeys.outcomeContributors);
      },
    },
  );

  const handleAddOutcomeContributor = (membership_id: any) => {
    if (membership_id) {
      const params = {
        membership_id,
      };

      addOutcomeContributorMutation({
        outcomeId,
        outcome_contributor: params,
      });
    }
  };

  const handleDeleteOutcomeContributor = (outcomeContributorId: string) => {
    deleteOutcomeContributorMutation(outcomeContributorId);
  };

  const isUpdatingOutcomeContributors =
    isAddingOutcomeContributor || isDeletingOutcomeContributor || isFetchingOutcomeContributors;

  return (
    <FormField>
      <label>
        {t('shared.contributors')} {isUpdatingOutcomeContributors && <Loader size="small" />}
      </label>
      {outcomeContributors.map((outcomeContributor: any, i: number) => {
        const { membership } = outcomeContributor;
        return (
          <OwnerLine key={i}>
            <OwnerOption
              className="active"
              data-outcomeContributor-id={outcomeContributor.id}
              key={outcomeContributor.id}
            >
              <KoalaAvatar membership={membership} size={2.2} tooltipType="none" />
              <span className="owner-option-name">{membership.cached_fullname ?? membership.cached_email}</span>
            </OwnerOption>
            <KoalaTextButton
              onClick={(e: React.SyntheticEvent) => {
                e.preventDefault();
                handleDeleteOutcomeContributor(outcomeContributor.id);
              }}
            >
              {t('shared.remove')}
            </KoalaTextButton>
          </OwnerLine>
        );
      })}
      <UserSearchableDropdown
        position="left"
        trigger={
          <OwnerOption className="active option-button">
            <AddPersonIcon /> <span className="owner-option-name">{t('shared.addContributor')}</span>
          </OwnerOption>
        }
        callback={handleAddOutcomeContributor}
      />
    </FormField>
  );
}

export default React.memo(OutcomeContributors);
