import MentionBox from 'components/MentionBox';
import KoalaAvatar from 'koala/components/Avatar';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import React, { useState } from 'react';
import { useMutation, useQueryCache } from 'react-query';
import styled from 'styled-components';
import theme from 'theme';
import { RetrospectiveComment } from 'types';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import { useTranslation } from 'react-i18next';

const Grid = styled.div`
  padding: 1.2rem ${theme.spacing.x2};
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'avatar user'
    '. content'
    '. actions';
  gap: ${theme.spacing.x1};
`;

const Avatar = styled.div`
  grid-area: avatar;
`;

const User = styled.div`
  grid-area: user;
  color: ${theme.colors.N70};
`;

const Content = styled.div`
  grid-area: content;
`;

const Actions = styled.div`
  grid-area: actions;
  display: flex;
  gap: ${theme.spacing.x1};
`;

interface Props {
  retrospectiveComment: RetrospectiveComment;
  setIsEditing: (value: boolean) => void;
}

function RetrospectiveCommentEdit(props: Props) {
  const { retrospectiveComment, setIsEditing } = props;
  const { membership, body } = retrospectiveComment;
  const [comment, setComment] = useState(body);
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const translationKey = 'workspaceRetrospective';

  // Comment mutations
  const [updateCommentMutation, { isLoading }] = useMutation(remoteApi.updateRetrospectiveComment, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.retrospectiveComments, retrospectiveComment.retrospective_id, {}]);
      setComment('');
      setIsEditing(false);
    },
  });

  const handleSubmit = () => {
    const retrospective_comment = {
      body: comment,
    };
    updateCommentMutation({
      retrospectiveCommentId: retrospectiveComment.id,
      retrospective_comment,
    });
  };

  return (
    <Grid>
      <Avatar>
        <KoalaAvatar membership={membership} size={2.4} tooltipType="none" />
      </Avatar>
      <User>{membership.cached_fullname ?? membership.cached_email}</User>
      <Content>
        <MentionBox
          setValue={setComment}
          value={comment}
          placeholder={t(`${translationKey}.addComment`) ?? 'Add a comment...'}
          comment={true}
          cmdEnterCallback={handleSubmit}
        />
      </Content>
      <Actions>
        <KoalaButton onClick={handleSubmit} loading={isLoading}>
          {t(`shared.save`)}
        </KoalaButton>
        <KoalaTextButton onClick={() => setIsEditing(false)}>{t(`shared.cancel`)}</KoalaTextButton>
      </Actions>
    </Grid>
  );
}

export default RetrospectiveCommentEdit;
