import createReducer from './createReducer';
import * as actionTypes from 'state/actions/actionTypes';

const INITIAL_STATE = {
  globalPanelContent: '',
  globalModalContent: '',
  isInMSTeams: false, // Is in Teams is used to check if the user is in MS Teams
};

const globalUIReducer = createReducer(INITIAL_STATE)({
  /********************
   * Panel stuff
   ********************/

  [actionTypes.SET_GLOBAL_PANEL_CONTENT]: (state: any, action: any) => {
    return {
      ...state,
      globalPanelContent: action.globalPanelContent,
    };
  },
  [actionTypes.SET_GLOBAL_MODAL_CONTENT]: (state: any, action: any) => {
    return {
      ...state,
      globalModalContent: action.globalModalContent,
    };
  },
  [actionTypes.SET_IS_IN_MSTEAMS]: (state: any, action: any) => {
    const { isInMSTeams } = action;
    return {
      ...state,
      isInMSTeams,
    };
  },
});

export default globalUIReducer;
