import { fetchPublicOutcomeDetails, fetchPublicCheckins, fetchPublicInitiatives } from 'api/remote';
import PlanIconLabel from 'components/PlanIconLabel';
import TabilityWordmarkCustom from 'components/_assets/TabilityWordmarkCustom';
import queryKeys from 'config/queryKeys';
import React from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import theme from 'theme';
import { Initiative, Outcome, Plan, Workspace } from 'types';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { planTimelineProgress } from 'utils/planUtils';
import PresentationCheckin from './PresentationCheckin';
import Cookies from 'universal-cookie';

import * as routes from 'routes';
import * as outcomeUtils from 'utils/outcomeUtils';

import Loader from 'components/Loader';
import KoalaStackedAvatar from 'koala/components/StackedAvatars';
import KoalaConfidenceText from 'koala/components/ConfidenceText';
import KoalaCircularProgress from 'koala/components/CircularProgress';
import PublicInitiativeRow from 'components/PublicInitiativeRow';
import CheckinsChart from 'components/Chart';
import OutcomeWeight from 'components/OutcomeWeight';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  max-width: 70rem;
  width: 70rem;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x3};

  padding: ${theme.spacing.x2};
  background: ${theme.colors.N0};
  label {
    color: ${theme.colors.N60};
    font-weight: 800;
    font-size: 10px;
    line-height: 120%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
`;
const HeaderContainer = styled.div`
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${theme.spacing.x2};
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;
const SpacingContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  justify-content: space-between;
  align-items: center;
`;
const Owners = styled.div`
  display: flex;
  height: 3.2rem;
`;

const ContentBox = styled.div`
  background-color: #ffffff;
  border: solid 1px ${theme.colors.blockBorder};
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.x2};
  gap: ${theme.spacing.x1};

  .barTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
  }
  .new-initiative-container {
    margin: 0;
  }

  @media ${theme.devices.tablet} {
    padding: ${theme.spacing.x2} ${theme.spacing.x1};
  }
`;
const TaskRows = styled.div`
  display: flex;
  flex-direction: column;

  .initiative-state {
    display: none;
  }
  .initiative-due-date {
    display: none;
  }
  .initiative-details {
    width: 90%;
  }
`;

const Footer = styled.div`
  grid-area: footer;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.colors.N60};

  display: flex;
  justify-content: space-between;
  gap: ${theme.spacing.x1};
`;
const Watermark = styled.div`
  font-size: 1.2rem;
  height: 1.8rem;
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  svg {
    height: 1.8rem;
  }
`;

const LocationTrace = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  flex-wrap: wrap;
  justify-content: flex-end;
  font-size: 1.2rem;
  a {
    color: ${theme.colors.subtleText};
    :hover {
      text-decoration: underline;
    }
  }
`;

const OutcomeStats = styled.div`
  display: grid;
  gap: ${theme.spacing.x2};
  grid-template-columns: 1fr 1fr 1fr;

  @media ${theme.devices.tablet} {
    grid-template-columns: 1fr;
  }
`;

const InfoBlock = styled.div`
  background: ${theme.colors.N0};
  border: 1px solid ${theme.colors.blockBorder};
  box-sizing: border-box;
  padding: ${theme.spacing.x1};
  border-radius: 4px;
  h3 {
    display: flex;
    gap: ${theme.spacing.half};
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    min-height: 2.4rem;
    text-align: center;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: ${theme.spacing.x1};
    font-weight: 800;
  }
`;

interface Props {
  outcomeId: string;
  plan: Plan;
}

function PublicPlanPresentationOutcome(props: Props) {
  const { outcomeId, plan } = props;
  const cookies = new Cookies();
  const { t } = useTranslation();
  const workspace: Workspace = plan.workspace;

  const staleTime = 0;
  const { daysLeft, prctTime } = planTimelineProgress(plan);

  // Get the checkins
  const COOKIENAME = '_tbty_pp';
  const public_access_password = cookies.get(COOKIENAME);

  const params = {
    public_access_password,
  };

  const queryKeyOutcome = [queryKeys.currentOutcome, outcomeId, params];
  const { data: outcomeResponse }: any = useQuery(queryKeyOutcome, fetchPublicOutcomeDetails, {
    staleTime,
  });
  const outcome: Outcome = outcomeResponse ? outcomeResponse.data : null;
  // Construct the query key using the plan Id
  const queryKeyCheckins = [queryKeys.checkins, outcomeId, params];
  // Get the plan details
  const { data: checkinsResponse }: any = useQuery(queryKeyCheckins, fetchPublicCheckins, {
    staleTime,
  });
  const checkins = checkinsResponse ? checkinsResponse.data : [];

  const queryKeyInitiatives = [queryKeys.initiatives, outcomeId, params];
  const { data: initiativesResponse }: any = useQuery(queryKeyInitiatives, fetchPublicInitiatives, { staleTime });
  const initiatives: Initiative[] = initiativesResponse ? initiativesResponse.data : [];

  if (!outcome) {
    return <Loader size="big" />;
  }

  const { current_checkin, title, membership, contributors } = outcome;
  const confidence = current_checkin ? current_checkin.confidence : 'grey';

  const planRoute = routes.WORKSPACE_PLAN_TRACK_ROUTE.replace(':workspaceSlug', workspace.slug).replace(
    ':planId',
    plan.nano_slug,
  );
  const outcomeRoute = `${planRoute}#outcome:${outcome.nano_slug}:show`;
  const hasTarget = outcomeUtils.hasTarget(outcome);

  const timelineProgressData = {
    progressPercentage: prctTime || 0,
    colorType: 'B',
    centerLabel: [`${daysLeft}`, t('shared.time.daysLeft', { count: daysLeft })],
  };

  let confidenceColor = 'G';

  if (current_checkin) {
    confidenceColor = current_checkin.confidence.charAt(0).toUpperCase();
  }

  let outcomeProgressData = {
    progressPercentage: outcome.outcome_progress_prct * 100,
    colorType: confidenceColor,
    centerLabel: `${Math.round(outcome.outcome_progress_prct * 100)}%`,
  };

  if (outcome.outcome_progress_prct < 0) {
    outcomeProgressData = {
      progressPercentage: 0,
      colorType: confidenceColor,
      centerLabel: `—`,
    };
  }

  const initiativeProgressData = {
    progressPercentage: outcome.initiative_progress_prct * 100,
    colorType: 'B',
    centerLabel: `${outcome.closed_initiatives_count}/${outcome.total_initiatives_count}`,
  };

  return (
    <Container>
      <HeaderContainer>
        <Title>
          <KoalaConfidenceText
            isCompleted={outcome.completed}
            value={hasTarget ? outcome.outcome_progress_prct * 100 : null}
            confidence={confidence}
            size="large"
          />
          <OutcomeWeight outcome={outcome} />
          <h1>{title}</h1>
        </Title>
        <SpacingContainer>
          <Owners>
            <KoalaStackedAvatar owner={membership ?? null} contributors={contributors} size={3.2} hideTooltip />
          </Owners>
        </SpacingContainer>
      </HeaderContainer>
      <OutcomeStats>
        <InfoBlock>
          <h3>{translate(workspace, CustomTermKey.OUTCOME, 1)}</h3>
          <KoalaCircularProgress data={outcomeProgressData} size="large" />
        </InfoBlock>
        <InfoBlock>
          <h3>{translate(workspace, CustomTermKey.INITIATIVE, 2)}</h3>
          <KoalaCircularProgress data={initiativeProgressData} size="large" />
        </InfoBlock>
        <InfoBlock>
          <h3>{t('publicPlan.timeline')}</h3>
          <KoalaCircularProgress data={timelineProgressData} size="large" />
        </InfoBlock>
      </OutcomeStats>
      <ContentBox>
        <CheckinsChart checkins={checkins} outcome={outcome} />
      </ContentBox>
      <PresentationCheckin outcome={outcome} plan={plan} />
      <ContentBox>
        <SpacingContainer>
          <h3>{translate(workspace, CustomTermKey.INITIATIVE, 2)}</h3>
        </SpacingContainer>
        <TaskRows>
          {initiatives.map((initiative) => {
            return (
              <PublicInitiativeRow key={initiative.id} initiative={initiative} showMeta={true} isReadOnly={true} />
            );
          })}
        </TaskRows>
      </ContentBox>
      <Footer>
        <a href="https://www.tability.io" target="_blank" rel="noreferrer nofollower">
          <Watermark>
            <span>{t('shared.poweredBy')}</span>
            <TabilityWordmarkCustom />
          </Watermark>
        </a>
        <LocationTrace>
          <a href={workspace.url}>{workspace.name}</a>
          <span>/</span>
          <a href={planRoute}>
            <PlanIconLabel plan={plan} size={'small'} />
          </a>
          <span>/</span>
          <a href={outcomeRoute}>{title}</a>
        </LocationTrace>
      </Footer>
    </Container>
  );
}

export default PublicPlanPresentationOutcome;
