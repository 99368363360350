import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import DropdownMenu from 'components/DropdownMenu';
import MarkdownContent from 'components/MarkdownContent';
import KoalaIconButton from 'koala/components/IconButton';
import Reactions from 'components/Reactions';
import { CachedReaction } from 'types';
import KoalaAvatar from 'koala/components/Avatar';
import { useTranslation } from 'react-i18next';
import { formatDistanceToNowLocale } from 'utils/dateUtils';

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'gutter meta'
    'gutter content'
    'gutter footer';
  column-gap: ${theme.spacing.x3};

  margin-bottom: ${theme.spacing.x2};

  &.primary {
    background: #f3f3f3;
    border-radius: 4px;
    padding: ${theme.spacing.x3};

    border-bottom: 0;
  }
`;

const Gutter = styled.div`
  grid-area: gutter;
`;

const Meta = styled.div`
  grid-area: meta;
  display: flex;
  align-items: center;

  font-weight: 600;
`;

const Content = styled.div`
  grid-area: content;
  margin-top: ${theme.spacing.x1};
`;

const Footer = styled.div`
  grid-area: footer;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  gap: ${theme.spacing.x1};
  color: ${theme.colors.subtleText};

  .comment-actions {
    opacity: 0;
  }

  &:hover {
    .comment-actions {
      opacity: 1;
    }
  }

  > div {
    display: flex;
    align-items: center;
  }
`;

interface Props {
  reviewReply: any;
  setIsEditing: any;
}

function CommentBlock(props: Props) {
  const queryCache = useQueryCache();
  const { t, i18n } = useTranslation();
  const { reviewReply, setIsEditing } = props;
  const { membership } = reviewReply;

  const currentUserRole = useSelector((state: any) =>
    state.session.currentMembership ? state.session.currentMembership.role : null,
  );
  const currentUserId = useSelector((state: any) => (state.session.currentUser ? state.session.currentUser.id : null));

  // Review comment mutations
  const [deleteReviewReplyMutation]: [any, any] = useMutation(remoteApi.deleteReviewReply, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.reviewReplies);
    },
  });

  const handleMenuSelection = (value: any) => {
    const action = value.props['data-action'];
    const reviewReplyId = value.props['data-id'];
    switch (action) {
      case 'edit':
        setIsEditing(true);
        break;
      case 'delete':
        if (window.confirm(t('comments.deleteComment') ?? 'Delete this comment?')) {
          deleteReviewReplyMutation(reviewReplyId);
        }
        break;
    }
  };

  const [reactions, setReactions] = useState<CachedReaction[]>([]);
  useEffect(() => {
    if (reviewReply.cached_reactions && typeof reviewReply.cached_reactions !== 'string') {
      setReactions(reviewReply.cached_reactions);
    } else {
      setReactions([]);
    }
  }, [reviewReply]);
  const [addReactionMutation] = useMutation(remoteApi.createReviewReplyReaction, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.reviewReplies);
    },
  });
  const [deleteReactionMutation] = useMutation(remoteApi.deleteReaction, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.reviewReplies);
    },
  });

  let menu_items: any[] = [];

  if (membership && membership.user && currentUserId === membership.user.id) {
    menu_items = [
      <span key="edit" data-action="edit" data-id={reviewReply.id}>
        {t('shared.edit')}
      </span>,
      <span key="delete" data-action="delete" data-id={reviewReply.id}>
        {t('shared.delete')}
      </span>,
    ];
  } else if (currentUserRole === 'owner' || currentUserRole === 'admin') {
    menu_items = [
      <span key="delete" data-action="delete" data-id={reviewReply.id}>
        {t('shared.delete')}
      </span>,
    ];
  }

  return (
    <Grid>
      <Gutter>
        <KoalaAvatar membership={membership} tooltipType="none" />
      </Gutter>
      <Meta>{membership.cached_fullname ?? membership.cached_email}</Meta>
      <Content>
        <MarkdownContent source={reviewReply.body} />
      </Content>
      <Footer>
        <div>{formatDistanceToNowLocale(reviewReply.created_at, i18n, true)}</div>
        <div>
          <Reactions
            reactions={reactions}
            addCallback={(emotion) => addReactionMutation({ reviewReplyId: reviewReply.id, emotion: emotion })}
            removeCallback={(reactionId) => deleteReactionMutation(reactionId)}
          />
        </div>
        <div className="comment-actions">
          {menu_items.length > 0 && (
            <DropdownMenu
              trigger={<KoalaIconButton iconName="ellipsis" />}
              onSelection={handleMenuSelection}
              items={menu_items}
            />
          )}
        </div>
      </Footer>
    </Grid>
  );
}

export default React.memo(CommentBlock);
