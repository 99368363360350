import queryKeys from 'config/queryKeys';
import React from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import theme from 'theme';
import { Membership, Team, Workspace } from 'types';
import * as remoteApi from 'api/remote';
import { shallowEqual, useSelector } from 'react-redux';
import Loader from 'components/Loader';
import EmptyStatePanel from 'components/EmptyStatePanel';
import TeamLabel from 'components/TeamLabel';
import { t } from 'i18next';

const TeamsContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  flex-wrap: wrap;
`;
const EmptyState = styled(EmptyStatePanel)`
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  color: ${theme.colors.subtleText};
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 10rem auto;
  gap: ${theme.spacing.x1};
  label {
    font-size: 1.2rem;
    color: ${theme.colors.subtleText};
    width: 10rem;
    min-width: 10rem;
  }
`;

interface Props {
  membership: Membership;
}

function TeamList(props: Props) {
  const { membership } = props;

  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const teamsQueryKey = [queryKeys.teams, membership.id];
  const { isLoading, data: teamResponses } = useQuery(teamsQueryKey, remoteApi.fetchMembershipTeams);

  if (!teamResponses) {
    return null;
  }

  if (isLoading) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  let teams: Team[] = [];

  if (teamResponses) {
    teams = teamResponses.data;
  }

  const headerLabel =
    currentMembership.id === membership.id ? t('workspaceProfile.myTeams') : t('workspaceProfile.theirTeams');
  return (
    <Container>
      <label>{headerLabel}</label>
      <TeamsContainer>
        {teams.length > 0 && teams.map((team) => <TeamLabel team={team} workspace={currentWorkspace} isLink />)}
        {teams.length === 0 && <EmptyState>{t('workspaceProfile.noTeams')}</EmptyState>}
      </TeamsContainer>
    </Container>
  );
}

export default TeamList;
