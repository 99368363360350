import React, { Fragment } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import { useParams } from 'react-router-dom';
import { parseISO, formatDistanceToNow } from 'date-fns';
import * as outcomeUtils from 'utils/outcomeUtils';

import ReactTooltip from 'react-tooltip';
import KoalaAvatar from 'koala/components/Avatar';

export const BlockGutter = styled.div`
  position: relative;
  grid-area: gutter;
  display: flex;
  align-items: center;
`;
export const BlockContent = styled.div`
  line-height: 3.2rem;
  font-weight: 500;
  grid-area: content;
  background: transparent;
  margin: 0;
  .block-title {
    display: flex;
  }
  p {
    margin-top: ${theme.spacing.x1};
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }
`;
export const BlockMeta = styled.div`
  line-height: 3.2rem;
  font-weight: 500;
  grid-area: meta;
  display: flex;
  justify-content: flex-start;

  > div {
    margin-right: ${theme.spacing.x2};
  }

  button.icon {
    height: 3.2rem;
    width: 3.2rem;

    svg {
      width: 2rem;
      height: 2rem;
      g {
        stroke: ${theme.colors.subtleText};
      }
    }
  }
`;

const BlockGrid = styled.div`
  display: grid;
  cursor: pointer;
  grid-template-columns: 5rem 1fr 16rem;
  grid-template-rows: auto auto;
  grid-template-areas: 'gutter content meta';
  align-items: start;
  column-gap: 1.6rem;
  margin: 0;
  padding: ${theme.spacing.x1};
  border-radius: 8px;

  .meta {
  }

  &:hover,
  &.selected,
  &:focus {
    .block-action {
      opacity: 1;
    }

    background: #f3f3f3;
  }

  &.no-hover-bg {
    &:hover {
      background: transparent;
    }
  }

  .block-action {
    opacity: 0;
  }
`;

const ColorBlock = styled.div<{ confidence: string }>`
  background-color: ${(props) => confidenceToColor(props.confidence)};
  height: 3.2rem;
  width: 3.2rem;
  border-radius: 4px;

  &.secondary {
    width: 0.8rem;
    height: 2.4rem;
    border-radius: 2px;
    margin-right: 0.4rem;

    &.none {
      background-color: transparent;
    }
  }
`;

const PendingFlag = styled.div`
  height: 1.2rem;
  width: 1.2rem;
  background: ${theme.colors.red};
  border-radius: 50%;
  position: absolute;
  left: -3.2rem;
`;

const ProgressBarContainer = styled.div`
  height: 1rem;
  width: 10rem;
  position: relative;
  top: 1rem;
  background: ${theme.colors.blockBorder};
  border-radius: 4px;
  overflow: hidden;
`;

const ProgressBar = styled.div<{ confidence: string; prct: number }>`
  height: 100%;
  background: ${theme.colors.blue};
  background: ${(props) => confidenceToColor(props.confidence)};
  border-radius: 4px;
  width: ${(props) => props.prct}%;
`;

interface Props {
  outcome: TabilityTypes.Outcome;
  isSelected: boolean;
}

const confidenceToColor = (confidence: string) => {
  switch (confidence) {
    case 'red':
      return theme.colors.red;
    case 'yellow':
      return theme.colors.yellow;
    case 'green':
      return theme.colors.green;
    default:
      return theme.colors.grey;
  }
};

function OutcomeBlock(props: Props) {
  const { outcome, isSelected } = props;
  const { membership } = outcome;
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();

  const blockId = `outcome:${outcome.nano_slug}`;
  const hashPath = `#${blockId}:show`;
  const outcomeUrl = `https://${process.env.REACT_APP_DOMAIN}/${workspaceSlug}/inbox${hashPath}`;

  const handleOutcomeClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (window) {
      window.open(outcomeUrl, '_blank');
    }
  };

  const currentCheckin = outcome.current_checkin;
  const previousCheckin = outcome.previous_checkin;

  // Calculate the progress prct

  const progressPrct = outcome.outcome_progress_prct * 100;
  const hasTarget = outcomeUtils.hasTarget(outcome);

  const className = isSelected ? 'selected' : '';

  const currentConfidence = currentCheckin ? currentCheckin.confidence : 'grey';

  return (
    <BlockGrid onClick={handleOutcomeClick} className={className}>
      <BlockGutter>
        {previousCheckin && <ColorBlock className="secondary" confidence={previousCheckin.confidence} />}
        {!previousCheckin && <ColorBlock className="secondary none" confidence="" />}

        {currentCheckin && <ColorBlock confidence={currentCheckin.confidence} />}
        {!currentCheckin && <ColorBlock confidence="" />}
        {outcome.is_pending_checkin && (
          <Fragment>
            <PendingFlag data-tip data-for={`outcome-pending-${outcome.nano_slug}`} />
            {currentCheckin && (
              <ReactTooltip
                place="bottom"
                type="dark"
                id={`outcome-pending-${outcome.nano_slug}`}
                className="tooltip"
                effect="solid"
              >
                <b>Checkin is due</b>
                <br />
                Last update was {formatDistanceToNow(parseISO(currentCheckin.checkin_date))} ago.
              </ReactTooltip>
            )}
          </Fragment>
        )}
      </BlockGutter>
      <BlockContent>
        <h4>{outcome.title}</h4>
      </BlockContent>
      <BlockMeta>
        <KoalaAvatar membership={membership} size={2.8} tooltipType="none" />
        {hasTarget && (
          <ProgressBarContainer>
            <ProgressBar prct={progressPrct} confidence={currentConfidence} />
          </ProgressBarContainer>
        )}
        {!hasTarget && <div className="subtle">&mdash;</div>}
      </BlockMeta>
    </BlockGrid>
  );
}

export default React.memo(OutcomeBlock);
