import KoalaIconButton from 'koala/components/IconButton';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import ReviewCardDeck from './ReviewCardDeck';
import styled from 'styled-components';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import { useQuery } from 'react-query';
import { Workspace } from 'types';
import Cards from './YearReviewCards';
import { useHistory } from 'react-router-dom';
import theme from 'theme';
import { useTranslation } from 'react-i18next';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import KoalaLoader from 'koala/components/Loader';

const CustomPanelGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'panel-header'
    'panel-content'
    'panel-actions';
  height: 100%;
  padding-top: ${theme.spacing.x4};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.x2};
  width: 100%;
`;

const DeckContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

interface Props {}

function WorkspaceYearInReview(props: Props) {
  const history = useHistory();
  const { t } = useTranslation();
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const handleCancel = () => {
    history.goBack();
  };

  const queryKeyStreakLeaderboard = [queryKeys.workspaceYearReview, 'streak_leaderboard', currentWorkspace.slug];
  const { data: streakLeaderboard, isLoading: isLoadingStreakLeaderboard } = useQuery(
    queryKeyStreakLeaderboard,
    remoteApi.fetchWorkspaceYearReviewStreakLeaderboard,
  );

  const queryKeyCheckinLeaderboard = [queryKeys.workspaceYearReview, 'checkin_leaderboard', currentWorkspace.slug];
  const { data: checkinLeaderboard, isLoading: isLoadingCheckinLeaderboard } = useQuery(
    queryKeyCheckinLeaderboard,
    remoteApi.fetchWorkspaceYearReviewCheckinLeaderboard,
  );

  const queryKey = [queryKeys.workspaceYearReview, currentWorkspace.slug];
  const { data: workspaceYearReview, isLoading: isLoadingWorkspaceYearReview } = useQuery(
    queryKey,
    remoteApi.fetchWorkspaceYearReview,
  );

  const reviewStats = workspaceYearReview?.data;
  const minValue = 1;

  if (isLoadingStreakLeaderboard || isLoadingCheckinLeaderboard || isLoadingWorkspaceYearReview) {
    return (
      <CustomPanelGrid>
        <Header>
          <h2>{t('modals.yearReview.workspaceTitle')}</h2>
          <KoalaIconButton onClick={handleCancel} iconName="close" />
        </Header>
        <DeckContainer>
          <KoalaLoader size={5} />
        </DeckContainer>
      </CustomPanelGrid>
    );
  }
  if (!reviewStats) {
    return null;
  }

  let cards = [
    <Cards.ThankYou t={t} />,
    <Cards.WorkspaceSavings
      numCheckins={reviewStats.checkin_count}
      numRetrospectives={reviewStats.retrospective_count}
      numStandups={reviewStats.standup_count}
      t={t}
    />,
  ];

  if (reviewStats.most_active_checkin_day?.checkin_count > minValue) {
    cards = cards.concat(<Cards.WorkspaceMostActiveCheckinDay data={reviewStats.most_active_checkin_day} t={t} />);
  }

  if (reviewStats.standup_count > minValue) {
    cards = cards.concat(<Cards.WorkspaceStandupCount count={reviewStats.standup_count} t={t} />);
  }

  // Data source, can increase this later
  if (reviewStats.checkins_from_data_sources > minValue) {
    cards = cards.concat(
      <Cards.WorkspaceCheckinsFromDataSources data={reviewStats.checkins_from_data_sources} t={t} />,
    );
  }

  if (reviewStats.favorite_reactions.length > 0) {
    cards = cards.concat(
      <Cards.WorkspaceFavoriteReactions
        reactions={reviewStats.favorite_reactions}
        count={reviewStats.reaction_count}
        t={t}
      />,
    );
  }

  if (reviewStats.initiative_count > minValue) {
    cards = cards.concat(
      <Cards.WorkspaceInitiativeCount
        count={reviewStats.initiative_count}
        initiativeLabel={translate(currentWorkspace, CustomTermKey.INITIATIVE, 2)}
        t={t}
      />,
    );
  }

  if (streakLeaderboard?.data?.length > 0) {
    cards = cards.concat(<Cards.WorkspaceStreakLeaderboard data={streakLeaderboard?.data} t={t} />);
  }

  if (reviewStats.retrospective_count > minValue) {
    cards = cards.concat(<Cards.WorkspaceRetrospectiveCount count={reviewStats.retrospective_count} t={t} />);
  }

  if (checkinLeaderboard?.data?.length > 0) {
    cards = cards.concat(<Cards.WorkspaceCheckinLeaderboard data={checkinLeaderboard?.data} t={t} />);
  }

  if (reviewStats.checkin_count > minValue) {
    cards = cards.concat(<Cards.WorkspaceCheckinCount count={reviewStats.checkin_count} t={t} />);
  }
  cards = cards.concat(<Cards.WorkspaceIntro t={t} />);

  return (
    <CustomPanelGrid>
      <Header>
        <h2>{t('modals.yearReview.workspaceTitle')}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </Header>
      <DeckContainer>
        <ReviewCardDeck cards={cards} />
      </DeckContainer>
    </CustomPanelGrid>
  );
}

export default WorkspaceYearInReview;
