import React from 'react';

const AlertIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17ZM19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 0.999999 10 0.999999C14.9706 1 19 5.02944 19 10Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 12C9.44772 12 9 11.5523 9 11L9 6C9 5.44771 9.44772 5 10 5C10.5523 5 11 5.44771 11 6L11 11C11 11.5523 10.5523 12 10 12Z"
      fill="#737373"
    />
    <path
      d="M9 14C9 13.4477 9.44772 13 10 13C10.5523 13 11 13.4477 11 14C11 14.5523 10.5523 15 10 15C9.44772 15 9 14.5523 9 14Z"
      fill="#737373"
    />
  </svg>
);

export default AlertIcon;
