import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryCache } from 'react-query';

import { ActionBlock, IntegrationAction, IntegrationBlock, IntegrationContent, IntegrationLogo } from '..';
import { Integration, Workspace } from 'types';
import KoalaTextButton from 'koala/components/TextButton';
import PowerBIIcon from '../_assets/IconPowerBI.png';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import KoalaLoader from 'koala/components/Loader';
import KoalaButton from 'koala/components/Button';

interface Props {
  workspace: Workspace;
  integrations: Integration[];
  state: string;
}

function PowerBIIntegration(props: Props) {
  const { workspace, integrations, state } = props;
  const { t } = useTranslation();
  const queryCache = useQueryCache();
  const queryKey = [queryKeys.integrations, workspace.slug];

  const [uninstallPowerBIMutation, { isLoading: isUninstallingPowerBI }] = useMutation(remoteApi.uninstallPowerBI, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKey);
    },
  });

  const handleUninstallPowerBI = (e: any) => {
    e.preventDefault();
    uninstallPowerBIMutation(workspace.slug);
  };

  const powerBIRedirectURL = `https://${process.env.REACT_APP_DOMAIN}/install/power_bi`;
  const powerBIScope =
    `https://analysis.windows.net/powerbi/api/Dashboard.Read.All ` +
    `https://analysis.windows.net/powerbi/api/Workspace.Read.All ` +
    `https://analysis.windows.net/powerbi/api/Report.Read.All ` +
    `https://analysis.windows.net/powerbi/api/Dataset.ReadWrite.All ` +
    `offline_access`;
  const powerBIOAuthURL =
    `https://login.microsoftonline.com/${process.env.REACT_APP_POWER_BI_TENANT_ID}/oauth2/v2.0/authorize` +
    `?client_id=${process.env.REACT_APP_POWER_BI_CLIENT_ID}` +
    `&response_type=code&redirect_uri=${encodeURIComponent(powerBIRedirectURL)}` +
    `&scope=${encodeURIComponent(powerBIScope)}` +
    `&state=${state}`;
  const powerBIIntegration = integrations.filter((i: Integration) => i.integration_type === 'power_bi')[0];
  const uninstallPowerBILabel = isUninstallingPowerBI ? <KoalaLoader /> : t('workspaceSettingsIntegrations.remove');

  return (
    <IntegrationBlock>
      <IntegrationLogo>
        <img src={PowerBIIcon} alt="PowerBI" />
      </IntegrationLogo>
      <IntegrationContent>
        <h3>{t('workspaceSettingsIntegrations.dataConnectorHeader', { integration: 'PowerBI' })}</h3>
        <p>{t('workspaceSettingsIntegrations.dataConnectorInfo', { integration: 'PowerBI' })}</p>
        <p>
          <a href="https://guides.tability.app/docs/integrations/power-bi" target="_blank" rel="noopener noreferrer">
            {t('workspaceSettingsIntegrations.integrationLink')}
          </a>
        </p>
      </IntegrationContent>
      <IntegrationAction>
        {powerBIIntegration && (
          <ActionBlock>
            <div>
              <Trans
                i18nKey="workspaceSettingsIntegrations.connected"
                components={{ b: <b /> }}
                values={{ value: powerBIIntegration.connected_to }}
              />
            </div>
            <KoalaButton href={powerBIOAuthURL} appearance="secondary" size="small">
              {t('workspaceSettingsIntegrations.reinstall')}
            </KoalaButton>
            <KoalaTextButton size="small" onClick={handleUninstallPowerBI}>
              {uninstallPowerBILabel}
            </KoalaTextButton>
          </ActionBlock>
        )}
        {!powerBIIntegration && (
          <KoalaButton href={powerBIOAuthURL}>
            {t('workspaceSettingsIntegrations.connectTo', { integration: 'PowerBI' })}
          </KoalaButton>
        )}
      </IntegrationAction>
    </IntegrationBlock>
  );
}

export default PowerBIIntegration;
