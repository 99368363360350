import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useInfiniteQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import theme from 'theme';
import parse from 'parse-link-header';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import { useHotkeys } from 'react-hotkeys-hook';

// API
import * as remoteApi from 'api/remote';

// Header component

// Components
import Loader from 'components/Loader';
import StandupBlock from './StandupBlock';

const ListingContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header'
    'standups';

  height: 100%;
  min-height: 100%;
  position: relative;
  flex: 1;
`;

const Standups = styled.div`
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: ${theme.spacing.x2};

  .empty_state_header {
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 800;
  }

  .ql-editor {
    font-size: 1.4rem !important;
  }
`;

const EmptyState = styled.div`
  padding: 0 ${theme.spacing.x2};
`;

interface Props {
  workspace: TabilityTypes.Workspace;
  membership?: TabilityTypes.Membership;
}

function StandupColumn(props: Props) {
  const { workspace, membership } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [standups, setStandups]: any = useState([]);
  const [resultsCount, setResultsCount]: any = useState(0);

  const showCreateStandupForm = () => {
    dispatch(setGlobalModalContent(`workspace::create.standup`));
  };

  useHotkeys('c', () => showCreateStandupForm(), { scopes: ['*'] });

  let filter = {};

  if (membership) {
    filter = {
      membership_id: membership.id,
    };
  }

  // Search query attributes
  const standupsQueryKey = [
    queryKeys.standups,
    workspace.id,
    {
      filter,
      limit: 1,
    },
  ];
  const getNextPage = (response: any) => {
    if (response && response.headers && response.headers.link) {
      const links = response.headers.link;
      const parsed = parse(links);
      if (parsed && parsed.next) {
        return parsed.next.page;
      }
    }
    return null;
  };

  // Search params for search
  const { isLoading } = useInfiniteQuery(standupsQueryKey, remoteApi.fetchStandups, {
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
    onSuccess: (response) => {
      const resultsCount = response[0].headers['x-total'];
      setResultsCount(resultsCount);
      setStandups(response);
    },
  });

  return (
    <>
      <ListingContainer>
        <Standups>
          {isLoading && <Loader size="medium" />}
          {parseInt(resultsCount) === 0 && membership && (
            <EmptyState>
              <p className="subtle">{t('workspaceStandups.emptyState.noStandups')}</p>
            </EmptyState>
          )}
          {standups.map((group: any, i: number) => {
            return (
              <Fragment key={i}>
                {group.data.map((standup: TabilityTypes.Standup) => {
                  return <StandupBlock standup={standup} />;
                })}
              </Fragment>
            );
          })}
        </Standups>
      </ListingContainer>
    </>
  );
}

export default StandupColumn;
