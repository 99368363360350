import { fetchCheckins, fetchInitiatives, fetchOutcomeDetails, manageOutcome, updateOutcome } from 'api/remote';
import DropdownMenu from 'components/DropdownMenu';
import PlanIconLabel from 'components/PlanIconLabel';
import TabilityWordmarkCustom from 'components/_assets/TabilityWordmarkCustom';
import queryKeys from 'config/queryKeys';
import KoalaIcon from 'koala/components/Icons';
import React from 'react';
import { queryCache, useMutation, useQuery } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import styled from 'styled-components';
import theme from 'theme';
import { Initiative, Outcome, Plan } from 'types';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { planTimelineProgress } from 'utils/planUtils';

import * as planUtils from 'utils/planUtils';
import * as workspaceUtils from 'utils/workspaceUtils';
import * as outcomeUtils from 'utils/outcomeUtils';
import * as membershipUtils from 'utils/membershipUtils';
import * as routes from 'routes';
import { useHistory, Link } from 'react-router-dom';

import KoalaIconButton from 'koala/components/IconButton';
import Loader from 'components/Loader';
import NewInitiativeForm from 'panels/OutcomePanel/NewInitiativeForm';
import PresentationCheckin from './PresentationCheckin';
import KoalaStackedAvatar from 'koala/components/StackedAvatars';
import KoalaConfidenceText from 'koala/components/ConfidenceText';
import KoalaCircularProgress from 'koala/components/CircularProgress';
import InitiativeRow from 'components/InitiativeRow';
import CheckinsChart from 'components/Chart';
import OutcomeWeight from 'components/OutcomeWeight';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x3};

  padding: ${theme.spacing.x2};
  background: ${theme.colors.N0};
  label {
    color: ${theme.colors.N60};
    font-weight: 800;
    font-size: 10px;
    line-height: 120%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  max-width: 90rem;
  width: 90rem;
  @media ${theme.devices.laptop} {
    width: 70rem;
  }
`;
const HeaderContainer = styled.div`
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${theme.spacing.x2};
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;
const SpacingContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  justify-content: space-between;
  align-items: center;
`;
const Owners = styled.div`
  display: flex;
  height: 3.2rem;
  .vl {
    border-left: 1px solid ${theme.colors.N10};
    height: 3.2rem;
    margin: 0 ${theme.spacing.x1};
  }
`;

const ContentBox = styled.div`
  background-color: #ffffff;
  border: solid 1px ${theme.colors.blockBorder};
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.x2};
  gap: ${theme.spacing.x1};

  .barTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
  }
  .new-initiative-container {
    margin: 0;
  }

  @media ${theme.devices.tablet} {
    padding: ${theme.spacing.x2} ${theme.spacing.x1};
  }
`;
const TaskRows = styled.div`
  display: flex;
  flex-direction: column;
  .initiative-due-date {
    display: none;
  }
`;

const Footer = styled.div`
  grid-area: footer;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.colors.N60};

  display: flex;
  justify-content: space-between;
  gap: ${theme.spacing.x1};
`;
const Watermark = styled.div`
  font-size: 1.2rem;
  height: 1.8rem;
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  svg {
    height: 1.8rem;
  }
`;

const LocationTrace = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  flex-wrap: wrap;
  justify-content: flex-end;
  font-size: 1.2rem;
  a {
    color: ${theme.colors.subtleText};
    :hover {
      text-decoration: underline;
    }
  }
`;

const OutcomeStats = styled.div`
  display: grid;
  gap: ${theme.spacing.x2};
  grid-template-columns: 1fr 1fr 1fr;

  @media ${theme.devices.tablet} {
    grid-template-columns: 1fr;
  }
`;

const InfoBlock = styled.div`
  background: ${theme.colors.N0};
  border: 1px solid ${theme.colors.blockBorder};
  box-sizing: border-box;
  padding: ${theme.spacing.x1};
  border-radius: 4px;
  h3 {
    display: flex;
    gap: ${theme.spacing.half};
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    min-height: 2.4rem;
    text-align: center;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: ${theme.spacing.x1};
    font-weight: 800;
  }
`;

interface Props {
  outcomeId: string;
  plan: Plan;
}

function PresentationModeContent(props: Props) {
  const { outcomeId, plan } = props;
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const isReadOnly = membershipUtils.isReadOnly(currentMembership);

  const hasStarterSubscription =
    workspaceUtils.hasStarterSubscription(currentWorkspace) || currentWorkspace.pricing_version >= 2;
  const hasEssentialsSubscription =
    workspaceUtils.hasEssentialsSubscription(currentWorkspace) || currentWorkspace.pricing_version >= 2;
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const staleTime = 60 * 1000 * 5;

  const queryKeyOutcome = [queryKeys.currentOutcome, outcomeId];
  const { data: outcomeResponse }: any = useQuery(queryKeyOutcome, fetchOutcomeDetails, {
    staleTime,
  });
  const outcome: Outcome = outcomeResponse ? outcomeResponse.data : null;

  const queryKeyCheckins = [queryKeys.checkins, outcomeId];
  const { data: checkinsResponse }: any = useQuery(queryKeyCheckins, fetchCheckins, {
    staleTime,
  });
  const checkins = checkinsResponse ? checkinsResponse.data : [];

  const queryKeyInitiatives = [queryKeys.initiatives, outcomeId];
  const { data: initiativesResponse }: any = useQuery(queryKeyInitiatives, fetchInitiatives, {
    staleTime,
  });
  let initiatives: Initiative[] = initiativesResponse ? initiativesResponse.data : [];

  const [manageOutcomeMutation]: [any, any] = useMutation(manageOutcome, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.currentOutcome, outcomeId]);
      queryCache.invalidateQueries([queryKeys.outcomes]);
    },
  });

  const [updateOutcomeMutation]: [any, any] = useMutation(updateOutcome, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.currentOutcome, outcomeId]);
      queryCache.invalidateQueries([queryKeys.outcomes]);
    },
  });

  if (!outcome) {
    return <Loader size="big" />;
  }

  const { current_checkin, title, membership, contributors, nano_slug } = outcome;

  const blockId = `outcome:${nano_slug}`;
  const hasEditPermission = planUtils.hasEditPermission(plan, currentMembership);
  const editHashRoute = `#${blockId}:edit`;
  const editRemoteHashRoute = `#${blockId}:edit_remote`;
  const moveHashRoute = `${blockId}:move`;
  const { daysLeft, prctTime } = planTimelineProgress(plan);
  const confidence = current_checkin ? current_checkin.confidence : 'grey';

  const menuItems = [];

  if (hasEditPermission) {
    menuItems.push(
      <span key="edit" data-action="edit" data-route={editHashRoute}>
        {t('shared.edit')}
      </span>,
      <span key="move" data-action="move" data-route={moveHashRoute}>
        {t('shared.outcomes.moveOutcome', {
          outcome: translate(currentWorkspace, CustomTermKey.OUTCOME, 1).toLowerCase(),
        })}
      </span>,
    );
  }

  if (hasEssentialsSubscription) {
    menuItems.push(
      <span key="edit-remote" data-action="edit_remote">
        {t('shared.outcomes.checkinsApi')}
      </span>,
    );
  } else {
    menuItems.push(
      <SpacingContainer key="edit-remote" data-action="edit_remote">
        <span>{t('shared.outcomes.checkinsApi')}</span>
        <KoalaIcon iconName="upgrade" />
      </SpacingContainer>,
    );
  }
  if (hasStarterSubscription || outcome.is_dummy) {
    if (hasEditPermission) {
      menuItems.push(
        <span key="align" data-action="align">
          {t('shared.align')}
        </span>,
      );
    }
    menuItems.push(
      <span key="embed" data-action="embed">
        {t('shared.outcomes.embed')}
      </span>,
    );
  } else {
    if (hasEditPermission) {
      menuItems.push(
        <SpacingContainer key="align" data-action="align">
          <span>{t('shared.align')}</span>
          <KoalaIcon iconName="upgrade" />
        </SpacingContainer>,
      );
    }
    menuItems.push(
      <SpacingContainer key="upgrade-embed" data-action="upgrade-embed">
        <span>{t('shared.outcomes.embed')}</span>
        <KoalaIcon iconName="upgrade" />
      </SpacingContainer>,
    );
  }
  if (hasEditPermission) {
    if (!outcome.archived) {
      menuItems.push(
        <span key="archive" data-action="archive">
          {t('shared.archive')}
        </span>,
      );
    } else {
      menuItems.push(
        <span key="unarchive" data-action="unarchive">
          {t('shared.unarchive')}
        </span>,
      );
    }
  }

  if (!outcome.completed) {
    menuItems.push(
      <span key="complete" data-action="complete">
        {t('shared.outcomes.markComplete')}
      </span>,
    );
  } else {
    menuItems.push(
      <span key="open" data-action="open">
        {t('shared.outcomes.reopen')}
      </span>,
    );
  }

  const handleMenuSelection = (value: any) => {
    const action = value.props['data-action'];
    switch (action) {
      case 'embed':
        dispatch(setGlobalModalContent(`${blockId}:share`));
        break;
      case 'upgrade-embed':
        const upgradeModalHash = 'workspace:tability20-starter:upgrade';
        dispatch(setGlobalModalContent(upgradeModalHash));
        break;
      case 'complete':
        manageOutcomeMutation({
          outcomeId: outcomeId,
          outcome: {
            completed: true,
          },
        });
        break;
      case 'open':
        manageOutcomeMutation({
          outcomeId: outcomeId,
          outcome: {
            completed: false,
          },
        });
        break;
      case 'archive':
        updateOutcomeMutation({
          outcomeId: outcomeId,
          outcome: {
            archived: true,
          },
        });
        break;
      case 'unarchive':
        updateOutcomeMutation({
          outcomeId: outcomeId,
          outcome: {
            archived: false,
          },
        });
        break;
      case 'edit':
        const editRoute = value.props['data-route'];
        history.push(editRoute);
        break;
      case 'edit_remote':
        history.push(editRemoteHashRoute);
        break;
      case 'align':
        dispatch(setGlobalModalContent(`${blockId}:add.dependency`));
        break;
      case 'move':
        dispatch(setGlobalModalContent(`${blockId}:move`));
        break;
    }
  };

  const lastInitiative = initiatives[initiatives.length - 1];
  const hasTarget = outcomeUtils.hasTarget(outcome);

  const timelineProgressData = {
    progressPercentage: prctTime || 0,
    colorType: 'B',
    centerLabel: [`${daysLeft}`, t('shared.time.daysLeft', { count: daysLeft })],
  };

  let confidenceColor = 'G';
  if (current_checkin) {
    confidenceColor = current_checkin.confidence.charAt(0).toUpperCase();
  }

  let outcomeProgressData = {
    progressPercentage: outcome.outcome_progress_prct * 100,
    colorType: confidenceColor,
    centerLabel: `${Math.round(outcome.outcome_progress_prct * 100)}%`,
  };

  if (outcome.outcome_progress_prct < 0) {
    outcomeProgressData = {
      progressPercentage: 0,
      colorType: confidenceColor,
      centerLabel: `—`,
    };
  }

  const initiativeProgressData = {
    progressPercentage: outcome.initiative_progress_prct * 100,
    colorType: 'B',
    centerLabel: `${outcome.closed_initiatives_count}/${outcome.total_initiatives_count}`,
  };

  return (
    <Container>
      <HeaderContainer>
        <Title>
          <KoalaConfidenceText
            isCompleted={outcome.completed}
            value={hasTarget ? outcome.outcome_progress_prct * 100 : null}
            confidence={confidence}
            size="large"
          />
          <OutcomeWeight outcome={outcome} />
          <h1>{title}</h1>
        </Title>
        <SpacingContainer>
          <Owners>
            <KoalaStackedAvatar owner={membership ?? null} contributors={contributors} size={3.2} />
          </Owners>
          {!isReadOnly && (
            <DropdownMenu
              trigger={<KoalaIconButton iconName="ellipsis" />}
              items={menuItems}
              onSelection={handleMenuSelection}
            />
          )}
        </SpacingContainer>
      </HeaderContainer>
      <OutcomeStats>
        <InfoBlock>
          <h3>{translate(currentWorkspace, CustomTermKey.OUTCOME, 1)}</h3>
          <KoalaCircularProgress data={outcomeProgressData} size="large" />
        </InfoBlock>
        <InfoBlock>
          <h3>{translate(currentWorkspace, CustomTermKey.INITIATIVE, 2)}</h3>
          <KoalaCircularProgress data={initiativeProgressData} size="large" />
        </InfoBlock>
        <InfoBlock>
          <h3>{t('publicPlan.timeline')}</h3>
          <KoalaCircularProgress data={timelineProgressData} size="large" />
        </InfoBlock>
      </OutcomeStats>
      <ContentBox>
        <CheckinsChart checkins={checkins} outcome={outcome} />
      </ContentBox>
      <PresentationCheckin outcome={outcome} checkins={checkins} isReadOnly={isReadOnly} />
      <ContentBox>
        <SpacingContainer>
          <h3>{translate(currentWorkspace, CustomTermKey.INITIATIVE, 2)}</h3>
        </SpacingContainer>
        <TaskRows>
          {initiatives.map((initiative) => {
            const blockId = `initiative:${initiative.nano_slug}`;
            const showHashRoute = `#${blockId}:show`;
            const handleClick = () => {
              history.push(showHashRoute);
            };
            return (
              <InitiativeRow initiative={initiative} showMeta={true} handleClick={handleClick} key={initiative.id} />
            );
          })}
        </TaskRows>
        {!isReadOnly && <NewInitiativeForm outcome={outcome} lastInitiative={lastInitiative} />}
      </ContentBox>
      <Footer>
        <a href="https://www.tability.io" target="_blank" rel="noreferrer nofollower">
          <Watermark>
            <span>{t('shared.poweredBy')}</span>
            <TabilityWordmarkCustom />
          </Watermark>
        </a>
        <LocationTrace>
          <Link to={`/${currentWorkspace.slug}`}>{currentWorkspace.name}</Link>
          <span>/</span>
          <Link
            to={routes.WORKSPACE_PLAN_TRACK_ROUTE.replace(':workspaceSlug', currentWorkspace.slug).replace(
              ':planId',
              plan.nano_slug,
            )}
          >
            <PlanIconLabel plan={plan} size={'small'} />
          </Link>
          <span>/</span>
          <Link
            to={`${routes.WORKSPACE_PLAN_TRACK_ROUTE.replace(':workspaceSlug', currentWorkspace.slug).replace(
              ':planId',
              plan.nano_slug,
            )}#outcome:${outcome.nano_slug}:show`}
          >
            {title}
          </Link>
        </LocationTrace>
      </Footer>
    </Container>
  );
}

export default PresentationModeContent;
