import React, { Fragment, useEffect } from 'react';
import * as TabilityTypes from 'types';
import { useSelector, shallowEqual } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import * as amplitudeUtils from 'utils/amplitude';
import { Helmet } from 'react-helmet';

// Header component
import WorkspaceHeader from 'components/WorkspaceHeader';
import { MobileReadyChromeHeader } from 'components/MobileReadyChrome';

// Components
import { ChromeContent } from 'components/Chrome';

import { useTranslation } from 'react-i18next';

import Newsfeed from './Newsfeed';

const Title = styled.h1`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};

  svg {
    margin-left: ${theme.spacing.x1};
  }

  @media ${theme.devices.mobile} {
    .avatar--custom-size {
      display: none;
    }
    gap: unset;
  }
`;

const ContentWrapper = styled.div`
  max-width: 80rem;
  margin: 0 auto;
  padding: ${theme.spacing.x4};
`;

function Inbox() {
  const currentWorkspace: TabilityTypes.Workspace = useSelector(
    (state: any) => state.session.currentWorkspace,
    shallowEqual,
  );
  const { t } = useTranslation();

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.workspace.activity.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>
          {currentWorkspace.name} | {t('workspaceNewsfeed.title')} | Tability
        </title>
      </Helmet>
      <MobileReadyChromeHeader>
        <WorkspaceHeader
          workspace={currentWorkspace}
          useGreyBackground={true}
          title={<Title>{t('workspaceNewsfeed.title')}</Title>}
        />
      </MobileReadyChromeHeader>
      <ChromeContent className="grey-bg">
        <ContentWrapper>
          <Newsfeed />
        </ContentWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default Inbox;
