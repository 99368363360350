import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

// This component will load the memberships from the user and redirect to the appropriate workspace if it exists.
// If no workspace has been found, will redirect to the workspace creation screen

function MSTeamsRedirect() {
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const isInMSTeams = useSelector((state: any) => state.globalUI.isInMSTeams);

  if (isInMSTeams) {
    // Open the page in a new window
    const url = window.location.href;
    window.open(url, '_blank');

    // Redirect the app to the homepage
    return <Redirect to={`/${currentWorkspace.slug}`} />;
  }

  return null;
}

export default MSTeamsRedirect;
