import { ModalContent, ModalGrid, ModalHeader } from 'components/GlobalModal';
import queryKeys from 'config/queryKeys';
import KoalaIconButton from 'koala/components/IconButton';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import { PlanSubscription } from 'types';
import * as remoteApi from 'api/remote';
import { AxiosResponse } from 'axios';
import KoalaLoader from 'koala/components/Loader';
import styled from 'styled-components';
import theme from 'theme';
import KoalaAvatar from 'koala/components/Avatar';
import KoalaTextButton from 'koala/components/TextButton';
import ReactTooltip from 'react-tooltip';
import UserSearchableDropdown from 'components/UserSearchableDropdown';
import AddPersonIcon from 'components/_assets/AddPersonIcon';

const OwnerLine = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  &.header {
    margin-bottom: ${theme.spacing.x1};
  }
`;

const NameContainer = styled.div`
  min-width: 30rem;
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

const OwnerOption = styled.div`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};

  border-radius: 8px;

  &:hover {
    cursor: pointer;
    background: #f3f3f3;
  }
  .owner-option-name {
    margin-left: ${theme.spacing.x2};
  }

  &.active {
    background: #f3f3f3;
    &:hover {
      cursor: initial;
      background: #f3f3f3;
    }
  }

  &.not-assigned {
    opacity: 0.5;
  }

  &.option-button {
    background: #f3f3f3;
    svg {
      height: 1.8rem;
      g {
        stroke: ${theme.colors.subtleText};
      }
    }
    &:hover {
      cursor: pointer;
      background: #e7e7e7;
    }
  }
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  align-items: start;
  justify-content: start;
  margin-bottom: ${theme.spacing.x2};
`;

const SubscribersSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
`;

interface Props {
  planId: string;
}

function PlanSubscribers(props: Props) {
  const { planId } = props;
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const [planSubscriptions, setPlanSubscriptions] = useState<PlanSubscription[] | null>(null);
  const { t } = useTranslation();
  const translationKey = 'modals.planSubscribers';
  const planSubscriptionQueryKey = [queryKeys.planSubscriptions, planId];
  // Get the plan subscription
  const { isFetching: isFetchingPlanSubscriptions } = useQuery(
    planSubscriptionQueryKey,
    remoteApi.fetchPlanSubscriptions,
    {
      staleTime: 0,
      onSuccess: (response: AxiosResponse<PlanSubscription[]>) => {
        setPlanSubscriptions(response.data);
      },
      onError: () => {
        setPlanSubscriptions(null);
      },
    },
  );

  const [deletePlanSubscriptionMutation] = useMutation(remoteApi.deletePlanSubscription, {
    onSuccess: (response: any) => {
      queryCache.invalidateQueries(planSubscriptionQueryKey);
    },
  });

  const [createPlanSubscriptionMutation] = useMutation(remoteApi.createPlanSubscription, {
    onSuccess: () => {
      queryCache.invalidateQueries(planSubscriptionQueryKey);
    },
  });

  const handleUnsubscribe = (subscriberId: string) => {
    deletePlanSubscriptionMutation(subscriberId);
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const handleAddSubscriber = (subscriberId: string) => {
    createPlanSubscriptionMutation({ planId, membershipId: subscriberId });
  };

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`${translationKey}.title`)}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <InfoSection>
          <p>{t(`${translationKey}.info`)}</p>
          <UserSearchableDropdown
            trigger={
              <OwnerOption className="active option-button">
                <AddPersonIcon /> <span className="owner-option-name">{t(`${translationKey}.addSubscriber`)}</span>
              </OwnerOption>
            }
            // @ts-ignore
            callback={handleAddSubscriber}
            position="left"
          />
        </InfoSection>

        <SubscribersSection>
          <h3>{t(`${translationKey}.subscribers`)}</h3>
          {isFetchingPlanSubscriptions && <KoalaLoader />}
          {planSubscriptions?.length === 0 && <p className="subtle">{t(`${translationKey}.noSubscribers`)}</p>}
          {planSubscriptions?.map((subscriber: PlanSubscription) => {
            const { membership } = subscriber;
            return (
              <OwnerLine key={subscriber.id}>
                <NameContainer>
                  <OwnerOption className="active" data-plan-subscriber-id={subscriber.id} key={subscriber.id}>
                    <KoalaAvatar membership={membership} size={2.2} tooltipType="none" />
                    <span className="owner-option-name">{membership.cached_fullname ?? membership.cached_email}</span>
                  </OwnerOption>
                </NameContainer>
                <div data-tip data-for="remove-disabled">
                  <KoalaTextButton onClick={() => handleUnsubscribe(subscriber.id)}>
                    {t('shared.remove')}
                  </KoalaTextButton>
                </div>

                <ReactTooltip
                  place="bottom"
                  type="dark"
                  id="remove-disabled"
                  className="tooltip"
                  effect="solid"
                  delayShow={200}
                >
                  {t(`${translationKey}.removeSubscriber`)}
                </ReactTooltip>
              </OwnerLine>
            );
          })}
        </SubscribersSection>
      </ModalContent>
    </ModalGrid>
  );
}

export default PlanSubscribers;
