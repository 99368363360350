import React from 'react';
import styled from 'styled-components';

import UserSearchableDropdown from 'components/UserSearchableDropdown';
import KoalaAvatar from 'koala/components/Avatar';
import theme from 'theme';
import { Link } from 'react-router-dom';
import { Membership } from 'types';
import { useTranslation } from 'react-i18next';

const AddOwnerButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid transparent;

  &:hover {
    background: #d1d1d1;
    border: 2px solid ${theme.colors.blue};
  }
`;

interface Props {
  canEdit: boolean;
  selectedUser: Membership | null;
  handleAssignUser: (value: string) => void;
  handleUnassignUser?: () => void;
  size?: 'small' | 'medium';
  position?: 'left' | 'right';
  defaultLink?: string;
}

function UserSelectButton(props: Props) {
  const { canEdit, selectedUser, handleAssignUser, handleUnassignUser, size, position, defaultLink } = props;
  const { t } = useTranslation();
  const avatarSize = size === 'small' ? 2.2 : 2.4;

  const tooltipText = ` ${t('shared.clickToChange')}` ?? ' (Click to change)';
  const avatar = (
    <KoalaAvatar
      membership={selectedUser}
      size={avatarSize}
      addBorder={true}
      tooltipType={canEdit ? 'text' : 'card'}
      tooltipText={canEdit ? tooltipText : undefined}
    />
  );

  const handleLinkClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <>
      {!canEdit && defaultLink && (
        <Link to={defaultLink} onClick={handleLinkClick}>
          {avatar}
        </Link>
      )}
      {!canEdit && !defaultLink && avatar}
      {canEdit && (
        <UserSearchableDropdown
          position={position}
          width={21}
          trigger={<AddOwnerButton>{avatar}</AddOwnerButton>}
          callback={handleAssignUser}
          unassignCallback={avatar ? handleUnassignUser : undefined}
        />
      )}
    </>
  );
}

export default UserSelectButton;
