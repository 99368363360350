import React, { useEffect, useState, useRef, ChangeEvent } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import { useSelector, shallowEqual } from 'react-redux';
import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useLocation, useHistory } from 'react-router-dom';

// API
import * as remoteApi from 'api/remote';

// Components
import { PanelGrid, PanelHeader, PanelContent, PanelActions } from 'components/GlobalPanel';
import FormField from 'components/FormField';
import Loader from 'components/Loader';
import QuillBodyEditor from 'components/QuillBodyEditor';
import UserSearchableDropdown from 'components/UserSearchableDropdown';

import OutcomeContributors from './OutcomeContributors';
import DataSources from './DataSources';

import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaIconButton from 'koala/components/IconButton';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import KoalaAvatar from 'koala/components/Avatar';
import _, { toNumber } from 'lodash';
import OutcomeButtons from './OutcomeButtons';
import ExampleChart from './ExampleChart';
import TagPickerOutcome from 'components/TagPickerOutcome';
import KoalaTabs from 'koala/components/Tabs';
import RemoteSettingsModalContent from 'panels/EditOutcomeRemoteSettingsPanel/ModalContent';
import { useTranslation } from 'react-i18next';
import { CONTEXT_STORAGE_KEY } from 'config/constants';
import { AxiosResponse } from 'axios';

const ErrorText = styled.div`
  color: ${theme.colors.R60};
  font-size: 1.2rem;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon {
    transform: rotate(180deg);
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const AddPersonContainer = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    g {
      stroke: ${theme.colors.subtleText};
    }
  }
`;

const InlineItems = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

const OwnerOption = styled.div`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};

  border-radius: 8px;
  background: #f3f3f3;

  &:hover {
    cursor: pointer;
    background: #e7e7e7;
  }
  .owner-option-name {
    margin-left: ${theme.spacing.x2};
  }
`;

const TypeGroup = styled.div`
  display: flex;
  margin-bottom: ${theme.spacing.x2};
  gap: ${theme.spacing.x3};

  @media ${theme.devices.laptop} {
    flex-direction: column;
    max-height: unset;
  }
`;
const TypeLeftColumn = styled.div`
  flex: 3;
`;
const TypeRightColumn = styled.div`
  flex: 2;
  max-height: 260px;
  width: 500px;

  @media ${theme.devices.laptop} {
    flex-direction: column;
    width: 100%;
  }
`;

const ChartContainer = styled.div`
  padding: ${theme.spacing.x2};
  background: ${theme.colors.N3};
  border-radius: 4px;
`;

const TabsContainer = styled.div`
  padding: ${theme.spacing.x2} 0;
  border-top: 1px solid ${theme.colors.blockBorder};
`;

interface Props {
  outcomeId: string;
  selectedTab?: string;
}

function EditOutcomePanel(props: Props) {
  const { outcomeId, selectedTab } = props;
  const location = useLocation();
  const history = useHistory();
  const queryCache = useQueryCache();
  const inputEl: any = useRef(null);

  const defaultSelectedTab =
    selectedTab && ['target', 'advanced', 'data_source', 'api'].includes(selectedTab) ? selectedTab : 'target';
  const [currentTab, setCurrentTab] = useState(defaultSelectedTab);
  const { t } = useTranslation();
  const translationKey = 'panels.editOutcome';

  const currentMembership: TabilityTypes.Membership = useSelector(
    (state: any) => state.session.currentMembership,
    shallowEqual,
  );
  const currentUser: TabilityTypes.User = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  // Adds the current user to the membership to be able to display the user in the panel
  currentMembership.user = currentUser;

  // Params that will be used for the form
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [membership, setMembership]: [any, any] = useState(null);
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [weight, setWeight] = useState(1);
  const [scoreFormat, setScoreFormat]: any = useState(null);
  const [outcomeType, setOutcomeType] = useState('improve_metric');
  const [min, setMin] = useState('0');
  const [max, setMax] = useState('100');
  const [targetWithFormat, setTargetWithFormat] = useState('');
  const [startWithFormat, setStartWithFormat] = useState('');
  const [errors, setErrors]: any = useState(null);
  const [tags, setTags] = useState<string | null>(null);
  const storageKey = `${CONTEXT_STORAGE_KEY}_outcome_description_${outcomeId}`;

  // React to the ESC key to hide the panel
  useEffect(() => {
    // Handle ESC key to close the panel
    const handlePress = (e: any) => {
      if (e.keyCode === 27) {
        // Esc key
        e.preventDefault();
        history.goBack();
      }
    };
    document.addEventListener('keydown', handlePress, false);
    return () => document.removeEventListener('keydown', handlePress, false);
  }, [history, location.pathname]);

  useEffect(() => {
    debounceStoreText.current(description);
  }, [description]);

  const storeText = (newText: string) => {
    if (newText) {
      sessionStorage.setItem(storageKey, newText);
    }
  };

  const debounceStoreText = useRef(
    _.debounce((newText: string) => storeText(newText), 1000, {
      maxWait: 5000,
    }),
  );

  // Query keys and query params
  const queryKey = [queryKeys.currentOutcome, outcomeId];
  const staleTime = 0;

  // Functions to update the outcome
  const [updateOutcomeMutation, { isLoading: isUpdating }] = useMutation(remoteApi.updateOutcome, {
    onSuccess: (response: AxiosResponse<TabilityTypes.Outcome>, variables) => {
      sessionStorage.removeItem(storageKey);
      queryCache.invalidateQueries(queryKeys.outcomes);

      if (variables.ownerChanged) {
        setMembership(response.data.membership);
      } else {
        queryCache.invalidateQueries([queryKeys.currentOutcome, outcomeId]);
      }

      if (variables.dontGoBack) {
        // Don't close the panel if dontGoBack set to true
        return;
      }

      history.goBack();
    },
    onError: (error: any) => {
      const data: { [key: string]: string[] } = error.response.data;
      setErrors(data);
    },
  });

  // Get the outcome details
  const { data: outcomeResponse } = useQuery(queryKey, remoteApi.fetchOutcomeDetails, {
    staleTime,
    onSuccess: (response) => {
      // Set the params on success
      const outcome: TabilityTypes.Outcome = response.data;
      setTitle(outcome.title);
      const _description = sessionStorage.getItem(storageKey) ?? outcome.description;
      setDescription(_description ?? '');
      setMembership(outcome.membership);
      setFrom(outcome.from?.toString() ?? '');
      setTo(outcome.to?.toString() ?? '');
      setWeight(outcome.weight);
      setScoreFormat(outcome.score_format);
      setMin(outcome.y_axis_min?.toString() ?? '');
      setMax(outcome.y_axis_max?.toString() ?? '');
      setOutcomeType(outcome.outcome_type ?? 'improve_metric');
      setTags(outcome.cached_tag_list);

      if (outcome.outcome_type === 'kpi') {
        const _startWithFormat =
          outcome.score_format && outcome.from ? outcome.score_format.replace('_number_', `${outcome.from}`) : '';
        setStartWithFormat(_startWithFormat);
      } else {
        const _targetWithFormat =
          outcome.score_format && outcome.to !== null
            ? outcome.score_format.replace('_number_', outcome.to.toString())
            : '';
        setTargetWithFormat(_targetWithFormat);
      }
    },
  });

  const outcome: TabilityTypes.Outcome = outcomeResponse ? outcomeResponse.data : null;

  const handleTitleChange = (e: any) => {
    e.preventDefault();
    const title = e.target.value;
    setTitle(title);
  };

  // END SEARCH LOGIC

  const handleAssignOwner = (membership_id: string) => {
    const params = {
      membership_id,
    };

    updateOutcomeMutation({
      outcomeId,
      outcome: params,
      dontGoBack: true, // Don't close the panel after changing the owner.
      ownerChanged: true,
    });
  };

  // Save the outcome on tab change
  const handleTabClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const params = {
      title,
      description,
      from,
      to,
      weight,
      score_format: scoreFormat,
      outcome_type: outcomeType,
      y_axis_min: min,
      y_axis_max: max,
      tag_list: tags,
    };

    updateOutcomeMutation({
      outcomeId,
      outcome: params,
      dontGoBack: true, // Don't close the panel switching tab
    });
  };

  const handleRemoveOwner = (e: any) => {
    e.preventDefault();
    const params = {
      membership_id: null,
    };

    updateOutcomeMutation({
      outcomeId,
      outcome: params,
      dontGoBack: true, // Don't close the panel after changing the owner.
      ownerChanged: true,
    });
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    const params = {
      title,
      description,
      from,
      to,
      weight,
      score_format: scoreFormat,
      outcome_type: outcomeType,
      y_axis_min: min,
      y_axis_max: max,
      tag_list: tags,
    };

    updateOutcomeMutation({
      outcomeId,
      outcome: params,
    });
  };

  const handleSetOutcomeType = (value: string) => {
    setOutcomeType(value);
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    history.goBack();
  };

  const parseValueWithFormat = (valueWithFormat: string) => {
    // Here we're parsing the valueWithFormat input to extract the format
    // and the actual target.
    const targetRegex = /^[^-0-9]*((?:-{0,1})\d+(?:,{1}\d+)*(?:\.{1}\d+){0,1}).*$/;

    const matches = valueWithFormat.match(targetRegex);

    let target, score_format;
    if (matches) {
      target = matches[1];
      score_format = matches[0].replace(target, '_number_');
      target = target.replace(',', '');
      target = parseFloat(target);
    } else {
      target = null;
      score_format = null;
    }

    return { target, score_format };
  };

  const addKpiTag = () => {
    const hasKpiTag = tags ? tags.includes('KPI') : false;
    if (!hasKpiTag) {
      const newTags = [tags, 'KPI'].filter(Boolean).join(', ');
      setTags(newTags);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputTarget = e.target;
    const value = inputTarget.value;
    const name = inputTarget.name;
    const numberRe = new RegExp('(?![0-9-+.,]).');

    switch (name) {
      case 'targetWithFormat':
        const targetResult = parseValueWithFormat(value);
        setTargetWithFormat(value);
        setScoreFormat(targetResult.score_format);
        setTo(targetResult.target !== null ? targetResult.target.toString() : '');
        break;
      case 'startWithFormat':
        const startResult = parseValueWithFormat(value);
        setStartWithFormat(value);
        setScoreFormat(startResult.score_format);
        setFrom(startResult.target !== null ? startResult.target.toString() : '');
        break;
      case 'improveStart':
        if (!numberRe.exec(value)) {
          setFrom(value);
        }
        break;
      case 'minYAxis':
        if (!numberRe.exec(value)) {
          setMin(value);
        }
        break;
      case 'maxYAxis':
        if (!numberRe.exec(value)) {
          setMax(value);
        }
        break;
    }
  };

  // Now we can display the app with the Chrome
  if (!outcome) {
    return (
      <LoadingContainer>
        <Loader size="big" />
      </LoadingContainer>
    );
  }

  let valueWithFormatLabel;
  switch (outcomeType) {
    case 'improve_metric':
      valueWithFormatLabel = 'Target';
      break;
    case 'stay_above':
      valueWithFormatLabel = 'Stay above';
      break;
    case 'stay_below':
      valueWithFormatLabel = 'Stay below';
      break;
    default:
      break;
  }

  let prefix = null,
    suffix = null;
  if (scoreFormat) {
    [prefix, suffix] = scoreFormat.split('_number_');
  }

  const newOutcome: TabilityTypes.Outcome = {
    ...outcome,
    to: to === '' ? null : toNumber(to),
    from: from === '' ? null : toNumber(from),
    outcome_type: outcomeType,
    y_axis_min: min === '' ? 0 : toNumber(min),
    y_axis_max: max === '' ? 10 : toNumber(max),
    cached_tag_list: tags,
  };

  const showMinMax = outcomeType === 'stay_above' || outcomeType === 'stay_below' || outcomeType === 'kpi';

  const hasKpiTag = tags ? tags.includes('KPI') : false;
  const outcomeLabel = translate(currentWorkspace, CustomTermKey.OUTCOME, 1);
  const outcomePlaceholder =
    t(`${translationKey}.outcomePlaceholder`, { label: outcomeLabel }) ?? `Write a measurable ${outcomeLabel}`;
  const valuePlaceholder = t(`${translationKey}.placeholder`) ?? `ex: $150/user, NPS 80, 40 demos...`;
  return (
    <PanelGrid>
      <PanelHeader>
        <Header>
          <h2>{t('panels.editTitle', { label: outcomeLabel })}</h2>
          <KoalaIconButton onClick={handleCancel} iconName="close" />
        </Header>
      </PanelHeader>
      <PanelContent>
        <form onSubmit={handleSave}>
          <FormField>
            <label>{t('panels.title')}</label>
            <input
              type="text"
              value={title}
              ref={inputEl}
              onChange={handleTitleChange}
              placeholder={outcomePlaceholder}
            />
          </FormField>
          <FormField>
            <label>{t('panels.owner')}</label>
            <InlineItems>
              <UserSearchableDropdown
                position="left"
                trigger={
                  <OwnerOption className="active not-assigned">
                    <AddPersonContainer>
                      <KoalaAvatar membership={membership} size={2.4} tooltipType="none" />
                    </AddPersonContainer>
                    <span className="owner-option-name">
                      {membership ? membership.user.fullname || membership.user.email : t('shared.notAssigned')}
                    </span>
                  </OwnerOption>
                }
                callback={handleAssignOwner}
              />
              {membership && (
                <KoalaButton onClick={handleRemoveOwner} type="button" appearance="subtle">
                  {t('shared.userSelect.unassign')}
                </KoalaButton>
              )}
            </InlineItems>
          </FormField>
          <OutcomeContributors outcomeId={outcomeId} />

          <KoalaTabs
            activeTab={currentTab}
            setActiveTab={setCurrentTab}
            onClick={handleTabClick}
            tabs={[
              {
                key: 'target',
                label: t(`${translationKey}.target`),
              },
              {
                key: 'advanced',
                label: t(`${translationKey}.advanced`),
              },
              {
                key: 'data_source',
                label: t(`${translationKey}.dataSource`),
              },
              {
                key: 'api',
                label: t(`${translationKey}.api`),
              },
            ]}
          />
          <TabsContainer>
            {currentTab === 'target' && (
              <TypeGroup>
                <TypeLeftColumn>
                  <FormField>
                    <label>{t(`${translationKey}.type`, { label: outcomeLabel })} </label>
                    <OutcomeButtons type={outcomeType} setType={(value) => handleSetOutcomeType(value)} />
                    <ErrorText>
                      {errors && errors['outcome_type']
                        ? t(`${translationKey}.typeRequired`, { label: outcomeLabel })
                        : ''}
                    </ErrorText>
                  </FormField>
                  <FormField>
                    {outcomeType === 'improve_metric' && (
                      <small className="subtle">{t(`${translationKey}.improve`)}</small>
                    )}
                    {outcomeType === 'no_metric' && <small className="subtle">{t(`${translationKey}.noMetric`)}</small>}
                    {outcomeType === 'stay_above' && (
                      <small className="subtle">{t(`${translationKey}.stayAbove`)}</small>
                    )}
                    {outcomeType === 'stay_below' && (
                      <small className="subtle">{t(`${translationKey}.stayBelow`)}</small>
                    )}
                    {outcomeType === 'kpi' && <small className="subtle">{t(`${translationKey}.kpi`)}</small>}
                  </FormField>
                  {(outcomeType === 'improve_metric' ||
                    outcomeType === 'stay_above' ||
                    outcomeType === 'stay_below') && (
                    <FormField>
                      <label>{valueWithFormatLabel}</label>
                      <input
                        placeholder={valuePlaceholder}
                        value={targetWithFormat}
                        autoComplete="off"
                        name="targetWithFormat"
                        onChange={handleChange}
                        className="small"
                      />
                      <ErrorText>
                        {errors && errors['to']
                          ? t(`${translationKey}.valueRequired`, { label: valueWithFormatLabel })
                          : ''}
                      </ErrorText>
                      <small className="subtle" style={{ marginTop: theme.spacing.x1 }}>
                        {t(`${translationKey}.customFormat`)}
                      </small>
                    </FormField>
                  )}
                  {outcomeType === 'kpi' && (
                    <FormField>
                      <label>{t(`${translationKey}.startingAt`)}</label>
                      <input
                        placeholder={valuePlaceholder}
                        value={startWithFormat}
                        autoComplete="off"
                        name="startWithFormat"
                        onChange={handleChange}
                        className="small"
                      />
                      <ErrorText>{errors && errors['from'] && t(`${translationKey}.startValueRequired`)}</ErrorText>
                      <small className="subtle" style={{ marginTop: theme.spacing.x1 }}>
                        {t(`${translationKey}.customFormat`)}
                      </small>
                    </FormField>
                  )}
                  {outcomeType === 'improve_metric' && (
                    <FormField>
                      <label>{t(`${translationKey}.startingAt`)}</label>
                      <div>
                        {prefix && <span className="prefix">&nbsp;&nbsp;{prefix}&nbsp;</span>}
                        <input
                          value={from ?? undefined}
                          autoComplete="off"
                          name="improveStart"
                          onChange={handleChange}
                          className="small"
                          pattern="(-)*([0-9])+(.)*([0-9])+"
                          title={t(`${translationKey}.startingTitlePlaceholder`) ?? 'Please enter a valid number'}
                        />
                        {suffix && <span className="suffix">&nbsp;{suffix}</span>}
                      </div>
                      <ErrorText>
                        {errors &&
                          errors['from'] &&
                          (t(`${translationKey}.startValueRequired`) ?? `Starting value is required`)}
                      </ErrorText>
                    </FormField>
                  )}
                  {showMinMax && (
                    <>
                      <FormField>
                        <label>{t(`${translationKey}.minValue`)}</label>
                        {prefix && <span className="prefix">&nbsp;&nbsp;{prefix}&nbsp;</span>}
                        <input
                          placeholder={t(`${translationKey}.minPlaceholder`) ?? `min`}
                          value={min ?? undefined}
                          autoComplete="off"
                          name="minYAxis"
                          onChange={handleChange}
                          className="small"
                        />
                        {suffix && <span className="suffix">&nbsp;{suffix}</span>}
                        <ErrorText>
                          {errors &&
                            errors['y_axis_min'] &&
                            (t(`${translationKey}.minError`, { error: errors['y_axis_min'] }) ??
                              `Min value ${errors['y_axis_min']}`)}
                        </ErrorText>
                      </FormField>
                      <FormField>
                        <label>{t(`${translationKey}.maxValue`)}</label>
                        {prefix && <span className="prefix">&nbsp;&nbsp;{prefix}&nbsp;</span>}
                        <input
                          placeholder={t(`${translationKey}.maxPlaceholder`) ?? `max`}
                          value={max ?? undefined}
                          autoComplete="off"
                          name="maxYAxis"
                          onChange={handleChange}
                          className="small"
                        />
                        {suffix && <span className="suffix">&nbsp;{suffix}</span>}
                        <ErrorText>
                          {errors &&
                            errors['y_axis_max'] &&
                            (t(`${translationKey}.maxError`, { error: errors['y_axis_max'] }) ??
                              `Max value ${errors['y_axis_max']}`)}
                        </ErrorText>
                      </FormField>
                    </>
                  )}
                </TypeLeftColumn>
                <TypeRightColumn>
                  <FormField>
                    <ChartContainer>
                      <label>{t(`${translationKey}.exampleChart`)}</label>
                      <ExampleChart newOutcome={newOutcome} />
                    </ChartContainer>
                  </FormField>
                </TypeRightColumn>
              </TypeGroup>
            )}
            {currentTab === 'advanced' && (
              <TypeGroup>
                <TypeLeftColumn>
                  <FormField>
                    <label>{t('panels.description')}</label>
                    <QuillBodyEditor
                      value={description}
                      disableAutoFocus={true}
                      onChange={setDescription}
                      placeholder={
                        t('panels.descriptionPlaceholder', { label: outcomeLabel }) ?? `About this ${outcomeLabel}`
                      }
                      disableMentions={true}
                      quillClassName="ql-notes"
                    />
                  </FormField>
                  <FormField>
                    <label>{t('shared.tags')}</label>
                    <InlineItems>
                      <TagPickerOutcome outcome={newOutcome} />
                      {!hasKpiTag && (
                        <KoalaButton onClick={addKpiTag} size="small" appearance="subtle">
                          {t(`${translationKey}.kpiTag`)}
                        </KoalaButton>
                      )}
                    </InlineItems>
                  </FormField>
                  <FormField>
                    <label>{t(`${translationKey}.weight`)}</label>
                    <select
                      value={weight}
                      onChange={(e: ChangeEvent<HTMLSelectElement>) => setWeight(parseInt(e.target.value))}
                      className="micro"
                    >
                      <option value={0}>0x ({t(`${translationKey}.ignore`)})</option>
                      <option value={1}>1x</option>
                      <option value={2}>2x</option>
                      <option value={3}>3x</option>
                    </select>
                  </FormField>
                </TypeLeftColumn>
                <TypeRightColumn></TypeRightColumn>
              </TypeGroup>
            )}
            {currentTab === 'data_source' && (
              <TypeGroup>
                <TypeLeftColumn>
                  <DataSources outcome={outcome} />
                </TypeLeftColumn>
                <TypeRightColumn />
              </TypeGroup>
            )}
            {currentTab === 'api' && (
              <TypeGroup>
                <TypeLeftColumn>
                  <RemoteSettingsModalContent outcome={outcome} />
                </TypeLeftColumn>
                <TypeRightColumn />
              </TypeGroup>
            )}
          </TabsContainer>
        </form>
      </PanelContent>

      <PanelActions>
        <KoalaButton submit onClick={handleSave} disabled={isUpdating} loading={isUpdating}>
          {t('shared.save')}
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
      </PanelActions>
    </PanelGrid>
  );
}

export default React.memo(EditOutcomePanel);
