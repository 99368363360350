import { Initiative, IntegrationUser } from 'types';

import IconAsanaPng from 'components/_assets/IconAsana.png';
import IconJiraPng from 'components/_assets/IconJira.png';
import IconClickupPng from 'components/_assets/IconClickup.png';
import IconLinearPng from 'components/_assets/IconLinear.png';

export const jiraToIntegrationUser = (integrationMeta: { fields: { assignee: any } }) => {
  const assignee = integrationMeta.fields.assignee;
  if (!assignee) {
    return null;
  }
  const avatarImage =
    assignee.avatarUrls && assignee.avatarUrls['48x48'] ? `url(${assignee.avatarUrls['48x48']})` : null;
  const name = assignee.displayName ?? 'Not assigned';
  const hash =
    assignee.accountId.charCodeAt(0) +
    assignee.accountId.charCodeAt(10) +
    assignee.accountId.charCodeAt(1) +
    assignee.accountId.charCodeAt(2);
  const initials = name
    ? name
        .trim()
        .split(' ')
        .map((x: string) => x.charAt(0).toUpperCase())
        .slice(0, 2)
        .join('')
    : '';

  const bgVariants = ['purple', 'red', 'green', 'yellow', 'blue', 'orange', 'teal'] as const;
  const color = bgVariants[hash % bgVariants.length];
  const user: IntegrationUser = { avatarImage, name, initials, color };
  return user;
};

export const clickupToIntegrationUser = (integrationMeta: any) => {
  if (!integrationMeta.assignees || !integrationMeta.status) {
    return null;
  }
  const assignee = integrationMeta.assignees[0];
  if (!assignee) {
    return null;
  }
  const avatarImage = assignee.profilePicture ? `url(${assignee.profilePicture})` : null;
  const name = assignee.username;
  const initials = !avatarImage && assignee.initials;
  const color = assignee.color;

  const user: IntegrationUser = { avatarImage, name, initials, color };
  return user;
};

export const asanaToIntegrationUser = (integrationMeta: any) => {
  if (!integrationMeta.data) {
    return null;
  }
  const assignee = integrationMeta.data.assignee;
  if (!assignee) {
    return null;
  }
  const name = assignee.name ?? 'Not assigned';
  const hash =
    assignee.gid.charCodeAt(0) + assignee.gid.charCodeAt(10) + assignee.gid.charCodeAt(1) + assignee.gid.charCodeAt(2);
  const initials = name
    ? name
        .trim()
        .split(' ')
        .map((x: string) => x.charAt(0).toUpperCase())
        .slice(0, 2)
        .join('')
    : '';

  const bgVariants = ['purple', 'red', 'green', 'yellow', 'blue', 'orange', 'teal'] as const;
  const color = bgVariants[hash % bgVariants.length];
  const avatarImage = null;

  const user: IntegrationUser = { avatarImage, name, initials, color };
  return user;
};

export const getIntegrationUser = (initiative: Initiative) => {
  switch (initiative.integration_type) {
    case 'clickup':
      return clickupToIntegrationUser(initiative.integration_meta);
    case 'jira_cloud':
      return jiraToIntegrationUser(initiative.integration_meta);
    case 'asana':
      return asanaToIntegrationUser(initiative.integration_meta);
    default:
      return null;
  }
};

export const isIntegrationClosed = (initiative: Initiative): boolean => {
  const { integration_type, integration_meta } = initiative;
  if (integration_type === 'clickup' && integration_meta.status) {
    return integration_meta.status.type === 'closed';
  }
  if (integration_type === 'asana' && integration_meta.data) {
    return integration_meta.data.completed;
  }
  if (integration_type === 'jira_cloud') {
    return integration_meta.fields.status.statusCategory.key === 'done';
  }
  if (integration_type === 'linear') {
    return initiative.state === 'closed';
  }

  return false;
};

export const getIntegrationStatus = (initiative: Initiative) => {
  const { integration_type, integration_meta } = initiative;
  if (integration_type === 'clickup' && integration_meta.status) {
    return integration_meta.status.status;
  }
  if (integration_type === 'asana' && integration_meta.data) {
    return integration_meta.data?.memberships[0]?.section?.name ?? '-';
  }
  if (integration_type === 'jira_cloud') {
    return integration_meta.fields.status.name || integration_meta.fields.status.statusCategory.name;
  }
  if (integration_type === 'linear') {
    // Linear uses state for issues, and status for projects
    if (integration_meta.status && integration_meta.status.name) {
      return integration_meta.status.name;
    }

    if (integration_meta.state && integration_meta.state.name) {
      return integration_meta.state.name;
    }
  }

  return 'To-do';
};

export const getIntegrationName = (integrationType: string) => {
  switch (integrationType) {
    case 'jira_cloud':
      return 'Jira';
    case 'clickup':
      return 'Clickup';
    case 'asana':
      return 'Asana';
    case 'linear':
      return 'Linear';
    default:
      return 'Tability';
  }
};

export const getIntegrationType = (initiative: Initiative) => {
  if (initiative.integration_type === 'jira_cloud' && initiative.integration_remote_id) {
    return 'Jira';
  } else if (
    initiative.integration_type === 'clickup' &&
    initiative.integration_remote_id &&
    initiative.integration_meta.status
  ) {
    return 'Clickup';
  } else if (initiative.integration_type === 'asana' && initiative.integration_remote_id) {
    return 'Asana';
  } else if (initiative.integration_type === 'linear' && initiative.integration_remote_id) {
    return 'Linear';
  } else {
    return 'Tability';
  }
};

export const getIntegrationImageSource = (integrationType: string) => {
  switch (integrationType) {
    case 'jira_cloud':
      return IconJiraPng;
    case 'clickup':
      return IconClickupPng;
    case 'asana':
      return IconAsanaPng;
    case 'linear':
      return IconLinearPng;
    default:
      return null;
  }
};

export const getDueDate = (initiative: Initiative) => {
  const { integration_type, integration_meta } = initiative;
  let dueDate: Date | null = null;
  switch (integration_type) {
    case 'jira_cloud':
      dueDate = integration_meta?.fields?.duedate ? new Date(integration_meta.fields.duedate) : null;
      break;
    case 'clickup':
      dueDate = integration_meta.due_date ? new Date(parseInt(integration_meta.due_date)) : null;
      break;
    case 'asana':
      dueDate = integration_meta.data.due_on ? new Date(integration_meta.data.due_on) : null;
      break;
    case 'linear':
      dueDate = initiative.due_at ? new Date(initiative.due_at) : null;
      break;
    default:
      break;
  }

  if (dueDate) {
    return dueDate.toDateString();
  }
};
