import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';

// API
import * as remoteApi from 'api/remote';

// Actions
import { sessionActions } from 'state/actions/';

// Components
import Loader from 'components/Loader';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;

  a {
    text-decoration: underline;
  }
`;

function AuthenticateGoogle() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation(undefined, { useSuspense: false });

  const [authenticateWithGoogleMutation, { isError, isFetching, isSuccess }]: [any, any] = useMutation(
    remoteApi.authenticateWithGoogle,
    {
      onSuccess: (response) => {
        dispatch(sessionActions.loginCompleted(response));
      },
    },
  );

  const params = new URLSearchParams(location.search);

  const code = params.get('code');

  useEffect(() => {
    if (code && window.opener) {
      window.opener.postMessage({ code }, `https://${process.env.REACT_APP_DOMAIN}`);
      window.close();
    } else {
      if (code) {
        const body = {
          code,
        };
        authenticateWithGoogleMutation(body);
      }
    }
  }, [code, authenticateWithGoogleMutation]);

  return (
    <Container>
      {isFetching && <Loader />}
      {isSuccess && <p>{t('authenticateGoogle.redirect')}</p>}
      {isError && (
        <Fragment>
          <p>{t('authenticateGoogle.error')}</p>
          <p>
            <Link to="/">{t('authenticateGoogle.back')}</Link>
          </p>
        </Fragment>
      )}
    </Container>
  );
}

export default AuthenticateGoogle;
