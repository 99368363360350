import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import Emoji from 'emoji-mart/dist-es/components/emoji/emoji';

import NotificationLayout, { Gutter, Header, Meta, Content, Footer } from './NotificationLayout';
import OutcomeBlockCompact from './OutcomeBlockCompact';
import MarkdownContent from 'components/MarkdownContent';
import KoalaAvatar from 'koala/components/Avatar';
import * as reactionUtils from 'utils/reactionUtils';
import { User, Workspace } from 'types';
import { Outcome } from 'types';
import { Trans, useTranslation } from 'react-i18next';
import { formatDistanceToNowLocale } from 'utils/dateUtils';

const StyledNotificationLayout = styled(NotificationLayout)`
  &.notification-unread {
  }
`;

const TabbyAvatar = styled.img<{ size: number }>`
  width: ${(props) => props.size}rem;
  position: relative;
  bottom: 4px;
`;

const SeenBadge = styled.div`
  height: 1.2rem;
  width: 1.2rem;
  background: transparent;
  border-radius: 50%;

  &.notification-unseen {
    background: ${theme.colors.red};
  }
`;

const Comment = styled.div`
  color: #373737;
  padding: 0 ${theme.spacing.x1};
  margin-top: ${theme.spacing.x1};
  border-radius: 4px;
  flex: 1;
`;

interface Props {
  notification: any;
}

function Panel(props: Props) {
  const history = useHistory();
  const { notification } = props;
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { t, i18n } = useTranslation();
  const { source, source_membership } = notification;
  let user: User | null = null;

  if (source_membership) {
    user = source_membership.user;
  }

  const handleClick = () => {
    history.push(notification.path);
  };

  const readClass = notification.read ? 'notification-read' : 'notification-unread';
  const seenClass = notification.seen ? 'notification-seen' : 'notification-unseen';

  let outcome: Outcome | null = null;
  if (source && source.outcome) {
    outcome = source.outcome;
  }

  let header: JSX.Element = <></>;

  if (user) {
    header = notification.subject.replace('{{user.fullname}}', user.fullname);
  } else {
    header = notification.subject;
  }
  if (notification.notification_type === 'reaction.created') {
    if (!source) {
      return <></>;
    }
    const reactableType = reactionUtils.reactableTypeToString(source.reactable_type, currentWorkspace, t);
    if (user) {
      header = (
        <Trans
          i18nKey={'components.workspaceHeader.reactableHeader'}
          components={{ emoji: <Emoji emoji={source.emotion} size={16} set="twitter" /> }}
          values={{ name: user.fullname, type: reactableType }}
        />
      );
    }
  }
  return (
    <StyledNotificationLayout onClick={handleClick} className={readClass}>
      <Gutter>
        {!user && (
          <TabbyAvatar
            src="https://res.cloudinary.com/tability/image/upload/v1600083943/static_assets/tabby_happy_ldzb8v.png"
            alt="Tabby"
            size={2.4}
          />
        )}
        {user && <KoalaAvatar membership={source_membership} size={2.4} tooltipType="card" />}
      </Gutter>
      <Header>{header}</Header>
      <Meta>
        <SeenBadge className={seenClass} />
      </Meta>
      <Content>
        {outcome && <OutcomeBlockCompact outcome={outcome} />}
        {notification.body && (
          <Comment className="comment">
            <MarkdownContent source={notification.body} />
          </Comment>
        )}
      </Content>
      <Footer>
        <div>{formatDistanceToNowLocale(notification.updated_at, i18n, true)}</div>
      </Footer>
    </StyledNotificationLayout>
  );
}

export default Panel;
