import KoalaIcon from 'koala/components/Icons';
import React, { ReactNode, useRef } from 'react';
import { useDrag } from 'react-dnd';
import styled from 'styled-components';
import theme from 'theme';
import { Objective, Outcome } from 'types';

const Block = styled.div<{ isDragging: boolean }>`
  background-color: ${theme.colors.N5};
  border-radius: 4px;
  min-height: 36px;
  padding: ${theme.spacing.x1};
  display: flex;
  align-items: center;
  cursor: grab;
`;
interface Props {
  children: ReactNode;
  saveDrag: (id: string, index: number, sectionObject?: Objective | Outcome) => void;
  id: string;
  index: number;
  sectionObject?: Objective | Outcome;
  order: string[];
}

function ElementBlock(props: Props) {
  const { saveDrag, id, index, children, sectionObject, order } = props;
  const ref = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag] = useDrag({
    type: 'new-section',
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      // if new item is in in order list, then save the move
      const sectionIndex = order.findIndex((c) => `${c}` === id);
      if (sectionIndex >= 0) {
        saveDrag(item.id, item.index, sectionObject);
      }
    },
  });

  drag(ref);
  return (
    <Block ref={ref} isDragging={isDragging}>
      <KoalaIcon iconName="grab" iconAppearance={theme.colors.N40} />
      {children}
    </Block>
  );
}

export default React.memo(ElementBlock);
