import MarkdownContent from 'components/MarkdownContent';
import KoalaAvatar from 'koala/components/Avatar';
import KoalaTextBadge from 'koala/components/TextBadge';
import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Checkin, Outcome } from 'types';
import { checkinScore } from 'utils/checkinUtils';
import * as checkinUtils from 'utils/checkinUtils';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  grid-template-areas:
    'gutter header'
    'gutter content';
  padding: ${theme.spacing.x2};

  margin-bottom: ${theme.spacing.x2};
  width: 100%;

  background: ${theme.colors.N0};
  border-radius: 8px;
`;

const Gutter = styled.div`
  grid-area: gutter;
  display: flex;
  justify-content: flex-start;
  margin-right: ${theme.spacing.x2};
  width: 32px;
  .avatar-image > div {
    position: relative;
    transform: scale(0.25);
    margin: -48px;
  }
`;

const Header = styled.div`
  grid-area: header;
  display: flex;
  align-items: center;
  height: 2rem;
  margin-bottom: ${theme.spacing.half};

  span {
    line-height: 2rem;
    align-items: center;
  }
`;
const Content = styled.div`
  grid-area: content;
`;

interface Props {
  checkin: Checkin;
  outcome: Outcome;
}
function CheckinDetails(props: Props) {
  const { checkin, outcome } = props;
  const { t } = useTranslation();

  const formattedScore = checkin ? checkinScore(checkin, outcome, t) : 'Pending';
  return (
    <Grid>
      <Gutter>
        <KoalaAvatar membership={checkin.membership} size={12.8} className="avatar-image" tooltipType="none" />
      </Gutter>
      <Header>
        <KoalaTextBadge
          isLowercase={true}
          variant={checkinUtils.confidenceToVariant(checkin.confidence)}
          edge="circle"
          style={{ border: 'none' }}
          dataFor={`progress-checkin-${checkin.id}`}
        >
          {formattedScore}
        </KoalaTextBadge>
        <ReactTooltip
          place="bottom"
          type="dark"
          className="tooltip"
          effect="solid"
          id={`progress-checkin-${checkin.id}`}
        >
          {formattedScore}
        </ReactTooltip>
      </Header>
      <Content>
        {checkin.body && <MarkdownContent source={checkin.body} />}
        {!checkin.body && (
          <p className="subtle">
            <em>{t('shared.noComment')}</em>
          </p>
        )}
        {checkin.is_remote && (
          <p className="subtle">
            --
            <br />
            <em>{t('publicPlan.remote')}</em>
          </p>
        )}
      </Content>
    </Grid>
  );
}

export default CheckinDetails;
