import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import { useDispatch } from 'react-redux';
import { ModalGrid, ModalHeader, ModalContent } from 'components/GlobalModal';
import { TeamMember } from 'types';
import { useQuery, useMutation, useQueryCache } from 'react-query';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';

import { setGlobalModalContent } from 'state/actions/globalUIActions';

import AddPersonIcon from 'components/_assets/AddPersonIcon';
import UserSearchableDropdown from 'components/UserSearchableDropdown';

import KoalaIconButton from 'koala/components/IconButton';
import Loader from 'components/Loader';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaAvatar from 'koala/components/Avatar';
import { useTranslation } from 'react-i18next';

const OwnerLine = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  &.header {
    margin-bottom: ${theme.spacing.x1};
  }
`;

const NameContainer = styled.div`
  min-width: 30rem;
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

const OwnerOption = styled.div`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};

  border-radius: 8px;

  &:hover {
    cursor: pointer;
    background: #f3f3f3;
  }
  .owner-option-name {
    margin-left: ${theme.spacing.x2};
  }

  &.active {
    background: #f3f3f3;
    &:hover {
      cursor: initial;
      background: #f3f3f3;
    }
  }

  &.not-assigned {
    opacity: 0.5;
  }

  &.option-button {
    background: #f3f3f3;
    svg {
      height: 1.8rem;
      g {
        stroke: ${theme.colors.subtleText};
      }
    }
    &:hover {
      cursor: pointer;
      background: #e7e7e7;
    }
  }
`;

const PeopleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
`;
interface Props {
  teamId: string;
}

function TeamMembers(props: Props) {
  const { teamId } = props;
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const translationKey = 'modals.editTeamMembers';

  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);

  const teamMembersQueryKey = [queryKeys.teamMembers, teamId];
  const staleTime = 0;
  const { isLoading } = useQuery(teamMembersQueryKey, remoteApi.fetchTeamMembers, {
    staleTime,
    onSuccess: (response) => {
      setTeamMembers(response.data);
    },
  });

  const [addTeamMemberMutation]: [any, any] = useMutation(remoteApi.createTeamMember, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.teamMembers);
      queryCache.invalidateQueries(queryKeys.currentTeam);
    },
  });

  const [deleteTeamMemberMutation, { isLoading: isDeletingTeamMember }]: [any, any] = useMutation(
    remoteApi.deleteTeamMember,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKeys.teamMembers);
        queryCache.invalidateQueries(queryKeys.currentTeam);
      },
    },
  );

  const handleDeleteTeamMembership = (teamMemberId: string) => {
    deleteTeamMemberMutation(teamMemberId);
  };

  const handleAssignUser = (value: string) => {
    const membership_id = value;
    if (membership_id) {
      const params = {
        membership_id,
      };

      addTeamMemberMutation({
        teamId: teamId,
        teamMember: params,
      });
    }
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  if (isLoading) {
    return (
      <ModalGrid>
        <ModalHeader>
          <h2>{t(`${translationKey}.title`)}</h2>
          <KoalaIconButton iconName="close" onClick={handleCancel} />
        </ModalHeader>
        <ModalContent>
          <Loader size="big" />
        </ModalContent>
      </ModalGrid>
    );
  }

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`${translationKey}.title`)}</h2>
        <KoalaIconButton iconName="close" onClick={handleCancel} />
      </ModalHeader>
      <ModalContent>
        <PeopleContainer>
          <div>
            <div>
              <UserSearchableDropdown
                trigger={
                  <OwnerOption className="active option-button">
                    <AddPersonIcon /> <span className="owner-option-name">{t(`${translationKey}.addTeam`)}</span>
                  </OwnerOption>
                }
                callback={handleAssignUser}
                position="left"
              />
            </div>
          </div>
          {teamMembers.map((teamMember: TeamMember) => {
            const { membership } = teamMember;
            return (
              <OwnerLine key={teamMember.id}>
                <NameContainer>
                  <OwnerOption className="active">
                    <KoalaAvatar membership={membership} size={2.2} tooltipType="none" />
                    <span className="owner-option-name">{membership.cached_fullname ?? membership.cached_email}</span>
                  </OwnerOption>
                </NameContainer>

                <div data-tip data-for="remove-disabled">
                  <KoalaTextButton onClick={() => handleDeleteTeamMembership(teamMember.id)}>
                    {t('shared.remove')}
                  </KoalaTextButton>
                </div>
                {isDeletingTeamMember && <Loader />}
              </OwnerLine>
            );
          })}
        </PeopleContainer>
      </ModalContent>
    </ModalGrid>
  );
}

export default React.memo(TeamMembers);
