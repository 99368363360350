import React, { useState } from 'react';
import * as TabilityTypes from 'types';

import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import FormField from 'components/FormField';
import KoalaButton from 'koala/components/Button';
import KoalaLoader from 'koala/components/Loader';

interface CurrentValueProps {
  outcome: TabilityTypes.Outcome;
}

function CurrentValue(props: CurrentValueProps) {
  const { outcome } = props;

  // Query keys and query params
  const queryKey = [
    queryKeys.currentOutcome,
    outcome.id,
    `data_source:dependencies:${outcome.data_source_type}`,
    'current',
  ];
  const staleTime = 300;

  const { data, isLoading } = useQuery(queryKey, remoteApi.fetchOutcomeDataSourceCurrentValue, {
    staleTime,
  });

  if (isLoading) {
    return (
      <FormField>
        <KoalaLoader />
      </FormField>
    );
  }

  const currentValue = data?.data.result;

  if (currentValue === null) {
    return <FormField>Sorry, an error occurred while fetching the current value.</FormField>;
  }

  return (
    <FormField>
      <label>Preview value</label>
      <p>{currentValue.toLocaleString()}</p>
    </FormField>
  );
}

interface Props {
  outcome: TabilityTypes.Outcome;
}

function DependenciesSource(props: Props) {
  const { outcome } = props;
  const [data_source_type, setDataSourceType] = useState(outcome.data_source_type || 'sum');

  const queryCache = useQueryCache();

  // Functions to update the outcome
  const [updateOutcomeDataSourceMutation, { isLoading: isUpdating }]: [any, any] = useMutation(
    remoteApi.updateOutcomeDataSource,
    {
      onSuccess: (response) => {
        queryCache.invalidateQueries(queryKeys.currentOutcome);
      },
    },
  );

  const handleUpdate = (e: any) => {
    e.preventDefault();
    let body = {
      data_source_origin: 'dependencies',
      data_source_type,
    };

    const mutationParams = {
      outcomeId: outcome.nano_slug,
      body,
    };
    updateOutcomeDataSourceMutation(mutationParams);
  };

  const availableDataSourceTypes = ['sum', 'avg', 'latest'];

  return (
    <>
      <FormField>
        <label>Select aggregation type</label>
        <select value={data_source_type} onChange={(e) => setDataSourceType(e.currentTarget.value)}>
          <option value="">Select an option...</option>
          <option value="sum">Sum</option>
          <option value="avg">Average</option>
          <option value="latest">Latest</option>
        </select>
      </FormField>
      {outcome.data_source_type !== data_source_type && (
        <FormField>
          <KoalaButton
            onClick={handleUpdate}
            loading={isUpdating}
            disabled={!data_source_type || isUpdating}
            appearance="subtle"
          >
            Save & preview
          </KoalaButton>
        </FormField>
      )}
      {outcome.data_source_origin === 'dependencies' &&
        outcome.data_source_type &&
        outcome.data_source_type === data_source_type && (
          <>{availableDataSourceTypes.includes(outcome.data_source_type) && <CurrentValue outcome={outcome} />}</>
        )}
    </>
  );
}

export default React.memo(DependenciesSource);
