/**
  This is the playground page. It's used to try out the editor and the different templates.
  State is saved in local storage, but you can only do that for one plan.

  The editor piece should be portable and be able to plug itself onto local storage OR
  onto the remote API (DB) once it'll be plugged into the app.
 */

import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Helmet } from 'react-helmet';
import * as TabilityTypes from 'types';
import * as amplitudeUtils from 'utils/amplitude';
import * as membershipUtils from 'utils/membershipUtils';
import * as workspaceUtils from 'utils/workspaceUtils';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import download from 'downloadjs';
import { Base64 } from 'js-base64';

import { useInfiniteQuery, useQuery, useMutation } from 'react-query';
import queryKeys from 'config/queryKeys';
import parse from 'parse-link-header';
import { format } from 'date-fns';
import * as routes from 'routes';
import { useHistory } from 'react-router-dom';

// API
import * as remoteApi from 'api/remote';
import { setGlobalModalContent } from 'state/actions/globalUIActions';

// Header component
import WorkspaceHeader from 'components/WorkspaceHeader';
import { MobileReadyChromeHeader } from 'components/MobileReadyChrome';

// Components
import { ChromeContent } from 'components/Chrome';

import Filters from './Filters';
import SearchStats from './SearchStats';
import Loader from 'components/Loader';
import UpgradeIcon from 'components/_assets/UpgradeIcon';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import PlanIconLabel from 'components/PlanIconLabel';
import KoalaAvatar from 'koala/components/Avatar';
import PresetFilters from './PresetFilters';
import SegmentBlock from 'components/SegmentBlock';
import DropdownMenu from 'components/DropdownMenu';
import KoalaIconButton from 'koala/components/IconButton';

import ListingNav from 'components/ListingNav';
import { LAST_VISITED_FILTER } from 'config/constants';
import KoalaSelect, { KoalaSelectOption } from 'koala/components/Select';
import { ValueType } from 'react-select';
import { t } from 'i18next';
import OutcomeExpandable from 'components/OutcomeExpandable';
import * as filterUtils from 'utils/filterUtils';
import ShareButton from 'components/ShareButton';

const ContainerGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  min-height: 100%;
`;

const Wrapper = styled.div`
  margin: 0 auto ${theme.spacing.x4} auto;
  max-width: 200rem;
  width: 100%;
  padding: 1.2rem ${theme.spacing.x2};

  h3 {
    padding: ${theme.spacing.x2} 0;
  }

  .outcome-header {
    display: flex;
    flex-wrap: wrap;
  }
`;

const OutcomeContainer = styled.div`
  border: 1px solid ${theme.colors.blockBorder};
  box-sizing: border-box;
  border-radius: 4px;
  background: #fff;

  .outcome-expanded {
    border-bottom: 1px solid ${theme.colors.N10};
  }
  .item:first-of-type {
    border-radius: 3px 3px 0 0;
  }
  .outcome-expanded:last-of-type {
    border-radius: 0 0 3px 3px;
    border-bottom: none;
  }
`;

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${theme.spacing.x1} 0;
`;

const NoResults = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.x3};
`;

const UpgradeBanner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.x3};
  background: rgba(197, 165, 239, 0.25);
  border-radius: 4px;

  max-width: 60rem;
  margin: ${theme.spacing.x1} auto;

  p {
    display: flex;
    align-items: center;

    svg {
      margin-right: ${theme.spacing.x1};
    }
  }
`;

const ResultsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: ${theme.spacing.x1};
  @media ${theme.devices.tablet} {
    flex-direction: column;
    align-items: start;
    margin-bottom: ${theme.spacing.x1};
  }
`;
const ResultsHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x2};
`;

const SortFilters = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

const GroupSeparator = styled.div`
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  background: ${theme.colors.N5};

  border-bottom: 1px solid ${theme.colors.blockBorder};
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};

  span {
    font-weight: 900;
    font-size: 1rem;
    text-transform: uppercase;
  }
`;

const ColumnHeader = styled.div`
  padding: 0 ${theme.spacing.x2} ${theme.spacing.x1} ${theme.spacing.x2};
  display: grid;
  color: ${theme.colors.N80};
  grid-template-areas: 'outcome plan status';
  grid-template-columns: 1fr 37rem 14rem;
  gap: ${theme.spacing.x2};

  > div {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
  }
  .outcome {
    grid-area: outcome;
  }
  .plan {
    grid-area: plan;
  }
  .timeline {
    grid-area: timeline;
  }
  .status {
    grid-area: status;
  }

  @media ${theme.devices.smallDesktop} {
    grid-template-columns: 1fr 24rem 14rem;
  }
  @media ${theme.devices.laptop} {
    grid-template-areas: 'outcome status';
    grid-template-columns: 1fr 12rem;

    .plan {
      display: none;
    }
  }

  @media ${theme.devices.tablet} {
    display: none;
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: ${theme.spacing.x2};

  .create-segment-button {
    display: flex;
    align-items: center;
    margin-top: 2.6rem;
  }
  @media ${theme.devices.mobile} {
    .create-segment-button {
      display: none;
    }
  }
`;

const ActionsNav = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

interface Props {
  workspace: TabilityTypes.Workspace;
}

export interface StatsResult {
  red_outcomes_count: number;
  yellow_outcomes_count: number;
  green_outcomes_count: number;
  grey_outcomes_count: number;
  pnone_outcomes_count: number;
  p10_outcomes_count: number;
  p20_outcomes_count: number;
  p30_outcomes_count: number;
  p40_outcomes_count: number;
  p50_outcomes_count: number;
  p60_outcomes_count: number;
  p70_outcomes_count: number;
  p80_outcomes_count: number;
  p90_outcomes_count: number;
  p100_outcomes_count: number;
  avg_outcomes_progress: number;
  total_outcomes_count: number;
}

function WorkspaceOutcomes(props: Props) {
  const { workspace } = props;
  const { workspaceSlug, filter, segmentId } = useParams<{
    workspaceSlug: string;
    segmentId: string;
    filter: string;
  }>();
  const dispatch = useDispatch();
  const location = useLocation();
  const isSegment = segmentId ? true : false;
  const { data: segmentResponse } = useQuery([queryKeys.segments, segmentId], remoteApi.fetchSegmentDetails, {
    enabled: isSegment,
  });
  const segment: TabilityTypes.Segment = segmentResponse ? segmentResponse.data : null;

  const [outcomesGroups, setOutcomesGroups]: any = useState([]);
  const [outcomesStats, setOutcomesStats] = useState<StatsResult | null>(null);
  const [resultsCount, setResultsCount]: any = useState(0);

  const filterPath = segmentId
    ? routes.WORKSPACE_OUTCOMES_SEGMENT_ROUTE_WITH_FILTERS.replace(':segmentId', segmentId)
    : routes.WORKSPACE_OUTCOMES_ROUTE_WITH_FILTERS;
  const history = useHistory();

  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const isReadOnly = membershipUtils.isReadOnly(currentMembership);

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.workspace.outcomes.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
    localStorage.setItem(LAST_VISITED_FILTER, 'outcomes');
  }, []);

  const updateURL = (filterHash: { [key: string]: any }) => {
    const encodedHash = Base64.encode(JSON.stringify(filterHash));
    history.push(filterPath.replace(':workspaceSlug', workspaceSlug).replace(':filter', encodedHash));
  };

  const customFilterHash = filter ? JSON.parse(Base64.decode(filter)) : {};
  const filterParams = filterUtils.getFilterHash(customFilterHash, currentMembership);
  // Construct the query key using the plan Id
  const queryKey = [
    queryKeys.outcomes,
    'search',
    {
      workspaceSlug,
      filter: filterParams,
    },
  ];

  // Construct the query key using the plan Id
  const queryKeyStats = [
    queryKeys.outcomes,
    'stats',
    {
      workspaceSlug,
      filter: filterParams,
    },
  ];

  const getNextPage = (response: any) => {
    if (response && response.headers && response.headers.link) {
      const links = response.headers.link;
      const parsed = parse(links);
      if (parsed && parsed.next) {
        return parsed.next.page;
      }
    }
    return null;
  };

  // Get the plan details
  const { isLoading, isFetchingMore, fetchMore, canFetchMore }: any = useInfiniteQuery(
    queryKey,
    remoteApi.searchOutcomes,
    {
      getFetchMore: (lastGroup, allGroups) => {
        return getNextPage(lastGroup);
      },
      onSuccess: (data: any) => {
        const resultsCount = parseInt(data[0].headers['x-total']);
        setResultsCount(resultsCount);
        setOutcomesGroups(data);
      },
    },
  );

  // Get the plan details
  const { isLoading: isLoadingStats }: any = useQuery(queryKeyStats, remoteApi.fetchOutcomesStats, {
    onSuccess: (response: any) => {
      setOutcomesStats(response.data);
    },
  });

  // Mutation that will create the workspace in the backend
  const [exportOutcomesMutation]: [any, any] = useMutation(remoteApi.exportOutcomes, {
    onSuccess: (response: any) => {
      const content = response.headers['content-type'];
      const fileName = `outcomes-${format(new Date(), 'Y-MM-dd')}.csv`;
      download(response.data, fileName, content);
    },
  });

  const handleExportOutcomes = () => {
    const mutationParams = {
      workspaceSlug: workspaceSlug,
      filter: filterParams,
    };
    exportOutcomesMutation(mutationParams);
  };

  const handleUpgradeExport = () => {
    const upgradeModalHash = 'workspace:tability20-essentials:upgrade';
    dispatch(setGlobalModalContent(upgradeModalHash));
  };

  const handleUpdateSorting = (option: ValueType<KoalaSelectOption, boolean>) => {
    const filterHash = { ...customFilterHash };
    const optionValue = option as KoalaSelectOption;
    if (optionValue) {
      filterHash['sorting'] = optionValue.value;
      updateURL(filterHash);
    }
  };

  const hasItems = outcomesGroups && outcomesGroups.length > 0 ? outcomesGroups[0].data.length > 0 : false;
  const hasEssentialsSubscription =
    workspaceUtils.hasEssentialsSubscription(workspace) || workspace.pricing_version >= 2;

  // Limiting results is used to limit how many results are available on a free plan
  let limitResultsForUpgrade = false;
  if (workspace.pricing_version >= 4) {
    limitResultsForUpgrade = false;
  } else {
    limitResultsForUpgrade = !hasEssentialsSubscription;
  }

  let indexCount = 0;
  let freeLimit = 3;
  let currentGroup: any = null;
  const sortToGroupMapping: any = {
    plan: 'plan',
    owner: 'membership',
  };

  const unseenResults = resultsCount - freeLimit;

  const showCreateSegment = () => {
    dispatch(setGlobalModalContent(`segment:${filter}:create.outcome`));
  };

  const handleMenuSelection = (value: any) => {
    const action = value.props['data-action'];
    switch (action) {
      case 'export':
        if (hasEssentialsSubscription) {
          handleExportOutcomes();
        } else {
          handleUpgradeExport();
        }
        break;
    }
  };

  const menuItems = () => {
    const items = [];
    items.push(<span data-action="export">{t('shared.export')}</span>);
    return items;
  };

  const handleShare = () => {
    dispatch(setGlobalModalContent('workspace:team:share'));
  };

  const link = `https://${process.env.REACT_APP_DOMAIN}${location.pathname}`;

  const sortOptions: KoalaSelectOption[] = [
    { label: t('workspaceFilters.sortTitle'), value: 'title' },
    { label: t('workspaceFilters.plans'), value: 'plan' },
    { label: t('workspaceFilters.owner'), value: 'owner' },
    { label: t('workspaceFilters.mostCompleted'), value: '-completion' },
    { label: t('workspaceFilters.leastCompleted'), value: 'completion' },
    { label: t('workspaceFilters.newest'), value: '-created_at' },
    { label: t('workspaceFilters.oldest'), value: 'created_at' },
  ];
  const selectedSortOption: any = sortOptions.find((option) => option.value === customFilterHash.sorting);

  return (
    <>
      <Helmet>
        <title>
          {workspace.name} | {translate(workspace, CustomTermKey.OUTCOME, 2)} | Tability
        </title>
      </Helmet>
      <MobileReadyChromeHeader className="no_border">
        <WorkspaceHeader
          workspace={workspace}
          useGreyBackground={true}
          title={<h1>{t('workspaceFilters.title')}</h1>}
        />
      </MobileReadyChromeHeader>
      <ChromeContent isGreyBackground>
        <ListingNav />
        <ContainerGrid>
          <PresetFilters />
          <Wrapper>
            {segment && (
              <SegmentBlock
                segmentableType="outcome"
                segment={segment}
                filterHash={filter}
                workspaceSlug={workspaceSlug}
              />
            )}
            <FiltersContainer>
              <Filters customFilterHash={customFilterHash} updateURL={updateURL} />
              {!isReadOnly && (
                <ActionsNav>
                  {!segment && (
                    <KoalaTextButton
                      appearance="subtle"
                      className="segment-save-button"
                      onClick={() => showCreateSegment()}
                    >
                      <span>{t('workspaceFilters.segments.save')}</span>
                    </KoalaTextButton>
                  )}
                  <ShareButton id="outcomes-filters" link={link} shareCallback={handleShare} />

                  <DropdownMenu
                    trigger={<KoalaIconButton iconName="ellipsis" />}
                    onSelection={handleMenuSelection}
                    items={menuItems()}
                  />
                </ActionsNav>
              )}
            </FiltersContainer>
            {isLoading && <Loader size="medium" />}
            {!isLoading && (
              <Fragment>
                <ResultsHeader>
                  <ResultsHeaderLeft>
                    <h3>
                      {t('workspaceFilters.resultHeader', {
                        count: resultsCount,
                        type: translate(workspace, CustomTermKey.OUTCOME, resultsCount).toLowerCase(),
                      })}
                    </h3>
                  </ResultsHeaderLeft>

                  <SortFilters>
                    <span>{t('workspaceFilters.sortBy')}</span>
                    <KoalaSelect
                      handleChange={handleUpdateSorting}
                      placeholder={t('workspaceFilters.placeholder')}
                      selectedOption={selectedSortOption}
                      options={sortOptions}
                      isMultiSelect={false}
                      size="small"
                    />
                  </SortFilters>
                </ResultsHeader>
                {isLoadingStats && <Loader size="medium" />}
                <SearchStats outcomesStats={outcomesStats} customFilterHash={customFilterHash} updateURL={updateURL} />
                <ColumnHeader>
                  <div className="outcome">{translate(workspace, CustomTermKey.OUTCOME, 1)}</div>
                  <div className="plan">{t('workspaceFilters.plan')}</div>
                  <div className="status">{t('workspaceFilters.status')}</div>
                </ColumnHeader>
                <OutcomeContainer>
                  {!hasItems && (
                    <NoResults>
                      {t('workspaceFilters.emptyState', {
                        type: translate(workspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
                      })}
                    </NoResults>
                  )}
                  {outcomesGroups.map((group: any, i: number) => {
                    return (
                      <Fragment key={i}>
                        {group.data.map((outcome: any, index: number) => {
                          indexCount++;

                          let displayCurrentGroup = false;
                          let comparedGroup = null;
                          // Get the grouping key
                          const groupKey = sortToGroupMapping[customFilterHash.sorting];
                          if (groupKey) {
                            comparedGroup = outcome[groupKey];
                            const currentGroupId = currentGroup ? currentGroup.id : null;
                            const comparedGroupId = comparedGroup ? comparedGroup.id : null;
                            if (currentGroupId !== comparedGroupId) {
                              currentGroup = comparedGroup;
                              displayCurrentGroup = true;
                            }
                          }

                          if (limitResultsForUpgrade && indexCount > freeLimit) {
                            return null;
                          }

                          return (
                            <Fragment key={outcome.id}>
                              {displayCurrentGroup && customFilterHash.sorting === 'plan' && (
                                <GroupSeparator className="item">
                                  <PlanIconLabel plan={outcome.plan} size="xsmall" />
                                </GroupSeparator>
                              )}
                              {displayCurrentGroup && customFilterHash.sorting === 'owner' && (
                                <>
                                  {outcome.membership && (
                                    <GroupSeparator className="item">
                                      <KoalaAvatar membership={outcome.membership} size={1.6} tooltipType="card" />
                                      <span>
                                        {outcome.membership.cached_fullname ?? outcome.membership.cached_email}
                                      </span>
                                    </GroupSeparator>
                                  )}
                                  {!outcome.membership && (
                                    <GroupSeparator className="item">
                                      <span>{t('workspaceFilters.withoutOwner')}</span>
                                    </GroupSeparator>
                                  )}
                                </>
                              )}
                              <OutcomeExpandable
                                outcome={outcome}
                                defaultExpanded={false}
                                hideClosedInitiatives={true}
                                showPlan={true}
                                hideTags={true}
                              />
                            </Fragment>
                          );
                        })}
                      </Fragment>
                    );
                  })}
                  {canFetchMore && !limitResultsForUpgrade && (
                    <LoadMore>
                      <KoalaButton loading={isFetchingMore} onClick={() => fetchMore()} appearance="secondary">
                        {t('shared.loadMore')}
                      </KoalaButton>
                    </LoadMore>
                  )}
                  {limitResultsForUpgrade && resultsCount > freeLimit && (
                    <>
                      {workspace.pricing_version < 4 && (
                        <UpgradeBanner>
                          <p>
                            <UpgradeIcon />{' '}
                            <b> {t('workspaceFilters.upgrade.moreResults', { count: unseenResults })}</b>
                          </p>
                          <p>
                            {t('workspaceFilters.upgrade.information', {
                              count: unseenResults,
                              type: translate(workspace, CustomTermKey.OUTCOME, unseenResults).toLowerCase(),
                            })}
                          </p>
                          {currentMembership && ['owner'].includes(currentMembership.role) && (
                            <p>
                              <KoalaButton isLink to={`/${workspaceSlug}/settings/billing`}>
                                {t('shared.upgrade.startTrial')}
                              </KoalaButton>
                            </p>
                          )}
                          {currentMembership && !['owner'].includes(currentMembership.role) && (
                            <p>
                              <KoalaTextButton href="https://tability.io/pricing" target="_blank" rel="noreferrer">
                                {t('shared.upgrade.learnMore')}
                              </KoalaTextButton>
                            </p>
                          )}
                        </UpgradeBanner>
                      )}
                      {workspace.pricing_version >= 4 && (
                        <UpgradeBanner>
                          <p>
                            <UpgradeIcon />{' '}
                            <b> {t('workspaceFilters.upgradeV4.moreResults', { count: unseenResults })}</b>
                          </p>
                          <p>
                            {t('workspaceFilters.upgradeV4.information', {
                              count: unseenResults,
                              type: translate(workspace, CustomTermKey.OUTCOME, unseenResults).toLowerCase(),
                            })}
                          </p>
                          {currentMembership && ['owner'].includes(currentMembership.role) && (
                            <p>
                              <KoalaButton isLink to={`/${workspaceSlug}/settings/billing`}>
                                {t('workspaceFilters.upgradeV4.upgrade')}
                              </KoalaButton>
                            </p>
                          )}
                          {currentMembership && !['owner'].includes(currentMembership.role) && (
                            <p>
                              <KoalaTextButton href="https://tability.io/pricing" target="_blank" rel="noreferrer">
                                {t('shared.learnMore')}
                              </KoalaTextButton>
                            </p>
                          )}
                        </UpgradeBanner>
                      )}
                    </>
                  )}
                </OutcomeContainer>
              </Fragment>
            )}
          </Wrapper>
        </ContainerGrid>
      </ChromeContent>
    </>
  );
}

export default WorkspaceOutcomes;
