import { animated, useSprings, to as interpolate } from '@react-spring/web';
import React, { useState, useRef } from 'react';
import { useDrag } from 'react-use-gesture';
import styled from 'styled-components';

import CardBackground1 from './CardBackground1.png';
import CardBackground2 from './CardBackground2.png';
import theme from 'theme';
import KoalaButton from 'koala/components/Button';
import html2canvas from 'html2canvas';
import KoalaIconButton from 'koala/components/IconButton';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  grid-template-rows: 700px 55px;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.x4};
  margin-top: ${theme.spacing.x2};
  overflow-x: hidden;
`;
const CardsContainer = styled.div`
  position: relative;
  height: 600px;
  width: 700px;
  display: flex;
  justify-content: center;
  padding-top: 8rem;
`;
const ButtonContainer = styled.div`
  width: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeckContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.x2};
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const AnimatedDiv = styled(animated.div)`
  position: absolute;
  width: 500px;
  height: 500px;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: none;
`;

const AnimatedCard = styled(animated.div)`
  background-color: white;
  background-size: cover;
  background-position: center;
  width: 500px;
  height: 500px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 12px 24px -12px rgba(0, 0, 0, 0.2);
`;

const CardBackground = styled.div<{ cardBackground: string }>`
  background-image: url(${({ cardBackground }) => cardBackground});
  background-size: cover;
  background-position: center;
  width: 500px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DownloadRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.x2};
`;

// These two are just helpers, they curate spring data, values that are later being interpolated into css
const to = (i: number) => ({
  x: 0,
  y: i * -4,
  scale: 1,
  rot: -10 + Math.random() * 20,
  delay: i * 100,
});
const from = (_i: number) => ({ x: 0, rot: 0, scale: 1.5, y: -1000 });
// This is being used down there in the view, it interpolates rotation and scale into a css transform
const trans = (r: number, s: number) =>
  `perspective(1500px) rotateX(0deg) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`;

interface Props {
  cards: JSX.Element[];
}
function ReviewCardDeck(props: Props) {
  const { cards } = props;
  const { t } = useTranslation();
  const [gone] = React.useState(() => new Set()); // The set flags all the cards that are flicked out
  const [isDownloading, setIsDownloading] = useState(false);
  const [hasDownloaded, setHasDownloaded] = useState(false);
  const cardRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [currentIndex, setCurrentIndex] = useState(cards.length - 1);
  // Initialize the refs array
  React.useEffect(() => {
    cardRefs.current = cardRefs.current.slice(0, cards.length);
  }, [cards.length]);

  const handlePreviousCard = () => {
    setHasDownloaded(false);
    if (currentIndex < cards.length - 1) {
      gone.delete(currentIndex + 1); // Remove the next card from gone set
      setCurrentIndex(currentIndex + 1);
      // Animate the card back in
      api.start((i) => {
        if (i === currentIndex + 1) {
          return {
            x: 0,
            rot: 0,
            scale: 1,
            delay: undefined,
            config: { friction: 100, tension: 600 },
          };
        }
        return undefined;
      });
    }
  };

  const handleNextCard = () => {
    setHasDownloaded(false);
    if (currentIndex > 0) {
      gone.add(currentIndex); // Add current card to gone set
      setCurrentIndex(currentIndex - 1);
      // Animate the card out
      api.start((i) => {
        if (i === currentIndex) {
          return {
            x: 200 + window.innerWidth,
            rot: 10,
            scale: 1,
            delay: undefined,
            config: { friction: 250, tension: 600 },
          };
        }
        return undefined;
      });
    }
  };

  const [springProps, api] = useSprings(cards.length, (i) => ({
    ...to(i),
    from: from(i),
  })); // Create a bunch of springs using the helpers above
  // Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity
  const bind = useDrag(({ args: [index], down, movement: [mx], direction: [xDir], velocity }) => {
    const trigger = velocity > 0.2; // If you flick hard enough it should trigger the card to fly out
    const dir = xDir < 0 ? -1 : 1; // Direction should either point left or right
    if (!down && trigger) {
      gone.add(index);
      setCurrentIndex(Math.max(0, currentIndex - 1));
    }
    api.start((i) => {
      if (index !== i) return; // We're only interested in changing spring-data for the current spring
      const isGone = gone.has(index);
      const x = isGone ? (200 + window.innerWidth) * dir : down ? mx : 0; // When a card is gone it flys out left or right, otherwise goes back to zero
      const rot = mx / 100 + (isGone ? dir * 10 * velocity : 0); // How much the card tilts, flicking it harder makes it rotate faster
      const scale = down ? 1.1 : 1; // Active cards lift up a bit
      return {
        x,
        rot,
        scale,
        delay: undefined,
        config: { friction: 100, tension: down ? 800 : isGone ? 200 : 500 },
      };
    });

    if (!down && gone.size === cards.length) {
      setTimeout(() => {
        gone.clear();
        setCurrentIndex(cards.length - 1);
        api.start((i) => to(i));
      }, 600);
    }
  });

  const handleDownload = async () => {
    setIsDownloading(true);

    const topCardIndex = cards.length - gone.size - 1;
    const elem = cardRefs.current[topCardIndex];
    if (!elem) return;
    elem.style.transform = 'none';
    await new Promise((resolve) => requestAnimationFrame(resolve));
    const cardElem: HTMLElement | null = elem.querySelector('.card');
    if (!cardElem) return;
    try {
      const canvas = await html2canvas(cardElem, {
        useCORS: true,
        scale: 1,
      });

      const data = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      if (typeof link.download === 'string') {
        link.href = data;
        const filename = 'year_review';
        link.download = `${filename}.png`;
        link.addEventListener('click', (e: MouseEvent) => {
          e.stopPropagation();
        });
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    } finally {
      setIsDownloading(false);
      setHasDownloaded(true);
    }
  };

  const cardProps: any[] = springProps;

  // Now we're just mapping the animated values to our view, that's it. Btw, this component only renders once. :-)
  return (
    <Container>
      <DeckContainer>
        <ButtonContainer>
          {currentIndex < cards.length - 1 && <KoalaIconButton iconName="leftChevron" onClick={handlePreviousCard} />}
        </ButtonContainer>
        <CardsContainer>
          {cardProps.map(({ x, y, rot, scale }, i) => {
            const cardBackground = i % 2 === 0 ? CardBackground1 : CardBackground2;
            return (
              <AnimatedDiv key={i} style={{ x, y }}>
                {/* This is the card itself, we're binding our gesture to it (and inject its index so we know which is which) */}
                <AnimatedCard
                  {...bind(i)}
                  style={{
                    transform: interpolate([rot, scale], (r, s) => trans(r, s)),
                  }}
                  ref={(el) => (cardRefs.current[i] = el)}
                >
                  <CardBackground cardBackground={cardBackground} className="card">
                    {cards[i]}
                  </CardBackground>
                </AnimatedCard>
              </AnimatedDiv>
            );
          })}
        </CardsContainer>
        <ButtonContainer>
          {currentIndex > 0 && <KoalaIconButton iconName="rightChevron" onClick={handleNextCard} />}
        </ButtonContainer>
      </DeckContainer>
      <DownloadRow>
        <KoalaButton onClick={handleDownload} loading={isDownloading} disabled={isDownloading}>
          {hasDownloaded ? t('modals.yearReview.downloaded') : t('modals.yearReview.download')}
        </KoalaButton>
      </DownloadRow>
    </Container>
  );
}

export default ReviewCardDeck;
