import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import { Membership, Team, TeamMember } from 'types';
import { useQuery } from 'react-query';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { setGlobalModalContent } from 'state/actions/globalUIActions';

import Loader from 'components/Loader';
import KoalaTextBadge from 'koala/components/TextBadge';
import KoalaTextButton from 'koala/components/TextButton';
import { useTranslation } from 'react-i18next';
import KoalaAvatar from 'koala/components/Avatar';
import { useHistory } from 'react-router-dom';

const MembersHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing.x1};
`;
const MemberTitleCount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${theme.spacing.x1};
`;
const Container = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  margin: 0px ${theme.spacing.x2};
`;

const PeopleContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
  flex-wrap: wrap;
`;
interface Props {
  team: Team;
}

function TeamMembers(props: Props) {
  const dispatch = useDispatch();
  const { team } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const teamMembersQueryKey = [queryKeys.teamMembers, team.id];
  const staleTime = 0;
  const { isLoading } = useQuery(teamMembersQueryKey, remoteApi.fetchTeamMembers, {
    staleTime,
    onSuccess: (response) => {
      setTeamMembers(response.data);
    },
  });

  const handleAvatarClicked = (membership: Membership) => {
    history.push(`/${currentWorkspace.slug}/people/${membership.id}`);
  };

  const handleManageMembers = () => {
    const blockId = `team:${team.id}:edit.members`;
    dispatch(setGlobalModalContent(blockId));
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <MembersHeader>
        <MemberTitleCount>
          <h3>{t('workspaceTeams.teamMember.title')}</h3>
          <KoalaTextBadge edge="circle" variant="neutral-light">
            {teamMembers.length}
          </KoalaTextBadge>
        </MemberTitleCount>

        <KoalaTextButton onClick={handleManageMembers} size="small" appearance="subtle">
          {t('shared.edit')}
        </KoalaTextButton>
      </MembersHeader>
      <PeopleContainer>
        {teamMembers.map((teamMember: any) => {
          const { membership } = teamMember;
          return (
            <KoalaAvatar
              membership={membership}
              handleClick={() => handleAvatarClicked(membership)}
              tooltipType="card"
            />
          );
        })}
      </PeopleContainer>
    </Container>
  );
}

export default React.memo(TeamMembers);
