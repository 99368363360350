import React, { memo } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import styled from 'styled-components';
import theme from 'theme';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import KoalaButton from 'koala/components/Button';
import KoalaIcon from 'koala/components/Icons';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: red;
  border: 1px solid ${theme.colors.blockBorder};
  border-radius: 4px;
  background-color: ${theme.colors.B5};
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};

  .btn--secondary {
    min-height: 100%;
    min-width: 100%;
    border: none;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${theme.spacing.x1};
  }
`;
const CustomHandle = styled(Handle)`
  background: transparent;
  border: none;
`;

function RootNode(node: NodeProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data } = node;
  const { parent_id } = data;

  const handleCreateSubPlan = (parent_id: string) => {
    dispatch(setGlobalModalContent(`plan:${parent_id}:create.plan`));
  };

  return (
    <Container onClick={(e) => e.stopPropagation()}>
      <CustomHandle type="target" position={Position.Top} key="in" id="in" isConnectableStart={false} />
      <KoalaButton appearance="secondary" onClick={() => handleCreateSubPlan(parent_id)}>
        <KoalaIcon iconName="plus" iconSize="small" />
        <span>{t('workspacePlan.subPlan.add')}</span>
      </KoalaButton>
    </Container>
  );
}

export default memo(RootNode);
