/**
  Login Logic.

  Given that some users might have 2FA enabled, and some may not, we need to first check if the email given
  has 2FA active. So the logic is:
  1. Use the checkCredentials API to get the has_2fa_enabled flagged.
  2. Display the correct set of fields based on the response.

  Note that checkCredentials will always return something even if the email doesn't exist to avoid enumeration
  issues.
 */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as amplitudeUtils from 'utils/amplitude';
import { useDispatch, useSelector } from 'react-redux';
import { sessionActions, uiActions } from 'state/actions/';
import theme from 'theme';

// Components
import { Helmet } from 'react-helmet';
import MSTeamsSignIn from 'components/MSTeamsSignIn';
import GoogleSignIn from 'components/GoogleSignIn';
import { Link } from 'react-router-dom';
import Wordmark from 'components/Wordmark';
import KoalaButton from 'koala/components/Button';

import { useMutation } from 'react-query';

// API
import * as remoteApi from 'api/remote';
import { Trans, useTranslation } from 'react-i18next';

// Components
const Container = styled.div`
  height: 100%;
  background: #e8e8e8;
  display: flex;
  justify-content: center;

  a {
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 800px) {
    padding: ${theme.spacing.x4};
    margin-bottom: 30rem;
  }
`;

const WordmarkWrapper = styled.div`
  margin-bottom: ${theme.spacing.x4};
  display: flex;
  justify-content: center;
`;

const OrSeparator = styled.div`
  margin: ${theme.spacing.x2} 0;
  border-bottom: 2px solid #d1d1d1;
  display: flex;
  justify-content: center;
  span {
    display: block;
    font-size: 1.4rem;
    line-height: 1.4rem;
    background-color: #fff;
    padding: 0 ${theme.spacing.x2};
    position: relative;
    top: 0.7rem;
  }
`;

const Content = styled.div`
  width: 100%;
  background: #fff;
  align-items: center;
  max-width: 48rem;
  padding: 6rem 8rem;
  border-radius: 1.6rem;
  position: relative;
  p {
    font-size: 2rem;
    line-height: 1.4;
  }

  .tabby_peek {
    width: 10rem;
    position: absolute;
    top: -6.8rem;
    right: 4rem;
    z-index: 99;
  }

  @media (max-width: 800px) {
    padding: ${theme.spacing.x4};
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;

const Form = styled.form`
  display: flex;
  margin-top: 3.2rem;
  flex-direction: column;
  input {
    margin-bottom: 1.6rem;

    @media (max-width: 800px) {
      &.small {
        width: 100%;
      }
    }
  }
`;

const ErrorList = styled.ul`
  margin-bottom: 1.6rem;
  color: #d0402e;
`;

const ReconfirmSuccess = styled.ul`
  margin-bottom: 1.6rem;
  color: #397a67;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: 1.6rem;
  }
`;

const ClassicContainer = styled.div`
  padding: ${theme.spacing.x2};
  background: #f3f3f3;
  position: absolute;
  top: ${theme.spacing.x3};
  right: ${theme.spacing.x3};
  border-radius: 16px;
  font-weight: 500;
  font-size: 1.4rem;
  a {
    color: ${theme.colors.blue};
  }

  @media (max-width: 800px) {
    position: relative;
  }
`;

const FormHelp = styled.div`
  color: ${theme.colors.subtleText};
  a {
    color: ${theme.colors.subtleText};
  }
  margin-top: ${theme.spacing.x2};
`;

function SessionLogin() {
  const dispatch = useDispatch();
  const { t } = useTranslation(undefined, { useSuspense: false });

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.session.login.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  // Reset Redux state for the UI
  useEffect(() => {
    dispatch(uiActions.resetPageState('Login'));
  }, [dispatch]);

  const [hasCheckedEmail, setHasCheckedEmail] = useState(false);
  const [has2FAEnabled, setHas2FAEnabled] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp_attempt, setOTPAttempt] = useState('');

  const uiState = useSelector((state: any) => state.pageLogin);
  const isInMSTeams = useSelector((state: any) => state.globalUI.isInMSTeams);

  const { hasErrors, error, isRequesting, isResendingConfirmation, hasSentNewConfirm } = uiState;

  const [checkCredentialsMutation, { isLoading: isCheckingEmail }]: [any, any] = useMutation(
    remoteApi.checkCredentials,
    {
      onSuccess: (response) => {
        setHasCheckedEmail(true);
        setHas2FAEnabled(response.data.has_2fa_enabled);
      },
    },
  );

  const handleCheckEmail = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    checkCredentialsMutation(email);
  };

  const handleLogin = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    const credentials = {
      email,
      password,
      otp_attempt,
    };
    dispatch(sessionActions.login(credentials));
  };

  const handleNewConfirmation = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(sessionActions.resendConfirmation(email));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputTarget = e.target;
    const value: any = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;

    switch (name) {
      case 'email':
        setEmail(value);
        break;
      case 'password':
        setPassword(value);
        break;
      case 'otp_attempt':
        setOTPAttempt(value);
        break;
    }
  };

  // Check if the error requires confirmation to be resent
  let needsConfirmation = false;
  if (error && error.includes('have to confirm')) {
    needsConfirmation = true;
  }

  if (!hasCheckedEmail) {
    return (
      <Container>
        <Helmet>
          <title>{t('sessionLogin.title')} | Tability</title>
          <script async src={`https://www.googletagmanager.com/gtag/js?id=G-S2587LR71B`}></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-S2587LR71B');
            `}
          </script>
        </Helmet>
        <ContentContainer>
          <Content>
            <img
              src="https://res.cloudinary.com/tability/image/upload/v1623762825/static_assets/Tabby_peeking_hrz1kx.png"
              alt="Tabby"
              className="tabby_peek"
            />
            <WordmarkWrapper>
              <Wordmark type="dark" />
            </WordmarkWrapper>
            {isInMSTeams && <MSTeamsSignIn />}
            {!isInMSTeams && <GoogleSignIn buttonLabel={t('sessionLogin.googleSignin')} />}
            <OrSeparator>
              <span>{t('sessionLogin.or')}</span>
            </OrSeparator>
            <Form onSubmit={handleCheckEmail}>
              <input
                type="email"
                name="email"
                autoComplete="off"
                placeholder={t('sessionLogin.emailPlaceholder') ?? 'Work email'}
                required={true}
                onChange={handleChange}
              />
              {hasErrors && <ErrorList>{error}</ErrorList>}
              <Actions>
                <KoalaButton submit loading={isCheckingEmail} disabled={isCheckingEmail}>
                  {t('sessionLogin.continue')}
                </KoalaButton>
                <Trans
                  i18nKey="sessionLogin.signup"
                  components={{ Link: <Link to="/signup" style={{ marginLeft: theme.spacing.half }} />, b: <b /> }}
                />
              </Actions>
              <FormHelp>
                <Trans i18nKey="sessionLogin.resetPassword" components={{ Link: <Link to="/password/reset" /> }} />
              </FormHelp>
              <FormHelp>
                <Trans i18nKey="sessionLogin.sso" components={{ Link: <Link to="/sso" /> }} />
              </FormHelp>
            </Form>
          </Content>
          <ClassicContainer>
            <Trans
              i18nKey="sessionLogin.previous"
              components={{ a: <a href="https://app.tability.io/login">login</a> }}
            />
          </ClassicContainer>
        </ContentContainer>
      </Container>
    );
  }

  // Displays the list of templates
  return (
    <Container>
      <Helmet>
        <title>{t('sessionLogin.title')} | Tability</title>
      </Helmet>
      <ContentContainer>
        <Content>
          <img
            src="https://res.cloudinary.com/tability/image/upload/v1623762825/static_assets/Tabby_peeking_hrz1kx.png"
            alt="Tabby"
            className="tabby_peek"
          />
          <WordmarkWrapper>
            <Wordmark type="dark" />
          </WordmarkWrapper>
          {isInMSTeams && <MSTeamsSignIn />}
          {!isInMSTeams && <GoogleSignIn buttonLabel={t('sessionLogin.googleSignin')} />}
          <OrSeparator>
            <span>{t('sessionLogin.or')}</span>
          </OrSeparator>
          <Form onSubmit={handleLogin}>
            <input
              type="email"
              name="email"
              autoComplete="off"
              placeholder={t('sessionLogin.emailPlaceholder') ?? 'Work email'}
              required={true}
              onChange={handleChange}
            />
            <input
              type="password"
              name="password"
              autoComplete="off"
              placeholder={t('sessionLogin.passwordPlaceholder') ?? 'Password'}
              required={true}
              onChange={handleChange}
            />
            {has2FAEnabled && (
              <input
                type="text"
                name="otp_attempt"
                autoComplete="off"
                placeholder={t('sessionLogin.2FAPlaceholder') ?? '2-Factor Authentication Token'}
                required={true}
                onChange={handleChange}
              />
            )}
            {hasErrors && <ErrorList>{error}</ErrorList>}
            {hasSentNewConfirm && (
              <ReconfirmSuccess>
                <Trans i18nKey="sessionLogin.reconfirmSuccess" values={{ email }} components={{ b: <b /> }} />
              </ReconfirmSuccess>
            )}
            <Actions>
              <KoalaButton submit loading={isRequesting} disabled={isRequesting}>
                {t('sessionLogin.login')}
              </KoalaButton>
              {needsConfirmation && (
                <KoalaButton
                  onClick={handleNewConfirmation}
                  disabled={isResendingConfirmation}
                  loading={isResendingConfirmation}
                  appearance="subtle"
                >
                  {t('sessionLogin.resendConfirmation')}
                </KoalaButton>
              )}
              <div>
                <Trans
                  i18nKey="sessionLogin.signup"
                  components={{ Link: <Link to="/signup" style={{ marginLeft: theme.spacing.half }} />, b: <b /> }}
                />
              </div>
            </Actions>
            <FormHelp>
              <Trans i18nKey="sessionLogin.resetPassword" components={{ Link: <Link to="/password/reset" /> }} />
            </FormHelp>
            <FormHelp>
              <Trans i18nKey="sessionLogin.sso" components={{ Link: <Link to="/sso" /> }} />
            </FormHelp>
          </Form>
        </Content>
        <ClassicContainer>
          <Trans
            i18nKey="sessionLogin.previous"
            components={{ a: <a href="https://app.tability.io/login">login</a> }}
          />
        </ClassicContainer>
      </ContentContainer>
    </Container>
  );
}

export default SessionLogin;
