import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import { Membership } from 'types';
import _ from 'lodash';
import { ValueType } from 'react-select';
import styled from 'styled-components';
import theme from 'theme';
import KoalaAvatar from 'koala/components/Avatar';
import KoalaIcon from 'koala/components/Icons';
import AsyncCreatableSelect from 'react-select/async-creatable';

const UserLabel = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
`;

interface Props {
  userList: string[];
  setUserList: (newList: string[]) => void;
  placeholder?: string;
}

function ShareSelection(props: Props) {
  const { setUserList, placeholder } = props;
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const { t } = useTranslation();
  const translationKey = 'components.shareSelection';
  const performSearch = (input: string, callback: any) => {
    const filters = {
      name: input,
      per_page: 5,
    };
    remoteApi
      .fetchWorkspaceMemberships(queryKeys.memberships, workspaceSlug, filters, 0)
      .then((response) => {
        const memberships = response.data;
        const options = memberships.map((member: Membership) => ({
          label: member.cached_name,
          value: member.id,
          meta: member,
        }));
        callback(options);
      })
      .catch((error) => {
        console.error('Error fetching options:', error);
        callback([]);
      });
  };

  const loadOptionsRef = useRef(
    _.debounce(
      (inputValue, callback) => {
        performSearch(inputValue, callback);
      },
      300,
      { maxWait: 2000 },
    ),
  );

  const handleSelectChange = (newValue: ValueType<{ label: string; value: string }, true>) => {
    const list = newValue ? newValue.map((option) => option.value) : [];
    setUserList(list);
  };
  const checkIsEmail = (input: string) => {
    const isValidEmail = String(input)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
    return !!isValidEmail;
  };
  const showNoOptionsMessage = (obj: { inputValue: string }) => {
    const msg = t(`${translationKey}.noResults`, { value: obj.inputValue });
    return msg ?? `We couldn't find any results for "${obj.inputValue}". Invite people by using an email address.`;
  };
  const formatCreateLabel = (inputValue: string) => {
    const msg = t(`${translationKey}.addEmail`, { value: inputValue });
    return msg ?? `Add via email "${inputValue}"`;
  };
  const formatOptionLabel = (option: any) => {
    const isNew = option.__isNew__;
    if (!isNew) {
      const membership: Membership = option.meta;
      return (
        <UserLabel>
          <KoalaAvatar membership={membership} size={2.4} tooltipType="none" />
          <span>{membership.cached_name}</span>
        </UserLabel>
      );
    }

    return (
      <UserLabel>
        <KoalaIcon iconName="email" />
        {option.label}
      </UserLabel>
    );
  };

  return (
    <AsyncCreatableSelect
      className="tag-select"
      isClearable
      placeholder={placeholder}
      isMulti
      defaultOptions
      cacheOptions
      allowCreateWhileLoading={false}
      loadOptions={(inputValue, callback) => loadOptionsRef.current(inputValue, callback)}
      onChange={handleSelectChange}
      isValidNewOption={checkIsEmail}
      noOptionsMessage={showNoOptionsMessage}
      formatCreateLabel={formatCreateLabel}
      formatOptionLabel={formatOptionLabel}
      loadingMessage={() => t(`shared.loading`) ?? 'Loading'}
    />
  );
}

export default ShareSelection;
