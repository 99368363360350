import React, { memo } from 'react';
import PlanIconLabel from 'components/PlanIconLabel';
import PlanStatus from 'components/PlanStatus';
import KoalaIconButton from 'koala/components/IconButton';
import { Edge, MarkerType, Node, Handle, NodeProps, Position, useReactFlow } from 'reactflow';
import styled from 'styled-components';
import theme from 'theme';
import { Link, useLocation } from 'react-router-dom';
import { Base64 } from 'js-base64';
import * as routes from 'routes';
import * as TabilityTypes from 'types';

import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { fetchPlans } from 'api/remote';

import { useTranslation } from 'react-i18next';
import PlanContent from './PlanContent';
import ProgressBarV2 from 'components/ProgressBarV2';
import ConfidenceBar from 'components/ConfidenceBar';
import { CustomTermKey, translate } from 'utils/customTermUtils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  width: 100%;

  .expand-plan-button {
    transition: all ease 0.2s;
    transform: rotate(90deg);
    &.plan--expanded {
      transform: rotate(-90deg);
    }
  }
`;
const PlanContainer = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid ${theme.colors.blockBorder};
  border-radius: 4px;
  background-color: ${theme.colors.N0};
  display: flex;
  flex-direction: column;
`;
const Title = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: ${theme.spacing.x1};
  justify-content: space-between;
  padding: ${theme.spacing.x2};
  a {
    width: 100%;
  }
  .plan-title {
    font-weight: 600;
  }
`;
const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .expand-button {
    transition: all ease 0.2s;
    transform: rotate(-90deg);
    &.show-outcomes {
      transform: rotate(0deg);
    }
  }
`;

const PlanMetrics = styled.div`
  display: flex;
  gap: ${theme.spacing.x3};
  padding: 0 ${theme.spacing.x1} ${theme.spacing.x1} ${theme.spacing.x1};
  justify-content: space-between;
  width: 100%;

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x1};
  }
`;

const CustomHandle = styled(Handle)`
  background: transparent;
  border: none;
`;

const ExpandChildrenContainer = styled.div`
  height: 3.2rem;
  min-height: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderLabel = styled.span`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1rem;
  color: ${theme.colors.subtleText};
`;

const ExpandableHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.x1};
  cursor: pointer;
  > div {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.half};
  }
  width: 100%;
  justify-content: space-between;
  &:hover {
    background: ${theme.colors.N3};
  }
`;

const nodeDefaults = {
  position: { x: 0, y: 0 },
  sourcePosition: Position.Bottom,
  targetPosition: Position.Top,
};

const edgeDefaults = {
  targetHandle: 'in',
  sourceHandle: 'out',
  type: 'step',
  markerEnd: {
    type: MarkerType.ArrowClosed,
  },
};

function PlanNode(node: NodeProps) {
  const { data } = node;
  const { t } = useTranslation();
  const { plan, path, expanded, handleExpand, expandable, outcomesExpanded, handleExpandOutcomes } = data;
  const { workspace } = plan;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mapStateHash = searchParams.get('mapState');
  let mapState: { [key: string]: any } = {};
  if (mapStateHash) {
    try {
      mapState = JSON.parse(Base64.decode(mapStateHash));
    } catch (e) {}
  }
  // const [showOutcomes, setShowOutcomes] = useState(true);

  // We use addNodes and addEdges to add to the flow graph state
  const { addNodes, addEdges } = useReactFlow();

  // Get the child plans
  const filterParams: { [key: string]: any } = {
    children_of: plan.id,
    status: 'planning',
  };

  const params = {
    limit: 200,
    filter: filterParams,
  };

  useQuery([queryKeys.plans, plan.workspace_id, params], fetchPlans, {
    onSuccess: (response) => {
      // Create the plans mapping
      const plans: TabilityTypes.Plan[] = response.data;

      const nodes: Node[] = [];

      const edges: Edge[] = [];

      plans.forEach((plan) => {
        edges.push({
          id: `${plan.parent_id}:${plan.id}`,
          target: `${plan.id}`,
          source: plan.parent_id,
          ...edgeDefaults,
        });

        const expanded = mapState['expandedPlans'] && mapState['expandedPlans'][plan.id];
        nodes.push({
          id: `${plan.id}`,
          type: 'plan',
          data: {
            expanded,
            plan,
            outcomesExpanded: true,
            handleExpand,
            handleExpandOutcomes,
            path: `${path}${plan.nano_slug}/`,
          },
          style: {
            height: 600,
            maxHeight: 600,
            width: 600,
            maxWidth: 600,
          },
          ...nodeDefaults,
        });
      });

      addEdges(edges);
      addNodes(nodes);
    },
  });

  const routeTarget =
    plan.state === 'published' ? routes.WORKSPACE_PLAN_TRACK_ROUTE : routes.WORKSPACE_PLAN_WRITE_ROUTE;
  const planRoute = routeTarget.replace(':workspaceSlug', workspace.slug).replace(':planId', plan.nano_slug);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    handleExpand(event, node);
    // toggleExpand(node);
  };

  const handleExpandOutcomesClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    handleExpandOutcomes(event, node);
  };

  const expandIconClass = expanded ? 'plan--expanded' : '';
  const expandedClassName = outcomesExpanded ? 'show-outcomes' : '';

  const outcomeProgress = plan.outcome_progress_prct * 100;
  const initiativeProgress = plan.initiative_progress_prct * 100;

  return (
    <Container>
      <CustomHandle position={Position.Top} type="target" id="in" key="in" isConnectableStart={false} />
      <PlanContainer className={`${expandedClassName}`}>
        <Title>
          <Link to={planRoute}>
            <PlanIconLabel plan={plan} />
          </Link>
        </Title>
        <PlanMetrics>
          <div>
            <HeaderLabel>{translate(workspace, CustomTermKey.OUTCOME, 2)}</HeaderLabel>
            <ProgressBarV2
              percentage={outcomeProgress}
              totalCount={plan.total_outcomes_count}
              label={`${Math.round(outcomeProgress)}%`}
            />
          </div>
          <div>
            <HeaderLabel>{translate(workspace, CustomTermKey.INITIATIVE, 2)}</HeaderLabel>
            <ProgressBarV2
              percentage={initiativeProgress}
              totalCount={plan.total_initiatives_count}
              label={`${plan.closed_initiatives_count}/${plan.total_initiatives_count}`}
            />
          </div>
          <div>
            <HeaderLabel>{t('workspacePlans.table.confidence')}</HeaderLabel>
            <ConfidenceBar
              greenCount={plan.green_outcomes_count}
              greyCount={plan.grey_outcomes_count}
              redCount={plan.red_outcomes_count}
              totalCount={plan.total_outcomes_count}
              yellowCount={plan.yellow_outcomes_count}
              ncs={Math.round(plan.ncs)}
            />
          </div>
        </PlanMetrics>
        <Footer>
          <ExpandableHeader onClick={handleExpandOutcomesClick}>
            <div>
              <KoalaIconButton
                className={`${expandedClassName} expand-button`}
                iconName="downChevron"
                size="xsmall"
                edge="square"
                onClick={handleExpandOutcomesClick}
              />
              {outcomesExpanded && <small>{t('workspaceMap.hidePlanContent')}</small>}
              {!outcomesExpanded && <small>{t('workspaceMap.showPlanContent')}</small>}
            </div>

            <PlanStatus plan={plan} />
          </ExpandableHeader>
        </Footer>
        {outcomesExpanded && (
          <>
            <PlanContent plan={plan} />
          </>
        )}
      </PlanContainer>
      <ExpandChildrenContainer>
        {expandable && (
          <KoalaIconButton
            size="small"
            onClick={handleClick}
            iconName={'leftChevron'}
            className={`expand-plan-button ${expandIconClass}`}
          />
        )}
      </ExpandChildrenContainer>

      <CustomHandle type="source" position={Position.Bottom} key="out" id="out" isConnectableStart={false} />
    </Container>
  );
}

export default memo(PlanNode);
