import MentionBox from 'components/MentionBox';
import KoalaAvatar from 'koala/components/Avatar';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import React, { useState } from 'react';
import { useMutation, useQueryCache } from 'react-query';
import styled from 'styled-components';
import theme from 'theme';
import { RetrospectiveComment } from 'types';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import { useTranslation } from 'react-i18next';

const Grid = styled.div`
  margin-left: ${theme.spacing.x4};
  padding: 1.2rem ${theme.spacing.x2};
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'avatar user'
    '. content'
    '. actions';
  gap: ${theme.spacing.x1};
`;

const Avatar = styled.div`
  grid-area: avatar;
`;
const User = styled.div`
  color: ${theme.colors.N70};
`;

const Content = styled.div`
  grid-area: content;

  .ql-editor.ql-display-only {
    padding: 0;
  }
`;

const Actions = styled.div`
  grid-area: actions;
  display: flex;
  gap: ${theme.spacing.x1};
`;

interface Props {
  retrospectiveComment: RetrospectiveComment;
  setShowNewReply: (value: boolean) => void;
}

function RetrospectiveReplyNew(props: Props) {
  const { retrospectiveComment, setShowNewReply } = props;
  const queryCache = useQueryCache();
  const { membership } = retrospectiveComment;
  const [reply, setReply] = useState('');
  const { t } = useTranslation();

  // create reply
  const [createReplyMutation, { isLoading: isCreating }] = useMutation(remoteApi.createRetrospectiveReply, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.retrospectiveReplies, retrospectiveComment.id]);
      setReply('');
      setShowNewReply(false);
    },
  });
  const handleSubmit = () => {
    const retrospective_reply = {
      body: reply,
    };
    createReplyMutation({
      retrospectiveCommentId: retrospectiveComment.id,
      retrospective_reply,
    });
  };

  return (
    <Grid>
      <Avatar>
        <KoalaAvatar membership={membership} size={2.4} tooltipType="none" />
      </Avatar>
      <User>{membership.cached_fullname ?? membership.cached_email}</User>
      <Content>
        <MentionBox
          setValue={setReply}
          value={reply}
          placeholder={t('workspaceRetrospective.addReply') ?? 'Add a reply...'}
          comment={true}
          cmdEnterCallback={handleSubmit}
        />
      </Content>
      <Actions>
        <KoalaButton onClick={handleSubmit} loading={isCreating}>
          {t('shared.save')}
        </KoalaButton>
        <KoalaTextButton onClick={() => setShowNewReply(false)}>{t('shared.cancel')}</KoalaTextButton>
      </Actions>
    </Grid>
  );
}

export default RetrospectiveReplyNew;
