import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useSelector, shallowEqual } from 'react-redux';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import MentionBox from 'components/MentionBox';

// API
import * as remoteApi from 'api/remote';

import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaAvatar from 'koala/components/Avatar';
import { useTranslation } from 'react-i18next';
import { Membership, User } from 'types';

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'gutter meta'
    'gutter content'
    'gutter footer';
  column-gap: ${theme.spacing.x3};

  margin-bottom: ${theme.spacing.x2};
`;

const Gutter = styled.div`
  grid-area: gutter;
`;

const Meta = styled.div`
  grid-area: meta;
  display: flex;
  align-items: center;

  font-weight: 600;
`;

const Content = styled.div`
  grid-area: content;
  margin: ${theme.spacing.x1} 0;

  textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    font-size: 1.6rem;
    line-height: 1.4;
    padding: ${theme.spacing.x1};
    min-height: 10rem;
    border-radius: 4px;
    border: 1px solid #949494;
    &:placeholder {
      color: #949494;
      font-size: 1.4rem;
    }

    width: 70%;
  }
`;

const Footer = styled.div`
  grid-area: footer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${theme.colors.subtleText};

  > div {
    display: flex;
    align-items: center;

    button {
      margin-right: ${theme.spacing.x1};
    }
  }
`;

const ReplyPlaceholder = styled.input`
  background: #f3f3f3;
  border: 1px solid transparent;
`;

interface Props {
  blockId: string;
  plan: any;
  closePanel?: any;
}

function CommentBlock(props: Props) {
  const queryCache = useQueryCache();
  const { t } = useTranslation();

  const { blockId, plan } = props;
  const [maskEditor, setMaskEditor] = useState(true);

  const currentUser: User = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);

  // Review comment mutations
  const [createReviewCommentMutation, { isLoading }]: [any, any] = useMutation(remoteApi.createReviewComment, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.reviewComments);
    },
  });

  // Form values
  const [body, setBody] = useState('');

  const handleSubmit = () => {
    const reviewComment = {
      block_id: blockId,
      body,
    };
    createReviewCommentMutation({
      planId: plan.id,
      review_comment: reviewComment,
    });
  };

  if (maskEditor) {
    return (
      <Grid>
        <Gutter>
          <KoalaAvatar membership={{ ...currentMembership, user: currentUser }} tooltipType="none" />
        </Gutter>
        <Meta>{currentMembership.cached_fullname ?? currentMembership.cached_email}</Meta>
        <Content>
          <ReplyPlaceholder
            type="text"
            placeholder={t('shared.reply') ?? 'Reply'}
            onFocus={() => {
              setMaskEditor(false);
            }}
          />
        </Content>
      </Grid>
    );
  }
  return (
    <Grid>
      <Gutter>
        <KoalaAvatar membership={{ ...currentMembership, user: currentUser }} tooltipType="none" />
      </Gutter>
      <Meta>{currentMembership.cached_fullname ?? currentMembership.cached_email}</Meta>
      <Content>
        <MentionBox
          setValue={setBody}
          value={body}
          placeholder={t('comments.addComment') ?? 'Add your comment'}
          cmdEnterCallback={handleSubmit}
        />
      </Content>
      <Footer>
        <div>
          <KoalaButton onClick={handleSubmit} disabled={isLoading} loading={isLoading}>
            {t('shared.save')}
          </KoalaButton>
          <KoalaTextButton onClick={() => setMaskEditor(true)}>{t('shared.cancel')}</KoalaTextButton>
        </div>
      </Footer>
    </Grid>
  );
}

export default React.memo(CommentBlock);
