import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import { useTranslation } from 'react-i18next';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';

import ReactFlow, {
  Background,
  // useReactFlow,
  Node,
  Edge,
  OnNodesChange,
  OnEdgesChange,
  applyEdgeChanges,
  applyNodeChanges,
  Controls,
  NodeMouseHandler,
} from 'reactflow';
import 'reactflow/dist/style.css';

import OutcomeNode from 'components/OutcomeNode';
import PlanNode from './PlanNode';
import useExpandCollapse from './useExpandCollapse';

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

const MapInfo = styled.div`
  position: absolute;
  z-index: 1;
  top: ${theme.spacing.x1};
  left: ${theme.spacing.x2};
  font-size: 1rem;
  color: ${theme.colors.subtleText};
`;

const nodeTypes = {
  outcome: OutcomeNode,
  plan: PlanNode,
};

interface Props {
  initialNodes: Node[];
  initialEdges: Edge[];
  plan: TabilityTypes.Plan;
}

function Map(props: Props) {
  const { t } = useTranslation();
  const { initialEdges, initialNodes } = props;
  // const reactFlowInstance = useReactFlow();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  // Create the initial nodes from the outcomes of the parent plan
  const [nodes, setNodes] = useState<Node[]>(initialNodes);
  const [edges, setEdges] = useState<Edge[]>(initialEdges);
  // const [fitNeeded, setFitNeeded] = useState(true);

  const onNodesChange: OnNodesChange = useCallback((changes) => {
    setNodes((nds) => applyNodeChanges(changes, nds));
    // fit view after nodes change
    // needs to ignore position as that's handled onDragEnd
    // const positionChange = changes.find((change) => change.type === 'position');
    // if (!positionChange) {
    //   setFitNeeded(true);
    // }
  }, []);
  const onEdgesChange: OnEdgesChange = useCallback((changes) => setEdges((eds) => applyEdgeChanges(changes, eds)), []);

  // set expanded when arrow is clicked in PlanNode
  const toggleNodeExpand: NodeMouseHandler = useCallback(
    (_, node) => {
      setNodes((nds) =>
        nds.map((n) => {
          if (n.id === node.id) {
            return {
              ...n,
              data: { ...n.data, expanded: !n.data.expanded },
            };
          }

          return n;
        }),
      );
    },
    [setNodes],
  );

  // when initial values change, set edges and nodes
  useEffect(() => {
    setEdges([...initialEdges]);
    // pass handleNodeExpand callback to nodes
    setNodes(() =>
      initialNodes.map((node) => {
        return {
          ...node,
          data: {
            ...node.data,
            toggleNodeExpand,
          },
        };
      }),
    );
    // setFitNeeded(true);
  }, [toggleNodeExpand, initialEdges, initialNodes]);

  // This function filters the nodes to avoid duplicate
  // This works because findIndex always returns the first element found in an array
  // See https://stackoverflow.com/questions/2218999/how-to-remove-all-duplicates-from-an-array-of-objects
  const filteredNodes = nodes.filter(
    (node, index, self) => index === self.findIndex((reference) => reference.id === node.id),
  );
  // This function filters the edges to avoid duplicate (see above)
  const filteredEdges = edges.filter(
    (edge, index, self) => index === self.findIndex((reference) => reference.id === edge.id),
  );

  const { nodes: visibleNodes, edges: visibleEdges } = useExpandCollapse(filteredNodes, filteredEdges, {
    layoutNodes: true,
    treeWidth: 450,
    treeHeight: 500,
  });

  // // fit graph in screen
  // useEffect(() => {
  //   if (fitNeeded) {
  //     // reactFlowInstance.fitView({ duration: 300, minZoom: 0.1, maxZoom: 2 });
  //     setFitNeeded(false);
  //   }
  // }, [fitNeeded, reactFlowInstance]);

  const outcomeLabel = translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase();

  return (
    <Container>
      <MapInfo>
        {t('workspacePlan.map.info', { outcomeLabel: outcomeLabel })} -{' '}
        <a
          href="https://guides.tability.app/docs/features/outcomes-key-results/cascading-map"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('shared.learnMore').toLowerCase()}
        </a>
      </MapInfo>
      <ReactFlow
        nodes={visibleNodes}
        edges={visibleEdges}
        snapToGrid
        minZoom={0.1}
        maxZoom={2}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={nodeTypes}
        defaultViewport={{
          x: 0,
          y: 0,
          zoom: 0.7,
        }}
        style={{
          backgroundColor: theme.colors.N3,
        }}
      >
        <Background color={theme.colors.N20} size={2} />
        <Controls />
      </ReactFlow>
    </Container>
  );
}

export default React.memo(Map);
