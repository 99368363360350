import React from 'react';
import * as TabilityTypes from 'types';
import { useSelector, shallowEqual } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
// Translations
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { useTranslation } from 'react-i18next';

// API and State
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import * as remoteApi from 'api/remote';
import { Link } from 'react-router-dom';

import Loader from 'components/Loader';
import KoalaAvatar from 'koala/components/Avatar';
import ProgressBarV2 from 'components/ProgressBarV2';

const Card = styled.div`
  border: 1px solid ${theme.colors.blockBorder};
  border-radius: 4px;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  align-items: center;
  background: ${theme.colors.N0};

  h4 {
    font-size: 1rem;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
`;
const ActivityWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${theme.spacing.x2};
  margin-bottom: ${theme.spacing.x4};
`;

const LeaderList = styled.div`
  margin-top: ${theme.spacing.x2};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  width: 100%;
`;
const LeaderRow = styled.div`
  display: flex;
  width: 100%;
  gap: ${theme.spacing.x2};
  align-items: center;
  .number {
    width: 2rem;
    font-weight: 600;
  }
  .profile {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.x1};
    flex: 1;
    > a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .progress {
    width: 8rem;
  }
  .progress_streak {
    width: 10rem;
    display: flex;
    gap: ${theme.spacing.half};
    align-items: center;
    font-size: 1.1rem;
  }
`;

const EmptyState = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const staleTime = 60 * 1000 * 5;

function Component() {
  const { t } = useTranslation();
  const currentWorkspace: TabilityTypes.Workspace = useSelector(
    (state: any) => state.session.currentWorkspace,
    shallowEqual,
  );

  const queryKeyOutcomeProgress = [queryKeys.currentWorkspace, currentWorkspace.slug, 'outcome_progress_ladder'];
  const { isFetching: isFetchinOutcomeProgressLadder, data: outcomeProgressLadderResponse } = useQuery(
    queryKeyOutcomeProgress,
    remoteApi.fetchWorkspaceOutcomeProgressLadder,
    {
      staleTime,
    },
  );

  const queryKeyInitiativeProgressLadder = [
    queryKeys.currentWorkspace,
    currentWorkspace.slug,
    'initiative_progress_ladder',
  ];
  const { isFetching: isFetchingInitiativeProgressLadder, data: initiativeProgressLadderResponse } = useQuery(
    queryKeyInitiativeProgressLadder,
    remoteApi.fetchWorkspaceInitiativeProgressLadder,
    {
      staleTime,
    },
  );

  const queryKeyStreakLadder = [queryKeys.currentWorkspace, currentWorkspace.slug, 'streak_ladder'];
  const { isFetching: isFetchingStreakLadder, data: streakLadderResponse } = useQuery(
    queryKeyStreakLadder,
    remoteApi.fetchWorkspaceStreakStats,
    {
      staleTime,
    },
  );

  const outcomeProgressLadder: TabilityTypes.Membership[] = outcomeProgressLadderResponse?.data || [];
  const initiativeProgressLadder: TabilityTypes.Membership[] = initiativeProgressLadderResponse?.data || [];
  const streakLadder: any[] = streakLadderResponse?.data || [];
  return (
    <>
      <h3>{t('workspaceInsights.progressLeaderboard')}</h3>
      <ActivityWrapper>
        <Card>
          <h4>
            {t('workspaceInsights.outcomeProgressLeaderboard', {
              label: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLocaleLowerCase(),
            })}
          </h4>
          {isFetchinOutcomeProgressLadder && <Loader />}
          {!isFetchinOutcomeProgressLadder && outcomeProgressLadder.length > 0 && (
            <LeaderList>
              {outcomeProgressLadder.map((member, index) => (
                <LeaderRow key={member.id}>
                  <div className="number">{index + 1}</div>
                  <div className="profile">
                    <KoalaAvatar membership={member} tooltipType="card" />
                    <Link to={`/${currentWorkspace.slug}/people/${member.id}`}>{member.user.fullname}</Link>
                  </div>
                  <div className="progress">
                    <ProgressBarV2
                      percentage={member.outcome_progress_prct * 100}
                      totalCount={member.total_outcomes_count}
                      label={`${Math.round(member.outcome_progress_prct * 100)}%`}
                    />
                  </div>
                </LeaderRow>
              ))}
            </LeaderList>
          )}
          {!isFetchinOutcomeProgressLadder && outcomeProgressLadder.length === 0 && (
            <EmptyState>
              <small className="subtle">{t('workspaceInsights.noLeaderboardData')}</small>
            </EmptyState>
          )}
        </Card>
        <Card>
          <h4>
            {t('workspaceInsights.initiativeProgressLeaderboard', {
              label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2).toLocaleLowerCase(),
            })}
          </h4>
          {isFetchingInitiativeProgressLadder && <Loader />}
          {!isFetchingInitiativeProgressLadder && initiativeProgressLadder.length > 0 && (
            <LeaderList>
              {initiativeProgressLadder.map((member, index) => (
                <LeaderRow key={member.id}>
                  <div className="number">{index + 1}</div>
                  <div className="profile">
                    <KoalaAvatar membership={member} tooltipType="card" />
                    <Link to={`/${currentWorkspace.slug}/people/${member.id}`}>{member.user.fullname}</Link>
                  </div>
                  <div className="progress">
                    <ProgressBarV2
                      percentage={member.initiative_progress_prct * 100}
                      totalCount={member.total_initiatives_count}
                      label={`${member.closed_initiatives_count}/${member.total_initiatives_count}`}
                    />
                  </div>
                </LeaderRow>
              ))}
            </LeaderList>
          )}
          {!isFetchingInitiativeProgressLadder && initiativeProgressLadder.length === 0 && (
            <EmptyState>
              <small className="subtle">{t('workspaceInsights.noLeaderboardData')}</small>
            </EmptyState>
          )}
        </Card>
        <Card>
          <h4>{t('workspaceInsights.streakLeaderboard')}</h4>
          {isFetchingStreakLadder && <Loader />}
          {!isFetchingStreakLadder && streakLadder.length > 0 && (
            <LeaderList>
              {streakLadder.map((item, index) => {
                const { membership, current_streak } = item;
                return (
                  <LeaderRow key={membership.id}>
                    <div className="number">{index + 1}</div>
                    <div className="profile">
                      <KoalaAvatar membership={membership} tooltipType="card" />
                      <Link to={`/${currentWorkspace.slug}/people/${membership.id}`}>{membership.user.fullname}</Link>
                    </div>
                    <div className="progress_streak">
                      <span role="img">🔥</span>
                      {t(`modals.streakLadder.streak`, { count: current_streak })}
                    </div>
                  </LeaderRow>
                );
              })}
            </LeaderList>
          )}
          {!isFetchingStreakLadder && streakLadder.length === 0 && (
            <EmptyState>
              <small className="subtle">{t('workspaceInsights.noActivity')}</small>
            </EmptyState>
          )}
        </Card>
      </ActivityWrapper>
    </>
  );
}

export default Component;
