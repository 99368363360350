import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Membership, Workspace, User } from 'types';

import * as remoteApi from 'api/remote';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import * as workspaceUtils from 'utils/workspaceUtils';
import { useTranslation } from 'react-i18next';

// Components
import KoalaAvatar from 'koala/components/Avatar';
import KoalaButton from 'koala/components/Button';
import KoalaIcon from 'koala/components/Icons';
import { useHistory, Link } from 'react-router-dom';
import AddPersonIcon from 'components/_assets/AddPersonIcon';
import PeopleMinimap from 'components/PeopleMinimap';

const Container = styled.div`
  label.header {
    font-size: 1.2rem;
    color: ${theme.colors.subtleText};
    width: 10rem;
    min-width: 10rem;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  margin-bottom: ${theme.spacing.x2};
`;

const InlineLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

const UserSelection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
`;

const Header = styled.div`
  h3 {
    display: flex;
    gap: ${theme.spacing.x1};
    align-items: center;
    margin-bottom: ${theme.spacing.x2};
  }
`;

const OwnerOption = styled.div`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};

  border-radius: 8px;

  &:hover {
    cursor: pointer;
    background: #f3f3f3;
  }
  .owner-option-name {
    margin-left: ${theme.spacing.x2};
  }

  &.active {
    background: #f3f3f3;
    &:hover {
      cursor: initial;
      background: #f3f3f3;
    }
  }

  &.not-assigned {
    opacity: 0.5;
  }

  &.option-button {
    background: #f3f3f3;
    svg {
      height: 1.8rem;
      g {
        stroke: ${theme.colors.subtleText};
      }
    }
    &:hover {
      cursor: pointer;
      background: #e7e7e7;
    }
  }
`;

interface Props {
  membershipId: string;
}

function ReportToSelection(props: Props) {
  const { membershipId } = props;
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const currentUser: User = useSelector((state: any) => state.session.currentUser, shallowEqual);

  const rootMembership = {
    ...currentMembership,
    user: currentUser,
  };
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const staleTime = 5 * 60 * 1000; // 5 minutes
  const direct_reportsQueryKey = [queryKeys.reporting, 'direct_reports', membershipId];
  const managerQueryKey = [queryKeys.reporting, 'manager', membershipId];

  // get direct_reports
  const { data: directReportsData } = useQuery(direct_reportsQueryKey, remoteApi.fetchMembershipDirectReports, {
    staleTime,
  });

  // get manager
  const { data: managerData } = useQuery(managerQueryKey, remoteApi.fetchMembershipManager, {
    staleTime,
  });

  const directReports = directReportsData?.data || [];
  const manager = managerData?.data || null;

  const handleManageReporting = () => {
    const blockId = `people:${membershipId}:edit.reporting`;
    dispatch(setGlobalModalContent(blockId));
  };

  const headerLabel = t('workspaceProfile.network.myNetwork');
  const headerButtonLabel = t('workspaceInbox.network.edit');
  const managerLabel = t('workspaceProfile.network.myManager');
  const directReportsLabel = t('workspaceProfile.network.myReports');

  const handleAvatarClicked = (membership: Membership) => {
    history.push(`/${currentWorkspace.slug}/people/${membership.id}`);
  };

  const showUpgrade =
    !workspaceUtils.hasV4PlusSubscription(currentWorkspace) && !workspaceUtils.hasPremiumSubscription(currentWorkspace);

  return (
    <Container>
      <Header>
        <h3>
          {headerLabel}
          <KoalaButton onClick={handleManageReporting} appearance="secondary" size="small">
            {showUpgrade && <KoalaIcon iconName="upgrade" iconSize="small" />}
            {headerButtonLabel}
          </KoalaButton>
        </h3>
      </Header>
      <Row>
        <label className="header">{managerLabel}</label>
        <UserSelection>
          {!manager && (
            <OwnerOption className="active option-button" onClick={handleManageReporting}>
              <AddPersonIcon />
              <span className="owner-option-name">{t(`modals.editReporting.selectManager`)}</span>
            </OwnerOption>
          )}
          {manager && (
            <InlineLink to={`/${currentWorkspace.slug}/people/${manager.id}`}>
              <KoalaAvatar membership={manager} handleClick={() => handleAvatarClicked(manager)} tooltipType="card" />
              <span>{manager.cached_fullname || manager.cached_email}</span>
            </InlineLink>
          )}
        </UserSelection>
      </Row>
      {directReports.length > 0 && (
        <Row>
          <label className="header">{directReportsLabel}</label>
          {directReports.length} {directReportsLabel.toLowerCase()}
        </Row>
      )}
      {directReports.length === 0 && (
        <Row>
          <label className="header">{directReportsLabel}</label>
          <UserSelection>
            <OwnerOption className="active option-button" onClick={handleManageReporting}>
              <AddPersonIcon />
              <span className="owner-option-name">{t(`modals.editReporting.selectDirect`)}</span>
            </OwnerOption>
          </UserSelection>
        </Row>
      )}
      <PeopleMinimap rootMembership={rootMembership} />
    </Container>
  );
}

export default ReportToSelection;
