import React, { Fragment, useState, useRef } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import theme from 'theme';
import { Helmet } from 'react-helmet';

// API
import * as remoteApi from 'api/remote';

// Actions
import { sessionActions } from 'state/actions/';

// Components
import { ChromeContent, ChromeSideNav } from 'components/Chrome';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import ContentTitle from 'components/ContentTitle';
import FormField from 'components/FormField';
import AccountSettingsNav from 'components/AccountSettingsNav';
import KoalaButton from 'koala/components/Button';
import KoalaAvatar from 'koala/components/Avatar';
import { useTranslation } from 'react-i18next';
import { Membership, User } from 'types';

const Form = styled.form`
  display: flex;
  flex-direction: column;

  @media ${theme.devices.mobile} {
    input {
      width: 100%;
    }
  }
`;

const ErrorList = styled.ul`
  margin-bottom: ${theme.spacing.x2};
  color: #d0402e;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: ${theme.spacing.x2};
  }
`;

const FileSelection = styled.div`
  display: flex;
  gap: ${theme.spacing.x2};
  align-items: center;
`;

function AccountDetails() {
  const dispatch = useDispatch();
  const currentUser: User = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const { t } = useTranslation();
  const [firstname, setFirstname] = useState(currentUser.firstname ?? '');
  const [lastname, setLastname] = useState(currentUser.lastname ?? '');
  const [avatar, setAvatar] = useState<File | null>(null);

  const fileRef = useRef<HTMLInputElement>(null);

  const [updateAccountMutation, { isError, isLoading, error }]: [any, any] = useMutation(
    remoteApi.updateAccountWithAvatar,
    {
      onSuccess: (response) => {
        const currentUser = response.data;
        dispatch(sessionActions.setCurrentUser(currentUser));
        // @ts-ignore
        fileRef.current.value = '';
        setAvatar(null);
      },
    },
  );

  let errors: any = {}; // UI Errors

  // Errors for the workspace creation are returned as a hash
  if (isError) {
    if (error.response.status === 500) {
      errors = {
        server: [t('errors.serverError')],
      };
    } else {
      errors = error.response ? error.response.data : {};
    }
  }

  // This function is used to turn the error hash keys into proper strings.
  const errorKeyToString = (errorKey: string): string => {
    switch (errorKey) {
      case 'firstname':
        return t('errors.firstName');
      case 'lastname':
        return t('errors.lastName');
      default:
        return '';
    }
  };

  const handleChange = (e: any) => {
    const inputTarget = e.target;
    const value = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;

    switch (name) {
      case 'firstname':
        setFirstname(value);
        break;
      case 'lastname':
        setLastname(value);
        break;
    }
  };

  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputTarget = e.target;
    setAvatar(inputTarget.files ? inputTarget.files[0] : null);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const params = {
      firstname,
      lastname,
      avatar,
    };
    updateAccountMutation({
      user: params,
    });
  };

  const openFileExplorer = () => {
    if (fileRef && fileRef.current) {
      fileRef.current.click();
    }
  };

  // Error classnames used to highlight input fields that have errors
  const firstnameClassname = errors['firstname'] ? 'error' : '';
  const lastnameClassname = errors['lastname'] ? 'error' : '';

  return (
    <Fragment>
      <Helmet>
        <title>{t('accountDetails.title')} | Tability</title>
      </Helmet>

      <ChromeSideNav>
        <AccountSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <SettingsNarrowWrapper>
          <ContentTitle>
            <h1>{t('accountDetails.header')}</h1>
          </ContentTitle>
          <Form onSubmit={handleSubmit}>
            <FormField>
              <label>{t('accountDetails.firstnameLabel')}</label>
              <input
                type="text"
                name="firstname"
                className={`${firstnameClassname} small`}
                autoComplete="off"
                placeholder={t('accountDetails.firstnamePlaceholder') ?? 'Your first name'}
                value={firstname}
                required={true}
                onChange={handleChange}
              />
            </FormField>
            <FormField>
              <label>{t('accountDetails.lastnameLabel')}</label>
              <input
                type="text"
                name="lastname"
                className={`${lastnameClassname} small`}
                autoComplete="off"
                placeholder={t('accountDetails.lastnamePlaceholder') ?? 'Your last name'}
                value={lastname}
                required={true}
                onChange={handleChange}
              />
            </FormField>
            <FormField>
              <label>{t('accountDetails.avatarLabel')}</label>
              <div style={{ padding: '1.6rem 0' }}>
                <KoalaAvatar membership={{ ...currentMembership, user: currentUser }} size={6.4} tooltipType="none" />
              </div>
              <FileSelection>
                <KoalaButton appearance="secondary" size="small" onClick={openFileExplorer} type="button">
                  {t('accountDetails.avatarButton')}
                </KoalaButton>
                <p className="subtle">{avatar ? avatar.name : t('accountDetails.noFile')}</p>
                <input
                  id="file-input"
                  type="file"
                  ref={fileRef}
                  accept="image/gif, image/jpeg, image/png"
                  onChange={handleAvatarChange}
                  style={{ display: 'none' }}
                />
              </FileSelection>
            </FormField>
            <FormField>
              <label>{t('accountDetails.tokenLabel')}</label>
              {currentUser.personal_api_token}
            </FormField>
            {isError && (
              <ErrorList>
                {Object.keys(errors).map((errorKey: string) => {
                  // Get the first error for the key
                  // NOTE: there might be more! I'm just keeping the UI simpler right now.
                  const error: string = errors[errorKey][0];
                  return (
                    <li>
                      {errorKeyToString(errorKey)} {error.toLowerCase()}
                    </li>
                  );
                })}
              </ErrorList>
            )}
            <Actions>
              <KoalaButton submit disabled={isLoading} loading={isLoading}>
                {t('shared.save')}
              </KoalaButton>
            </Actions>
          </Form>
        </SettingsNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default AccountDetails;
