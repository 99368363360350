import queryKeys from 'config/queryKeys';
import React, { Fragment, useState } from 'react';
import { useInfiniteQuery, useMutation, useQueryCache } from 'react-query';
import { Membership, Retrospective, RetrospectiveComment, User } from 'types';
import parse from 'parse-link-header';

import * as remoteApi from 'api/remote';

import KoalaLoader from 'koala/components/Loader';
import styled from 'styled-components';
import theme from 'theme';
import RetrospectiveCommentBlock from './RetrospectiveComment';
import KoalaAvatar from 'koala/components/Avatar';
import { shallowEqual, useSelector } from 'react-redux';
import MentionBox from 'components/MentionBox';
import { useTranslation } from 'react-i18next';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import { AxiosResponse } from 'axios';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${theme.colors.N10};
`;
const NewComment = styled.div`
  padding: 1.2rem ${theme.spacing.x2};
  display: grid;
  grid-template-areas: 'avatar input';
  grid-template-columns: auto 1fr;
  gap: ${theme.spacing.x1};
`;
const InputActions = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  margin-top: ${theme.spacing.x1};
`;

const ReplyPlaceholder = styled.input`
  background: #fff;
  border: 1px solid ${theme.colors.blockBorder};
`;
const InputBox = styled.div`
  display: flex;
  flex-direction: column;
`;
const AvatarBox = styled.div`
  height: 3.2rem;
  display: flex;
  align-items: center;
`;

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.x3};
`;

function getNextPage(response: any) {
  if (response && response.headers && response.headers.link) {
    const links = response.headers.link;
    const parsed = parse(links);
    if (parsed && parsed.next) {
      return parsed.next.page;
    }
  }
  return null;
}

interface Props {
  retrospective: Retrospective;
}

function RetrospectiveComments(props: Props) {
  const { retrospective } = props;
  const currentUser: User = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const [body, setBody] = useState('');
  const { t } = useTranslation();
  const translationKey = 'workspaceRetrospective';
  const queryCache = useQueryCache();
  const [maskEditor, setMaskEditor] = useState(true);
  const [commentGroups, setCommentGroups] = useState<AxiosResponse<RetrospectiveComment[]>[]>([]);

  // create comment mutations
  const [createCommentMutation, { isLoading: isCreating }] = useMutation(remoteApi.createRetrospectiveComment, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.retrospectiveComments, retrospective.id, {}]);
      setBody('');
      setMaskEditor(true);
    },
  });

  // Get the retrospective comments
  const staleTime = 0;
  const {
    isLoading: isCommentsLoading,
    fetchMore,
    canFetchMore,
    isFetchingMore,
  } = useInfiniteQuery([queryKeys.retrospectiveComments, retrospective.id, {}], remoteApi.fetchRetrospectiveComments, {
    staleTime,
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
    onSuccess: (response: AxiosResponse<RetrospectiveComment[]>[]) => {
      setCommentGroups(response);
    },
  });

  const handleSubmit = () => {
    const retrospective_comment = {
      body,
    };
    createCommentMutation({
      retrospectiveId: retrospective.id,
      retrospective_comment,
    });
  };

  if (isCommentsLoading) {
    return <KoalaLoader />;
  }

  return (
    <Container>
      <NewComment>
        <AvatarBox>
          <KoalaAvatar membership={{ ...currentMembership, user: currentUser }} tooltipType="card" />
        </AvatarBox>
        <InputBox>
          {maskEditor && (
            <ReplyPlaceholder
              type="text"
              placeholder={t(`${translationKey}.addComment`) ?? 'Add a comment...'}
              onFocus={() => {
                setMaskEditor(false);
              }}
            />
          )}
          {!maskEditor && (
            <>
              <MentionBox
                setValue={setBody}
                value={body}
                placeholder={t(`${translationKey}.addComment`) ?? 'Add a comment...'}
                comment={true}
                cmdEnterCallback={handleSubmit}
              />
              <InputActions>
                <KoalaButton onClick={handleSubmit} loading={isCreating}>
                  {t('shared.save')}
                </KoalaButton>
                <KoalaTextButton onClick={() => setMaskEditor(true)}>{t('shared.cancel')}</KoalaTextButton>
              </InputActions>
            </>
          )}
        </InputBox>
      </NewComment>
      {commentGroups.map((group, i) => (
        <Fragment key={i}>
          {group.data.map((comment) => (
            <RetrospectiveCommentBlock retrospectiveComment={comment} key={comment.id} />
          ))}
        </Fragment>
      ))}
      {canFetchMore && (
        <LoadMore>
          <KoalaButton onClick={() => fetchMore()} appearance="secondary" loading={!!isFetchingMore}>
            {t('shared.loadMore')}
          </KoalaButton>
        </LoadMore>
      )}
    </Container>
  );
}

export default RetrospectiveComments;
