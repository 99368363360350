import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import KoalaConfidenceBlock from 'koala/components/ConfidenceBlock';
import KoalaProgressBar from 'koala/components/ProgressBar';
import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Outcome } from 'types';
import * as outcomeUtils from 'utils/outcomeUtils';
import * as checkinUtils from 'utils/checkinUtils';
import CurrentGrowth from 'components/CurrentGrowth';

const Container = styled.div`
  z-index: 60;
  width: 286px;
  background-color: ${theme.colors.N0};
  border: 1px solid ${theme.colors.N20};
  cursor: default;
  border-radius: 8px;
  filter: drop-shadow(0px 0px 8px rgba(21, 21, 21, 0.15));
  padding: ${theme.spacing.x2};
  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  small {
    color: ${theme.colors.N60};
    font-weight: 500;
  }

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .outcome-card-confidence {
    align-items: flex-start;
    margin-top: 0.4rem;
  }
`;

const DividerBlock = styled.div`
  border-bottom: 1px solid ${theme.colors.N20};
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.half};
`;

const DifferenceContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
`;

const DifferenceBlock = styled.div`
  display: flex;
  gap: ${theme.spacing.half};
  flex: 1;
  flex-direction: column;
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${theme.spacing.x1};
`;

const DetailRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;
const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const ProgressBarMetrics = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface OutcomeCardProps {
  outcome: Outcome;
}

function OutcomeCard(props: OutcomeCardProps) {
  const { outcome } = props;
  const { t } = useTranslation();

  const { outcome_type, current_checkin, previous_checkin, from, to, score_format } = outcome;

  // Split score format into numeric part and text part
  const [numericFormat] = score_format ? score_format.split(/(?<=_number_[%kKmM]?)\s+/) : ['', ''];

  let formattedScore = '';
  //  if there's a current checkin, use the score
  if (current_checkin) {
    formattedScore = checkinUtils.checkinScore(current_checkin, outcome, t);
    // if there's no current checkin and has metric, use the starting value
  } else if (outcome.outcome_type !== 'no_metric') {
    formattedScore = from !== null ? outcomeUtils.nFormatter(from, 2) : '';
    formattedScore = outcomeUtils.applyFormat(score_format, formattedScore);
    // if there's no current checkin and no metric, show pending
  } else {
    formattedScore = t('shared.pending');
  }

  let toValueFormatted = to !== null ? outcomeUtils.nFormatter(to, 2) : '';
  toValueFormatted = outcomeUtils.applyFormat(score_format, toValueFormatted);
  const currentColor = current_checkin ? current_checkin.confidence : 'grey';

  const currentScore = current_checkin ? current_checkin.score : 0;
  const previousScore = previous_checkin ? previous_checkin.score : from || 0;
  const scoreDifference = currentScore - previousScore;
  let scoreDifferenceFormatted = outcomeUtils.nFormatter(scoreDifference, 2);
  scoreDifferenceFormatted = outcomeUtils.applyFormat(numericFormat, scoreDifferenceFormatted);

  const showGoalSection =
    outcome_type === 'improve_metric' || outcome_type === 'stay_below' || outcome_type === 'stay_above';
  const hasMetric = outcome_type !== 'no_metric';

  return (
    <Container onClick={(e) => e.stopPropagation()}>
      <SectionContainer>
        {current_checkin ? (
          <small>
            {t('components.outcomeCard.latest')} ({format(new Date(current_checkin.created_at), 'dd MMM')})
          </small>
        ) : (
          <small>{t('components.outcomeCard.pending')}</small>
        )}
        <HeaderRow>
          {outcome.outcome_type !== 'kpi' && (
            <KoalaConfidenceBlock
              currentConfidence={outcome.current_checkin?.confidence}
              className="outcome-card-confidence"
            />
          )}
          <h2>{formattedScore}</h2>
        </HeaderRow>
      </SectionContainer>
      {showGoalSection && (
        <>
          <DividerBlock />
          {outcome_type === 'improve_metric' && (
            <ProgressBarContainer>
              <ProgressBarMetrics>
                <small>{t('components.outcomeCard.goal')}</small>
                <small>{Math.round(outcome.outcome_progress_prct * 100)}%</small>
              </ProgressBarMetrics>
              <KoalaProgressBar
                appearance="secondary"
                value={outcome.outcome_progress_prct * 100}
                confidence={currentColor}
              />
            </ProgressBarContainer>
          )}

          {outcome_type === 'improve_metric' && (
            <p>
              {from !== null ? outcomeUtils.nFormatter(from, 2) : ''} → {toValueFormatted}
            </p>
          )}

          {outcome_type === 'stay_below' && (
            <ProgressBarContainer>
              <small>{t('components.outcomeCard.goal')}</small>
              <p>{`< ${toValueFormatted}`}</p>
            </ProgressBarContainer>
          )}

          {outcome_type === 'stay_above' && (
            <ProgressBarContainer>
              <small>{t('components.outcomeCard.goal')}</small>
              <p>{`> ${toValueFormatted}`}</p>
            </ProgressBarContainer>
          )}
        </>
      )}

      {previous_checkin && (
        <>
          <DividerBlock />
          <DifferenceContainer>
            <DifferenceBlock>
              <small>{t('components.outcomeCard.previous')}</small>
              {hasMetric ? (
                <span>
                  {outcomeUtils.applyFormat(numericFormat, outcomeUtils.nFormatter(previous_checkin.score, 2))}
                </span>
              ) : (
                <span>{checkinUtils.checkinScore(previous_checkin, outcome, t)}</span>
              )}
            </DifferenceBlock>
            {hasMetric && (
              <DifferenceBlock>
                <small>{t('components.outcomeCard.change')}</small>
                <DetailRow>
                  <span>{scoreDifferenceFormatted}</span>
                  <CurrentGrowth outcome={outcome} />
                </DetailRow>
              </DifferenceBlock>
            )}
          </DifferenceContainer>
        </>
      )}
    </Container>
  );
}

export default React.memo(OutcomeCard);
