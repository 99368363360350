import BlockActions from 'components/BlockActions';
import MarkdownContent from 'components/MarkdownContent';
import { CustomEmojisList } from 'components/Reactions/CustomEmojis';
import { EmojiData, Picker } from 'emoji-mart';
import KoalaAvatar from 'koala/components/Avatar';
import KoalaIconButton from 'koala/components/IconButton';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryCache } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import { CachedReaction, InitiativeComment, InitiativeReply, Membership, User } from 'types';
import * as dateUtils from 'utils/dateUtils';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import KoalaLoader from 'koala/components/Loader';
import Reactions from 'components/Reactions';
import ShareIconButton from 'components/ShareIconButton';
import * as routes from 'routes';
import { useHistory, useParams } from 'react-router-dom';

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'avatar user timestamp'
    '. content content'
    '. reactions reactions';
  column-gap: ${theme.spacing.x1};
  padding: ${theme.spacing.x1} 0px 0px ${theme.spacing.x6};

  .block-actions {
    opacity: 0;
    top: ${theme.spacing.half};
    padding: ${theme.spacing.half};
  }

  &:hover {
    .block-actions {
      opacity: 1;
    }
  }
`;

const ReactionSection = styled.div`
  grid-area: reactions;
`;

const AvatarSection = styled.div`
  grid-area: avatar;
`;

const ContentSection = styled.div`
  grid-area: content;
  padding-top: ${theme.spacing.x1};
  border: 1px solid ${theme.colors.blockBorder};
  border-radius: 4px;
  padding: ${theme.spacing.x1};
  margin: ${theme.spacing.half} 0;
`;

const TimestampSection = styled.div`
  grid-area: timestamp;
  color: ${theme.colors.N60};
  font-size: 1.1rem;
`;

const UserSection = styled.div`
  grid-area: user;
  font-weight: 700;
`;

const PickerContainer = styled.div`
  height: 0px;
  width: 0px;
  position: relative;
`;

const PickerInner = styled.div`
  width: 320px;
  height: fit-content;
  position: absolute;
  left: -320px;
  top: 15px;

  button:hover {
    background: ${theme.colors.N5};
  }
`;

interface Props {
  reply: InitiativeReply;
  comment: InitiativeComment;
  setIsEditing: (value: boolean) => void;
  setShowNewReply: (value: boolean) => void;
}

function InitiativeReplyDisplay(props: Props) {
  const { reply, setIsEditing, setShowNewReply, comment } = props;
  const { membership } = reply;
  const { i18n } = useTranslation();
  const history = useHistory();
  const emojiRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const currentUser: User = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const queryCache = useQueryCache();
  const [reactions, setReactions] = useState<CachedReaction[]>([]);
  useEffect(() => {
    if (reply.cached_reactions && typeof reply.cached_reactions !== 'string') {
      setReactions(reply.cached_reactions);
    } else {
      setReactions([]);
    }
  }, [reply]);
  const user = membership ? membership.user : null;

  const [addReactionMutation] = useMutation(remoteApi.createInitiativeReplyReaction, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.initiativeReplies, reply.initiative_comment_id]);
    },
  });

  const [deleteReactionMutation] = useMutation(remoteApi.deleteReaction, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.initiativeReplies, reply.initiative_comment_id]);
    },
  });

  const [deleteReplyMutation, { isLoading: isDeleting }] = useMutation(remoteApi.deleteInitiativeReply, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.initiativeReplies, reply.initiative_comment_id]);
    },
  });

  const handleReaction = () => {
    setShowEmojiPicker((prev) => !prev);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleReply = () => {
    setShowNewReply(true);
  };

  const handleDelete = () => {
    deleteReplyMutation(reply.id);
  };

  const addEmoji = (emojiData: EmojiData | string, e: React.SyntheticEvent) => {
    e.stopPropagation();
    setShowEmojiPicker(false);
    if (typeof emojiData === 'string') {
      addReactionMutation({ initiativeReplyId: reply.id, emotion: emojiData });
    } else if (typeof emojiData !== 'string' && emojiData.colons) {
      addReactionMutation({ initiativeReplyId: reply.id, emotion: emojiData.colons });
    }
  };

  const addReaction = (emotion: string) => {
    addReactionMutation({ initiativeReplyId: reply.id, emotion: emotion });
  };
  const removeReaction = (reactionId: string) => {
    deleteReactionMutation(reactionId);
  };

  const handleAvatarClicked = () => {
    history.push(`/${workspaceSlug}/people/${membership.id}`);
  };

  const userCustomEmojis = currentUser && currentUser.referrals_count ? CustomEmojisList : undefined;

  if (isDeleting) {
    return <KoalaLoader />;
  }

  const canEdit = user && currentUser.id === user.id;
  const canDelete = canEdit || currentMembership.role === 'owner' || currentMembership.role === 'admin';

  const link = `https://${process.env.REACT_APP_DOMAIN}${routes.WORKSPACE_PLAN_TRACK_ROUTE.replace(
    ':workspaceSlug',
    workspaceSlug,
  ).replace(':planId', comment.plan_nano_slug)}#initiative:${comment.initiative_id}:show`;

  return (
    <Container>
      <AvatarSection>
        <KoalaAvatar membership={membership} handleClick={handleAvatarClicked} tooltipType="card" />
      </AvatarSection>
      <UserSection>{membership.cached_fullname ?? membership.cached_email}</UserSection>
      <TimestampSection>{dateUtils.formatDistanceToNowLocale(reply.created_at, i18n, true, true)}</TimestampSection>
      {reply.body && (
        <ContentSection>
          <MarkdownContent source={reply.body} />
        </ContentSection>
      )}
      <ReactionSection>
        {reactions.length > 0 && (
          <Reactions reactions={reactions} addCallback={addReaction} removeCallback={removeReaction} />
        )}
      </ReactionSection>
      <BlockActions className="block-actions">
        <KoalaIconButton edge="square" iconName="comment" onClick={handleReply} size="small" />
        <KoalaIconButton edge="square" iconName="emoji" onClick={handleReaction} size="small" />
        <ShareIconButton id={reply.id} link={link} edge="square" size="small" />
        {canEdit && <KoalaIconButton edge="square" iconName="edit" onClick={handleEdit} size="small" />}
        {canDelete && <KoalaIconButton edge="square" iconName="trash" onClick={handleDelete} size="small" />}
        <PickerContainer>
          <PickerInner ref={emojiRef} onClick={(e) => e.stopPropagation()} className="emoji-picker-inner-container">
            {showEmojiPicker ? (
              <Picker
                set="twitter"
                title="Pick emoji"
                emoji="point_up"
                style={{
                  position: 'relative',
                  zIndex: 99,
                  marginTop: `${theme.spacing.half}`,
                  width: '100%',
                }}
                onClick={addEmoji}
                custom={userCustomEmojis}
              />
            ) : null}
          </PickerInner>
        </PickerContainer>
      </BlockActions>
    </Container>
  );
}

export default React.memo(InitiativeReplyDisplay);
