import React from 'react';
import styled from 'styled-components';

// Components
const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

function Login() {
  // Displays the list of templates
  return (
    <Container>
      <div>
        <h1>The Microsoft Teams sign-in flow failed.</h1>
        <p>Sorry, but we couldn't log you in. Please contact support if this issue persists.</p>
      </div>
    </Container>
  );
}

export default Login;
