import React from 'react';

const NewsfeedIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 14.9998V15C1 16.6401 2.31082 18 3.96491 18H16L16.0336 17.9998L16.0625 18C16.0916 18 16.1203 17.9988 16.1487 17.9963C17.7721 17.9161 19 16.5284 19 14.913L19 7C19 5.34314 17.6568 4 16 4L15 4C14.9641 4 14.9287 4.00189 14.8938 4.00558C14.4835 2.83747 13.3708 2 12.0625 2H4C2.34315 2 1 3.34315 1 5V14.9998ZM17 14.913C17 15.5413 16.5315 15.9824 16.0301 15.9995C15.4928 15.9824 15.0625 15.5415 15.0625 15L15.0625 14.998V6H16C16.5523 6 17 6.44771 17 7L17 14.913ZM13.0625 14.9998L13.0625 15.0022C13.0627 15.352 13.1229 15.6879 13.2332 16H3.96491C3.44862 16 3 15.569 3 15V14.9998V5C3 4.44772 3.44772 4 4 4L12.0625 4C12.6148 4 13.0625 4.44772 13.0625 5V14.9998ZM11.88 7C11.88 7.55228 11.4323 8 10.88 8L5.12001 8C4.56772 8 4.12001 7.55228 4.12001 7C4.12001 6.44771 4.56772 6 5.12001 6L10.88 6C11.4323 6 11.88 6.44772 11.88 7ZM10.88 11C11.4323 11 11.88 10.5523 11.88 10C11.88 9.44772 11.4323 9 10.88 9L5.12001 9C4.56772 9 4.12001 9.44771 4.12001 10C4.12001 10.5523 4.56772 11 5.12001 11L10.88 11ZM10.88 14C11.4323 14 11.88 13.5523 11.88 13C11.88 12.4477 11.4323 12 10.88 12L5.12001 12C4.56772 12 4.12001 12.4477 4.12001 13C4.12001 13.5523 4.56772 14 5.12001 14L10.88 14Z"
      fill="#737373"
    />
  </svg>
);

export default NewsfeedIcon;
