import WorkspaceAvatar from 'components/WorkspaceAvatar';
import React, { memo } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import styled from 'styled-components';
import theme from 'theme';

const Container = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid ${theme.colors.N10};
  padding: 1.2rem ${theme.spacing.x2};
  border-radius: 8px;
  background-color: ${theme.colors.N0};
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;
const Label = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;
const CustomHandle = styled(Handle)`
  background: transparent;
  border: none;
`;

function WorkspaceNode(node: NodeProps) {
  const { data } = node;
  const { workspace } = data;

  return (
    <Container>
      <WorkspaceAvatar workspace={workspace} />
      <Label>{workspace.name}</Label>
      <CustomHandle type="source" position={Position.Bottom} key="out" id="out" isConnectableStart={false} />
    </Container>
  );
}

export default memo(WorkspaceNode);
