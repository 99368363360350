import React, { useState } from 'react';
import { differenceInCalendarDays, parseISO } from 'date-fns/esm';
import * as TabilityTypes from 'types';
import { useSelector, shallowEqual } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
// Translations
// import { CustomTermKey, translate } from 'utils/customTermUtils';
// import { useTranslation } from 'react-i18next';
import * as checkinUtils from 'utils/checkinUtils';

// API and State
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import * as remoteApi from 'api/remote';

import Loader from 'components/Loader';
import OutcomeExpandable from 'components/OutcomeExpandable';
import InitiativeRow from 'components/InitiativeRow';
import KoalaIcon from 'koala/components/Icons';
import KoalaAvatar from 'koala/components/Avatar';
import PlanIconLabel from 'components/PlanIconLabel';
import KoalaColorBlock from 'koala/components/ColorBlock';

const Container = styled.div`
  background: ${theme.colors.N0};
  padding: 0 ${theme.spacing.x2} ${theme.spacing.x2} ${theme.spacing.x2};
`;

const LeaderList = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid ${theme.colors.blockBorder};
  margin-bottom: ${theme.spacing.x3};
  &:last-child {
    margin-bottom: 0;
  }
`;

const LeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow-y: auto;
`;

const LeaderRow = styled.div`
  display: flex;
  gap: ${theme.spacing.x2};
  padding: ${theme.spacing.x1};
  border-bottom: 1px solid ${theme.colors.blockBorder};
  &:last-child {
    border-bottom: none;
  }
  margin: 0 ${theme.spacing.x1};
  padding: ${theme.spacing.x2};
  align-items: flex-start;
  &:hover {
    cursor: pointer;
    background: ${theme.colors.N5};
  }

  .chevron {
    transition: all ease 0.2s;
    transform: rotate(-90deg);
  }
  &.expanded {
    .chevron {
      transform: rotate(0deg);
    }
  }
  .number {
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .profile {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: ${theme.spacing.half};
    width: 100%;
    b {
      font-weight: normal;
      display: inline-block;
      padding: 2px 4px;
      border-radius: 4px;
      background: ${theme.colors.N5};
    }
    .koala-color-block--container {
      position: relative;
      top: 4px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.x1};
  }

  .alert-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x1};

    .outcome-expanded,
    .initiative-row {
      border: 1px solid ${theme.colors.blockBorder} !important;
    }
    .plan-title-container {
      align-items: center;
    }
    .plan-title {
      font-size: 1.2rem;
      line-height: 1;
      color: ${theme.colors.subtleText};
    }
  }
  transition: all 0.1s ease-in-out;
`;

const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: ${theme.spacing.x3};
  font-size: 1.6rem;
`;

const SignalsHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  background: ${theme.colors.P20};
  padding: ${theme.spacing.x3};
  border-radius: 4px;
  margin: ${theme.spacing.x1};

  svg {
    path {
      fill: ${theme.colors.R50};
    }
  }
`;

const CelebrationsHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  background: ${theme.colors.G5};
  padding: ${theme.spacing.x3};
  border-radius: 4px;
  margin: ${theme.spacing.x1};

  svg {
    path {
      fill: ${theme.colors.G50};
    }
  }
`;

const staleTime = 60 * 1000 * 5;

const alertTypeToSymbol = (alert: any) => {
  const { alert_type, target } = alert;

  const currentConfidence = target.current_checkin ? target.current_checkin.confidence : 'grey';
  const confidenceColor = checkinUtils.confidenceToColor(currentConfidence);
  switch (alert_type) {
    case 'outcome_red':
      return (
        <>
          <KoalaColorBlock
            color={confidenceColor}
            size="xsmall"
            isPending={target.is_pending_checkin}
            isCompleted={target.completed}
            dataFor={`outcome-block-${target.nano_slug}`}
          />
        </>
      );
    case 'outcome_yellow':
      return (
        <>
          <KoalaColorBlock
            color={confidenceColor}
            size="xsmall"
            isPending={target.is_pending_checkin}
            isCompleted={target.completed}
            dataFor={`outcome-block-${target.nano_slug}`}
          />
        </>
      );
    case 'outcome_checkin_overdue':
      return (
        <>
          <KoalaIcon iconName="clock" iconSize={1.6} />
        </>
      );
    case 'outcome_critical_progress':
      return (
        <>
          <KoalaColorBlock
            color={confidenceColor}
            size="xsmall"
            isPending={target.is_pending_checkin}
            isCompleted={target.completed}
            dataFor={`outcome-block-${target.nano_slug}`}
          />
        </>
      );
    case 'outcome_critical_work':
      return (
        <>
          <KoalaColorBlock
            color={confidenceColor}
            size="xsmall"
            isPending={target.is_pending_checkin}
            isCompleted={target.completed}
            dataFor={`outcome-block-${target.nano_slug}`}
          />
        </>
      );
    case 'outcome_no_work':
      return (
        <>
          <KoalaColorBlock
            color={confidenceColor}
            size="xsmall"
            isPending={target.is_pending_checkin}
            isCompleted={target.completed}
            dataFor={`outcome-block-${target.nano_slug}`}
          />
        </>
      );
    case 'initiative_blocked':
      return (
        <>
          <KoalaIcon iconName="alert" iconSize={1.6} />
        </>
      );
    case 'initiative_overdue':
      return (
        <>
          <KoalaIcon iconName="calendar" iconSize={1.6} />
        </>
      );
    case 'initiative_staled':
      return (
        <>
          <KoalaIcon iconName="clock" iconSize={1.6} />
        </>
      );
    case 'outcome_completed':
      return (
        <>
          <KoalaColorBlock
            color={confidenceColor}
            size="xsmall"
            isPending={target.is_pending_checkin}
            isCompleted={target.completed}
            dataFor={`outcome-block-${target.nano_slug}`}
          />
        </>
      );
    case 'initiative_completed':
      return (
        <>
          <KoalaIcon iconName="task" iconSize={1.6} />
        </>
      );
    default:
      return alert_type;
  }
};

const alertTypeToString = (alert: any) => {
  const { alert_type, target } = alert;
  let itemTitle = <>{target.title}</>;
  const { plan } = target;

  const planFinishAtISO = plan && plan.finish_at ? parseISO(plan.finish_at) : null;
  let daysLeft = planFinishAtISO ? Math.max(0, differenceInCalendarDays(planFinishAtISO, new Date())) : 0;
  switch (alert_type) {
    case 'outcome_red':
      return (
        <>
          <div>
            <b>{itemTitle}</b>&nbsp;
            <span>is off track</span>
          </div>
        </>
      );
    case 'outcome_yellow':
      return (
        <>
          <div>
            <b>{itemTitle}</b>&nbsp;
            <span>is at risk</span>
          </div>
        </>
      );
    case 'outcome_checkin_overdue':
      return (
        <>
          <div>
            <b>{itemTitle}</b>&nbsp;
            <span>has an overdue progress update</span>
          </div>
        </>
      );
    case 'outcome_critical_progress':
      return (
        <>
          <div>
            <b>{itemTitle}</b>&nbsp;
            <span>
              only has {Math.round(target.outcome_progress_prct * 100)}% progress with {daysLeft} days left to go
            </span>
          </div>
        </>
      );
    case 'outcome_critical_work':
      return (
        <>
          <div>
            <b>{itemTitle}</b>&nbsp;
            <span>
              only has {Math.round(target.initiative_progress_prct * 100)}% of its tasks completed with {daysLeft} days
              left to go
            </span>
          </div>
        </>
      );
    case 'outcome_no_work':
      return (
        <>
          <div>
            <b>{itemTitle}</b>&nbsp;
            <span>doesn't have any work started with {daysLeft} days left to go</span>
          </div>
        </>
      );
    case 'initiative_blocked':
      return (
        <>
          <div>
            <b>{itemTitle}</b>&nbsp;
            <span>is blocked</span>
          </div>
        </>
      );
    case 'initiative_overdue':
      return (
        <>
          <div>
            <b>{itemTitle}</b>&nbsp;
            <span>is overdue</span>
          </div>
        </>
      );
    case 'initiative_staled':
      return (
        <>
          <div>
            <b>{itemTitle}</b>&nbsp;
            <span>has stalled</span>
          </div>
        </>
      );
    case 'outcome_completed':
      return (
        <>
          <div>
            <b>{itemTitle}</b>&nbsp;
            <span>has been marked completed 🎉</span>
          </div>
        </>
      );
    case 'initiative_completed':
      return (
        <>
          <div>
            <b>{itemTitle}</b>&nbsp;
            <span>has been closed</span>
          </div>
        </>
      );
    default:
      return alert_type;
  }
};

const LeaderRowItem = ({ alert, index }: { alert: any; index: number }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const leaderClass = isExpanded ? 'expanded' : '';

  return (
    <LeaderRow className={leaderClass} onClick={() => setIsExpanded(!isExpanded)}>
      <div className={`number`}>{alertTypeToSymbol(alert)}</div>
      <div className="alert-item">
        <div className="profile">{alertTypeToString(alert)}</div>
        <PlanIconLabel plan={alert.target.plan} size="xsmall" />
        {isExpanded && (
          <>
            {alert.target_type === 'Outcome' && (
              <OutcomeExpandable outcome={alert.target} hideActions={true} hideExpand={true} hideTags={true} />
            )}
            {alert.target_type === 'Initiative' && (
              <InitiativeRow initiative={alert.target} showMeta={true} showHeirarchy={false} />
            )}
          </>
        )}
      </div>
      <div className="actions">
        <KoalaAvatar membership={alert.target.membership} tooltipType="card" />
        <KoalaIcon iconName="downChevron" iconSize={2.4} className="chevron" />
      </div>
    </LeaderRow>
  );
};

function Component() {
  // const { t } = useTranslation();
  const currentWorkspace: TabilityTypes.Workspace = useSelector(
    (state: any) => state.session.currentWorkspace,
    shallowEqual,
  );

  const queryKeyAlerts = [queryKeys.currentWorkspace, currentWorkspace.slug, 'alerts'];
  const { isFetching: isFetchingAlerts, data: alertsResponse } = useQuery(
    queryKeyAlerts,
    remoteApi.fetchWorkspaceAlerts,
    {
      staleTime,
    },
  );

  const queryKeyCelebrations = [queryKeys.currentWorkspace, currentWorkspace.slug, 'celebrations'];
  const { isFetching: isFetchingCelebrations, data: celebrationsResponse } = useQuery(
    queryKeyCelebrations,
    remoteApi.fetchWorkspaceCelebrations,
    {
      staleTime,
    },
  );

  const alerts: any[] = alertsResponse?.data || [];
  const celebrations: any[] = celebrationsResponse?.data || [];

  return (
    <Container>
      {isFetchingCelebrations && <Loader />}
      {!isFetchingCelebrations && celebrations.length === 0 && (
        <LeaderList>
          <CelebrationsHeader>
            <KoalaIcon iconName="signal" iconSize={2.4} />
            <span>Celebrations</span>
          </CelebrationsHeader>
          <EmptyState className="subtle">No celebrations found for you yet.</EmptyState>
        </LeaderList>
      )}
      {!isFetchingCelebrations && celebrations.length > 0 && (
        <LeaderList>
          <CelebrationsHeader>
            <KoalaIcon iconName="celebration" iconSize={2.4} />
            <span>{celebrations.length} celebrations</span>
          </CelebrationsHeader>
          <LeaderContent>
            {celebrations.map((celebration, index) => {
              return <LeaderRowItem alert={celebration} index={index} />;
            })}
          </LeaderContent>
        </LeaderList>
      )}
      {isFetchingAlerts && <Loader />}
      {!isFetchingAlerts && alerts.length === 0 && (
        <LeaderList>
          <SignalsHeader>
            <KoalaIcon iconName="signal" iconSize={2.4} />
            <span>Signals</span>
          </SignalsHeader>
          <EmptyState className="subtle">No signals found for you yet.</EmptyState>
        </LeaderList>
      )}
      {!isFetchingAlerts && alerts.length > 0 && (
        <LeaderList>
          <SignalsHeader>
            <KoalaIcon iconName="signal" iconSize={2.4} />
            {alerts.length > 0 && <span>{alerts.length} signals found for you</span>}
            {alerts.length === 0 && <span>Signals</span>}
          </SignalsHeader>
          <LeaderContent>
            {alerts.map((alert, index) => {
              return <LeaderRowItem alert={alert} index={index} />;
            })}
          </LeaderContent>
        </LeaderList>
      )}
    </Container>
  );
}

export default Component;
