import KoalaIconButton from 'koala/components/IconButton';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import ReviewCardDeck from './ReviewCardDeck';
import styled from 'styled-components';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import { useQuery } from 'react-query';
import { Membership, Workspace } from 'types';
import Cards from './YearReviewCards';
import { useHistory } from 'react-router-dom';
import theme from 'theme';
import { useTranslation } from 'react-i18next';
import { translate } from 'utils/customTermUtils';
import { CustomTermKey } from 'utils/customTermUtils';
import KoalaLoader from 'koala/components/Loader';

const CustomPanelGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'panel-header'
    'panel-content'
    'panel-actions';
  height: 100%;
  padding-top: ${theme.spacing.x4};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.x2};
  width: 100%;
`;

const DeckContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

interface Props {}

function WorkspaceYearInReview(props: Props) {
  const history = useHistory();
  const { t } = useTranslation();
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const handleCancel = () => {
    history.goBack();
  };

  const queryKey = [queryKeys.personalYearReview, currentMembership.id];
  const { data: personalYearReview, isLoading: isLoadingPersonalYearReview } = useQuery(
    queryKey,
    remoteApi.fetchPersonalYearReview,
  );

  const reviewStats = personalYearReview?.data;
  const minValue = 0;

  if (isLoadingPersonalYearReview) {
    return (
      <CustomPanelGrid>
        <Header>
          <h2>{t('modals.yearReview.personalTitle')}</h2>
          <KoalaIconButton onClick={handleCancel} iconName="close" />
        </Header>
        <DeckContainer>
          <KoalaLoader size={5} />
        </DeckContainer>
      </CustomPanelGrid>
    );
  }

  if (!reviewStats) {
    return null;
  }

  let cards = [
    <Cards.ThankYou t={t} />,
    <Cards.PersonalSavings numCheckins={reviewStats.checkin_count} numStandups={reviewStats.standup_count} t={t} />,
  ];

  if (reviewStats.longest_streak > minValue) {
    cards = cards.concat(<Cards.PersonalLongestStreak streak={reviewStats.longest_streak} t={t} />);
  }

  if (reviewStats.standup_count > minValue) {
    cards = cards.concat(<Cards.PersonalStandupCount count={reviewStats.standup_count} t={t} />);
  }

  if (reviewStats.favorite_reactions.length > 0) {
    cards = cards.concat(<Cards.PersonalFavoriteReactions reactions={reviewStats.favorite_reactions} t={t} />);
  }

  if (reviewStats.initiative_count > minValue) {
    cards = cards.concat(
      <Cards.PersonalInitiativeCount
        count={reviewStats.initiative_count}
        label={translate(currentWorkspace, CustomTermKey.INITIATIVE, 2)}
        t={t}
      />,
    );
  }

  if (reviewStats.checkin_count > minValue) {
    cards = cards.concat(<Cards.PersonalCheckinCount count={reviewStats.checkin_count} t={t} />);
  }
  cards = cards.concat(<Cards.PersonalIntro t={t} />);

  return (
    <CustomPanelGrid>
      <Header>
        <h2>{t('modals.yearReview.personalTitle')}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </Header>
      <DeckContainer>
        <ReviewCardDeck cards={cards} />
      </DeckContainer>
    </CustomPanelGrid>
  );
}

export default WorkspaceYearInReview;
