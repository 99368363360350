import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useInfiniteQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import theme from 'theme';
import parse from 'parse-link-header';
// API
import * as remoteApi from 'api/remote';

// Components
import KoalaButton from 'koala/components/Button';
import OutcomeExpandable from 'components/OutcomeExpandable';
import { useTranslation } from 'react-i18next';

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  border-bottom: 0;
  font-weight: 800;
  font-size: 1rem;
  height: 3.7rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  border-bottom: 1px solid ${theme.colors.blockBorder};
`;

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${theme.spacing.x1} 0;
`;

export const BlockContent = styled.div`
  font-weight: 700;
  grid-area: content;
  background: transparent;
  margin: 0;
  height: 100%;

  display: flex;
  align-items: center;
`;

export const BlockMeta = styled.div`
  font-weight: 700;
  grid-area: meta;
  display: flex;
  align-items: center;
  height: 100%;
`;

const OutcomeContainer = styled.div`
  .outcome-expanded {
    border-bottom: 1px solid ${theme.colors.N10};
  }
  .outcome-expanded:last-of-type {
    border-bottom: 0;
  }
`;

const EmptyState = styled.div`
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
`;

interface Props {
  membership: TabilityTypes.Membership;
  setCheckinsLoading: Function;
  isContributing: boolean;
}

const getNextPage = (response: any) => {
  if (response && response.headers && response.headers.link) {
    const links = response.headers.link;
    const parsed = parse(links);
    if (parsed && parsed.next) {
      return parsed.next.page;
    }
  }
  return null;
};

function PendingCheckinsList(props: Props) {
  // Query that fetches all the outcomes with pending checkins
  const { membership, setCheckinsLoading, isContributing } = props;
  const { t } = useTranslation();
  const pendingCheckinsQueryKey = [queryKeys.checkins, membership.id, !!isContributing, { limit: 5 }];
  const {
    isLoading,
    isFetchingMore,
    data: outcomesResponse,
    fetchMore,
    canFetchMore,
  }: any = useInfiniteQuery(pendingCheckinsQueryKey, remoteApi.fetchMembershipPendingCheckins, {
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
  });

  useEffect(() => {
    setCheckinsLoading(isLoading);
  }, [setCheckinsLoading, isLoading]);

  if (isLoading) {
    return null;
  }

  let pendingCheckins = [];

  if (outcomesResponse) {
    pendingCheckins = outcomesResponse;
  }

  const hasCheckins = pendingCheckins.length === 0 || pendingCheckins[0].data.length === 0;

  // if no checkins and contributing, hide
  if (hasCheckins && isContributing) {
    return <></>;
  } else if (hasCheckins) {
    // Display empty state if no checkins
    return (
      <>
        <HeaderRow>{t('workspaceInbox.pendingCheckins')}</HeaderRow>
        <EmptyState>
          <p className="subtle">{t('shared.noData')}</p>
        </EmptyState>
      </>
    );
  }

  return (
    <Fragment>
      <HeaderRow>{!isContributing ? t('workspaceInbox.pendingCheckins') : t('workspaceInbox.contributing')}</HeaderRow>
      <OutcomeContainer>
        {pendingCheckins.map((group: any, i: number) =>
          group.data.map((outcome: TabilityTypes.Outcome, index: number) => {
            return <OutcomeExpandable outcome={outcome} hideExpand={true} key={index} />;
          }),
        )}
      </OutcomeContainer>

      {canFetchMore && (
        <LoadMore>
          <KoalaButton
            onClick={() => fetchMore()}
            loading={isFetchingMore}
            disabled={isFetchingMore}
            appearance="secondary"
          >
            {t('shared.loadMore')}
          </KoalaButton>
        </LoadMore>
      )}
    </Fragment>
  );
}

export default PendingCheckinsList;
