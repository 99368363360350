import queryKeys from 'config/queryKeys';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { Retrospective, RetrospectiveSection } from 'types';
import * as remoteApi from 'api/remote';
import { AxiosResponse } from 'axios';
import KoalaLoader from 'koala/components/Loader';
import theme from 'theme';
import TextSectionView from '../../../components/Retrospectives/TextSectionView';
import ObjectiveSectionView from '../../../components/Retrospectives/ObjectiveSectionView';
import { useTranslation } from 'react-i18next';
import KoalaIconButton from 'koala/components/IconButton';
import OutcomeSectionView from 'components/Retrospectives/OutcomeSectionView';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
`;

const SectionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 0px;

  .hover-to-show {
    display: none;
  }
  :hover {
    .hover-to-show {
      display: unset;
    }
  }
`;

const Actions = styled.div`
  width: 5rem;
  height: 4rem;
  position: relative;
  right: -${theme.spacing.x1};
  display: flex;
  gap: ${theme.spacing.half};
  align-items: center;
  color: ${theme.colors.N60};
  font-weight: 600;
`;

interface Props {
  retrospective: Retrospective;
  setShowCommentPanel: (value: RetrospectiveSection) => void;
}

function RetrospectiveSections(props: Props) {
  const { retrospective, setShowCommentPanel } = props;
  const { t } = useTranslation();
  const [sections, setSections] = useState<RetrospectiveSection[]>([]);

  // Get the retrospective sections
  const sectionQueryKey = [queryKeys.retrospectiveSections, retrospective.id];
  const staleTime = 0;
  const { isLoading: isSectionsLoading } = useQuery(sectionQueryKey, remoteApi.fetchRetrospectiveSections, {
    staleTime,
    onSuccess: (response: AxiosResponse<RetrospectiveSection[]>) => {
      const sections = response.data;
      setSections(sections);
    },
  });

  if (isSectionsLoading) {
    return (
      <div>
        <KoalaLoader />
      </div>
    );
  }

  const handleComment = (section: RetrospectiveSection) => {
    setShowCommentPanel(section);
  };

  const renderContent = (section: RetrospectiveSection) => {
    switch (section.section_type) {
      case 'text':
        return <TextSectionView section={section} hideEmpty={true} />;
      case 'objective':
        return <ObjectiveSectionView section={section} allowLink={true} />;
      case 'outcome':
        return <OutcomeSectionView section={section} />;
      default:
        return <div>{t(`workspaceRetrospective.sectionNotFound`)}</div>;
    }
  };
  return (
    <Container>
      {sections.map((section, i) => {
        const commentCount = section.retrospective_comments_count;
        const showComment = commentCount > 0 ? '' : 'hover-to-show';
        return (
          <SectionContainer key={i}>
            {renderContent(section)}
            <Actions>
              <KoalaIconButton className={showComment} iconName="comment" onClick={() => handleComment(section)} />
              {commentCount > 0 && <p>{commentCount}</p>}
            </Actions>
          </SectionContainer>
        );
      })}
    </Container>
  );
}

export default RetrospectiveSections;
