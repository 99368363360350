import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import * as microsoftTeams from '@microsoft/teams-js';

// Components
import MSTeamsError from 'components/MSTeamsError';

// API
import * as remoteApi from 'api/remote';

// Actions
import { sessionActions } from 'state/actions/';

import Loader from 'components/Loader';

function MSTeamsSignIn() {
  const dispatch = useDispatch();

  const [authenticateWithMicrosoftMutation, { isError, isFetching }]: [any, any] = useMutation(
    remoteApi.authenticateWithMicrosoft,
    {
      onSuccess: (response) => {
        dispatch(sessionActions.loginCompleted(response));
      },
    },
  );

  useEffect(() => {
    // Step 1: Get the client-side token
    function getClientSideToken() {
      return new Promise((resolve, reject) => {
        microsoftTeams.authentication.getAuthToken({
          successCallback: (result) => {
            resolve(result);
            const body = {
              token: result,
            };
            authenticateWithMicrosoftMutation(body);
          },
          failureCallback: (error) => {
            reject('Error getting token: ' + error);
          },
        });
      });
    }
    // Initialize the Microsoft Teams SDK
    microsoftTeams.app.initialize().then(() => {
      getClientSideToken();
    });
  }, [authenticateWithMicrosoftMutation]);

  if (isError) {
    return <MSTeamsError />;
  }

  if (isFetching) {
    return <Loader />;
  }

  return <></>;
}

export default React.memo(MSTeamsSignIn);
