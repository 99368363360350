import React, { useState } from 'react';
import * as TabilityTypes from 'types';
import * as workspaceUtils from 'utils/workspaceUtils';
import styled from 'styled-components';
import theme from 'theme';

// Components
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { fetchPlans } from 'api/remote';
import { Edge, MarkerType, Node, Position, ReactFlowProvider } from 'reactflow';
import Map from './Map';
import UpgradeRequired from './UpgradeRequired';
import KoalaButton from 'koala/components/Button';

const Container = styled.div`
  width: 100%;
  height: 50vh;
  position: relative;
`;

const Mask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(255, 255, 255, 0.6);
`;

const UpgradeModal = styled.div`
  position: absolute;
  left: 50%;
  max-height: 25rem;
  z-index: 10;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  overflow: hidden;
  height: 25rem;
  top: 50%;
  width: 60rem;
  max-width: 60rem;
  margin-left: -30rem;
  margin-top: -20rem;
  background: #fff;

  @media ${theme.devices.mobile} {
    width: 100%;
    margin: 20rem 0 0 0;
    margin-top: -20rem;
    left: unset;
    overflow: auto;
    height: 30rem;
    max-height: 30rem;
  }
`;

const HeaderContainer = styled.div`
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  border-bottom: 1px solid ${theme.colors.N10};
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-size: 1rem;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.N0};
  border: 1px solid ${theme.colors.blockBorder};
  border-radius: 4px;
  &.full-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 20;
  }
`;

const edgeDefaults = {
  targetHandle: 'in',
  sourceHandle: 'out',
  type: 'step',
  markerEnd: {
    type: MarkerType.ArrowClosed,
  },
};
const nodeDefaults = {
  position: { x: 0, y: 0 },
  sourcePosition: Position.Bottom,
  targetPosition: Position.Top,
};

interface Props {
  rootPlan: TabilityTypes.Plan;
}

function WorkspaceMapV2(props: Props) {
  const { rootPlan } = props;
  const currentWorkspace: TabilityTypes.Workspace = useSelector(
    (state: any) => state.session.currentWorkspace,
    shallowEqual,
  );
  const { slug } = currentWorkspace;
  const { t } = useTranslation();
  const [hasFetchedPlans, setHasFetchedPlans] = useState(false);
  const [initialEdges, setInitialEdges] = useState<Edge[]>([]);
  const [initialNodes, setInitialNodes] = useState<Node[]>([]);
  const [fullScreenEnabled, setFullScreenEnabled] = useState(false);

  const hasPremiumSubscription = workspaceUtils.hasPremiumSubscription(currentWorkspace);

  // Check the upgrade required flag
  let upgradeRequired = !hasPremiumSubscription;

  const filterParams: { [key: string]: any } = {
    children_of: rootPlan.id,
  };

  const params = {
    limit: 200,
    filter: filterParams,
  };

  useQuery([queryKeys.plans, slug, params], fetchPlans, {
    onSuccess: (response) => {
      // Create the plans mapping
      const plans: TabilityTypes.Plan[] = response.data;

      const nodes: Node[] = [];

      const edges: Edge[] = [];

      let initialPath = '';

      if (rootPlan.parent) {
        const { parent } = rootPlan;
        initialPath = `${parent.nano_slug}/`;

        edges.push({
          id: `${parent.id}:${rootPlan.id}`,
          target: rootPlan.id,
          source: parent.id,
          ...edgeDefaults,
        });

        // const expanded = mapState['expandedPlans'] && mapState['expandedPlans'][plan.id];

        nodes.push({
          id: `${parent.id}`,
          type: 'root',
          data: {
            expanded: true,
            plan: parent,
            outcomesExpanded: false,
            path: initialPath,
            isParent: true,
          },
          style: {
            height: 200,
            maxHeight: 200,
            width: 350,
            maxWidth: 350,
          },
          ...nodeDefaults,
        });
      }

      initialPath = `${initialPath}${rootPlan.nano_slug}/`;

      nodes.push({
        id: rootPlan.id,
        data: { plan: rootPlan, expanded: true, outcomesExpanded: false, path: initialPath },
        type: 'root',
        ...nodeDefaults,
        style: {
          height: 200,
          maxHeight: 200,
          width: 350,
          maxWidth: 350,
        },
      });

      plans.forEach((plan, index) => {
        edges.push({
          id: `${plan.parent_id}:${plan.id}`,
          target: plan.id,
          source: plan.parent_id,
          ...edgeDefaults,
        });

        // const expanded = mapState['expandedPlans'] && mapState['expandedPlans'][plan.id];

        nodes.push({
          id: `${plan.id}`,
          type: 'plan',
          data: {
            expanded: false,
            plan,
            outcomesExpanded: true,
            path: `${initialPath}${plan.nano_slug}/`,
          },
          style: {
            height: 600,
            maxHeight: 600,
            width: 600,
            maxWidth: 600,
          },
          ...nodeDefaults,
        });
      });

      nodes.push({
        id: 'add-sub-plan',
        type: 'action',
        data: {
          parent_id: rootPlan.id,
        },
        ...nodeDefaults,
        style: {
          height: 80,
          width: 200,
        },
      });

      edges.push({
        id: 'root:add-sub-plan',
        target: 'add-sub-plan',
        source: rootPlan.id,
        ...edgeDefaults,
      });

      setHasFetchedPlans(true);
      setInitialEdges(edges);
      setInitialNodes(nodes);
    },
    staleTime: 0,
  });

  const fullScreenClass = fullScreenEnabled ? 'full-screen' : '';

  return (
    <Container>
      {upgradeRequired && (
        <>
          <Mask />
          <UpgradeModal>
            <UpgradeRequired />
          </UpgradeModal>
        </>
      )}
      <Content className={fullScreenClass}>
        <HeaderContainer>
          <span className="title">Minimap</span>
          <KoalaButton onClick={() => setFullScreenEnabled(!fullScreenEnabled)} appearance="secondary" size="small">
            {fullScreenEnabled ? t('shared.exitFullScreen') : t('shared.enterFullScreen')}
          </KoalaButton>
        </HeaderContainer>
        {hasFetchedPlans && (
          <ReactFlowProvider>
            <Map initialEdges={initialEdges} initialNodes={initialNodes} fullScreenEnabled={fullScreenEnabled} />
          </ReactFlowProvider>
        )}
      </Content>
    </Container>
  );
}

export default WorkspaceMapV2;
