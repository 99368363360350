import React, { memo } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import styled from 'styled-components';
import theme from 'theme';
import { Workspace } from 'types';
import { useSelector, shallowEqual } from 'react-redux';

import Profile from './Profile';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import ActiveOutcomesList from './ActiveOutcomesList';
import ActiveInitiativesList from './ActiveInitiativesList';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  width: 100%;

  .expand-plan-button {
    transition: all ease 0.2s;
    transform: rotate(90deg);
    &.plan--expanded {
      transform: rotate(-90deg);
    }
  }
`;
const PlanContainer = styled.div`
  width: 100%;
  height: 100%;
  border: 2px solid ${theme.colors.B20};
  border-radius: 4px;
  background-color: ${theme.colors.N0};
  display: flex;
  flex-direction: column;
`;

const CustomHandle = styled(Handle)`
  background: transparent;
  border: none;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  padding: ${theme.spacing.x2};
  max-height: 40rem;
  overflow: auto;

  label {
    font-size: 1rem;
    font-weight: 900;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
`;

function MembershipNode(node: NodeProps) {
  const { data } = node;
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { membership, dataExpanded } = data;

  const expandedDataClassName = dataExpanded ? 'show-data' : '';

  return (
    <Container onClick={(e) => e.stopPropagation()}>
      <CustomHandle position={Position.Top} type="target" id="in" key="in" isConnectableStart={false} />
      <PlanContainer className={`${expandedDataClassName}`}>
        <Profile membership={membership} />
        {dataExpanded && (
          <Content className={`nowheel`}>
            <label>{translate(currentWorkspace, CustomTermKey.OUTCOME, 2)}</label>
            <ActiveOutcomesList membership={membership} />
            <label>{translate(currentWorkspace, CustomTermKey.INITIATIVE, 2)}</label>
            <ActiveInitiativesList membership={membership} showContributingInitiatives={false} />
          </Content>
        )}
      </PlanContainer>
      <CustomHandle type="source" position={Position.Bottom} key="out" id="out" isConnectableStart={false} />
    </Container>
  );
}

export default memo(MembershipNode);
