import React from 'react';
import { Provider } from 'react-redux';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import Routes from './routes/';
import AmplitudeIDSync from './analytics/AmplitudeIDSync';

import RefreshAuth from './routes/RefreshAuth';

// Store
import configureStore from './state/store';

// UI Components
import GlobalStyles from 'theme/GlobalStyles';
import SessionTimeout from 'routes/SessionTimeout';
import MSTeamsDetection from 'routes/MSTeamsDetection';
export const { store, persistor } = configureStore();

const queryCacheConfig = {
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
};

const queryCache = new QueryCache(queryCacheConfig);

function App() {
  // Extract path from the app.
  const pathname = window.location.pathname;
  const paths = pathname.split('/');
  let useRefreshAuth = true;

  // Don't try to refresh the authentication if using an embed or the public slideshow
  if (paths[2] === 'embed') {
    useRefreshAuth = false;
  }
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ReactQueryCacheProvider queryCache={queryCache}>
          <ReactQueryDevtools initialIsOpen={false} />
          <GlobalStyles />
          <MSTeamsDetection />
          <AmplitudeIDSync />
          {useRefreshAuth && <RefreshAuth />}
          <SessionTimeout />
          <Router>
            <Routes />
          </Router>
        </ReactQueryCacheProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
