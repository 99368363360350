import { ModalContent, ModalFooter, ModalGrid, ModalHeader } from 'components/GlobalModal';
import InitiativeRow from 'components/InitiativeRow';
import queryKeys from 'config/queryKeys';
import KoalaIconButton from 'koala/components/IconButton';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import styled from 'styled-components';

import * as remoteApi from 'api/remote';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { Initiative, WorkspaceSettings } from 'types';
import DropdownMenu from 'components/DropdownMenu';
import InitiativeWorkStatus from 'components/InitiativeWorkStatus';
import { getWorkStateDropdownData, getWorkStateParams } from 'utils/initiativeUtils';
import theme from 'theme';
import MentionBox from 'components/MentionBox';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import _ from 'lodash';
import { CONTEXT_STORAGE_KEY } from 'config/constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};

  h4 {
    font-size: 1.1rem;
    font-weight: 500;
  }

  .initiative-row {
    padding: 0;
  }

  .AriaMenuButton-menu {
    right: unset;
  }
`;

interface Props {
  initiativeId: string;
}

function UpdateInitiativeWorkState(props: Props) {
  const { initiativeId } = props;
  const { t } = useTranslation();
  const translationKey = 'modals.initiative';
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const [initiative, setInitiative] = useState<Initiative>();
  const [status, setStatus] = useState<string>();
  const [newState, setNewState] = useState<string>('open');
  const [body, setBody] = useState<string>('');
  const storageKey = `${CONTEXT_STORAGE_KEY}_initiative_status_${initiativeId}`;
  const currentWorkspaceSettings: WorkspaceSettings = useSelector(
    (state: any) => state.session.currentWorkspaceSettings,
    shallowEqual,
  );

  // Get the initiative details
  const staleTime = 0;
  const queryKey = [queryKeys.currentInitiative, initiativeId];
  useQuery(queryKey, remoteApi.fetchInitiativeDetails, {
    staleTime,
    onSuccess: (response) => {
      // Set the params on success
      const initiative: Initiative = response.data;
      setInitiative(initiative);
      setStatus(initiative.work_state);
      setNewState(initiative.state);
    },
  });

  // Initiative comment mutations
  const [createInitiativeCommentMutation, { isLoading }]: [any, any] = useMutation(remoteApi.createInitiativeComment, {
    onSuccess: () => {
      sessionStorage.removeItem(storageKey);
      queryCache.invalidateQueries([queryKeys.initiativeComments, initiativeId]);
      queryCache.invalidateQueries(queryKeys.currentInitiative);
      queryCache.invalidateQueries(queryKeys.initiatives);
      dispatch(setGlobalModalContent(''));
    },
  });

  useEffect(() => {
    debounceStoreText.current(body);
  }, [body]);

  useEffect(() => {
    const savedContext = sessionStorage.getItem(storageKey) || '';
    setBody(savedContext);
  }, [storageKey]);

  const storeText = (newText: string) => {
    if (newText) {
      sessionStorage.setItem(storageKey, newText);
    }
  };

  const debounceStoreText = useRef(
    _.debounce((newText: string) => storeText(newText), 1000, {
      maxWait: 5000,
    }),
  );

  const handleCancel = (e: any) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  if (!initiative) {
    return <></>;
  }

  const handleInitiativeStateChange = (value: any, initiative: Initiative) => {
    const action = value.props['data-action'];
    const params = getWorkStateParams(action);
    setStatus(action);
    setNewState(params.state);
  };

  const filteredStates = getWorkStateDropdownData(t, currentWorkspaceSettings);

  const handleSave = () => {
    if (!status) {
      return;
    }
    const params = getWorkStateParams(status);
    const initiativeComment = { ...params, body };
    createInitiativeCommentMutation({
      initiativeId: initiative.id,
      initiative_comment: initiativeComment,
    });
  };

  const allowSave = status && !isLoading;

  return (
    <>
      <ModalGrid>
        <ModalHeader>
          <h2>{t(`${translationKey}.title`)}</h2>
          <KoalaIconButton onClick={handleCancel} iconName="close" />
        </ModalHeader>
        <ModalContent style={{ overflow: 'visible' }}>
          <Container>
            <Section>
              <h4>{t(`${translationKey}.task`)}</h4>
              <InitiativeRow initiative={{ ...initiative, state: newState }} isReadOnly={true} />
            </Section>
            <Section>
              <h4>{t(`${translationKey}.status`)}</h4>
              <div>
                <DropdownMenu
                  trigger={<InitiativeWorkStatus providedState={status} showArrow={true} />}
                  items={filteredStates}
                  onSelection={(e: any) => handleInitiativeStateChange(e, initiative)}
                />
              </div>
            </Section>
            <Section>
              <h4>{t(`${translationKey}.comment`)}</h4>
              <MentionBox
                setValue={setBody}
                value={body}
                placeholder={t(`${translationKey}.commentPlaceholder`) ?? 'Add a comment'}
                comment={true}
                cmdEnterCallback={handleSave}
              />
            </Section>
          </Container>
        </ModalContent>
        <ModalFooter>
          <KoalaButton onClick={handleSave} disabled={!allowSave} loading={isLoading}>
            {t('shared.save')}
          </KoalaButton>
          <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
        </ModalFooter>
      </ModalGrid>
    </>
  );
}

export default UpdateInitiativeWorkState;
