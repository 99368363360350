import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setIsInMSTeams } from 'state/actions/globalUIActions';
import { app } from '@microsoft/teams-js';

// const SessionTimeout = ({ isAuthenticated, logOut }) => {
const MSTeamsDetection = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const isInIframe = window.self !== window.top;

    if (!isInIframe) {
      dispatch(setIsInMSTeams(false));
      return;
    }

    const initializeTeams = async () => {
      try {
        // Initialize Microsoft Teams SDK (now a Promise-based API)
        await app.initialize();

        // Fetch the context
        await app.getContext();

        // If there's no error, we're in Teams
        // Set state to indicate the app is running inside Teams
        dispatch(setIsInMSTeams(true));
      } catch (error) {
        dispatch(setIsInMSTeams(false));
      }
    };

    initializeTeams();
  }, [dispatch]);

  return null;
};
export default MSTeamsDetection;
