import { Emoji } from 'emoji-mart';
import KoalaIcon from 'koala/components/Icons';
import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Plan } from 'types';

const Container = styled.span<{ size: number; gap: string }>`
  display: flex;
  gap: ${(props) => props.gap};
  .plan-icon {
    align-items: center;
    display: flex;
  }
  .plan-title {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    position: relative;
  }
  p {
    margin: 0;
  }
  .emoji-mart-emoji {
    height: ${(props) => props.size}rem;
  }
`;

interface Props {
  /** entire plan object to create label for */
  plan?: Plan;
  /** plan icon string, undefined shows default icon (overridden by plan if set) */
  planIcon?: string;
  /** plan icon string, undefined shows empty title (overridden by plan if set) */
  planTitle?: string;
  /** size of the plan label */
  size?: 'xsmall' | 'small' | 'medium';
  /** max length of title before cropping, unset shows whole title */
  crop?: number;
}

function PlanIconLabel(props: Props) {
  const { plan, size, crop, planIcon, planTitle } = props;
  let icon: string | undefined;
  let title: string;

  if (plan) {
    icon = plan.plan_icon;
    title = plan.title;
  } else {
    icon = planIcon;
    title = planTitle ?? '';
  }

  let emojiSize = 24;
  let iconSize = 2.4;
  let iconGap = theme.spacing.x1;
  switch (size) {
    case 'xsmall':
      emojiSize = 14;
      iconSize = 1.4;
      iconGap = theme.spacing.half;
      break;
    case 'small':
      emojiSize = 20;
      iconSize = 2;
      break;
    case 'medium':
      emojiSize = 24;
      iconSize = 2.4;
      break;

    default:
      break;
  }

  let croppedTitle = title;
  if (crop) {
    croppedTitle = title.length > crop ? `${title.substring(0, crop)}...` : title;
  }

  return (
    <Container className="plan-title-container" size={iconSize} gap={iconGap}>
      <span className="plan-icon">
        {icon && <Emoji emoji={icon} size={emojiSize} set="twitter" />}
        {!icon && <KoalaIcon iconName="plan" iconSize={iconSize} />}
      </span>
      <span className="plan-title" title={title}>
        {croppedTitle}
      </span>
    </Container>
  );
}

export default PlanIconLabel;
